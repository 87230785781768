import { useQuery, useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import {
  deleteCampaignTemplate,
  getCampaignTemplates,
  createCampaignTemplate,
  updateCampaignTemplate,
} from '../api/campaign-templates.api';
import {
  CreateCampaignTemplateDto,
  CampaignTemplate,
  ESocketRefreshModule,
} from '../../backend/careo-api';

type SaveCampaignTemplateVariables = {
  campaignTemplateData: CreateCampaignTemplateDto;
  selectedCampaignTemplate?: CampaignTemplate;
};

type UseCampaignTemplateProps = {
  campaignTemplatesList: CampaignTemplate[];
  isLoading: boolean;

  saveCampaignTemplate: (
    variables: SaveCampaignTemplateVariables,
  ) => Promise<void>;
  deleteCampaignTemplate: (campaignTemplate: CampaignTemplate) => Promise<void>;
};

export const useCampaignTemplates = (): UseCampaignTemplateProps => {
  // Fetch campaignTemplates (React Query caches it)
  const { data: campaignTemplates = [], isLoading } = useQuery({
    queryKey: [ESocketRefreshModule.CAMPAIGN_TEMPLATES],
    queryFn: getCampaignTemplates,
  });

  // Delete campaignTemplate mutation
  const deleteMutation = useMutation({
    mutationFn: (selectedCampaignTemplate: CampaignTemplate) => {
      return deleteCampaignTemplate(selectedCampaignTemplate);
    },
    onSuccess: () => {
      toast.success('CampaignTemplate removed successfully');
    },
    onError: (error: any) => {
      toast.error(error.message);
    },
  });

  // Create or Update CampaignTemplate Mutation
  const saveCampaignTemplateMutation = useMutation({
    mutationFn: ({
      campaignTemplateData,
      selectedCampaignTemplate,
    }: {
      selectedCampaignTemplate?: CampaignTemplate;
      campaignTemplateData: CreateCampaignTemplateDto;
    }) => {
      return (
        selectedCampaignTemplate
          ? updateCampaignTemplate(
              selectedCampaignTemplate._id,
              campaignTemplateData,
            )
          : createCampaignTemplate(campaignTemplateData)
      ).then(() => {});
    },
    onSuccess: () => {
      toast.success('Template saved successfully');
    },
    onError: (error: any) => {
      toast.error(error?.message || 'Something went wrong');
    },
  });

  return {
    campaignTemplatesList: campaignTemplates,
    isLoading,
    saveCampaignTemplate: saveCampaignTemplateMutation.mutateAsync,
    deleteCampaignTemplate: deleteMutation.mutateAsync,
  };
};
