import { useQuery, useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import {
  getCandidateCompliances,
  updateCompliance,
} from '../api/compliances.api';
import {
  Compliance,
  EComplianceName,
  ESocketRefreshModule,
  UpdateComplianceDto,
} from '../../backend/careo-api';
import { selectCandidateCompliances } from '../api/campaign-templates.api';

type UpdateComplianceVariables = {
  complianceData: UpdateComplianceDto;
  validate: boolean;
  complianceId: string;
};

type SetCandidateComplianceVariables = {
  candidateId: string;
  tasksList: EComplianceName[];
  reminderDate?: string;
};

type UseComplianceProps = {
  compliancesList: Compliance[];
  isLoading: boolean;

  updateCompliance: (variables: UpdateComplianceVariables) => Promise<void>;
  selectCandidateCompliances: (
    variables: SetCandidateComplianceVariables,
  ) => Promise<void>;
};

export const useCompliances = (candidateId: string): UseComplianceProps => {
  // Fetch compliances (React Query caches it)
  const { data: compliances = [], isLoading } = useQuery({
    queryKey: [ESocketRefreshModule.CANDIDATE_COMPLIANCES, candidateId],
    queryFn: () => getCandidateCompliances(candidateId),
    enabled: !!candidateId, // Ensures query runs only if candidateId exists
  });

  // Update Compliance Mutation
  const updateComplianceMutation = useMutation({
    mutationFn: ({
      complianceData,
      validate,
      complianceId,
    }: UpdateComplianceVariables) => {
      return updateCompliance(complianceId, validate, complianceData).then(
        () => {},
      );
    },
    onSuccess: () => {
      toast.success('Compliance updated successfully');
    },
    onError: (error: any) => {
      toast.error(error?.message || 'Something went wrong');
    },
  });

  const selectCandidateCompliancesMutation = useMutation({
    mutationFn: ({
      candidateId,
      tasksList,
      reminderDate,
    }: SetCandidateComplianceVariables) => {
      return selectCandidateCompliances(
        candidateId,
        tasksList,
        reminderDate,
      ).then(() => {});
    },
    onSuccess: () => {
      toast.success('Compliances saved successfully');
    },
    onError: (error: any) => {
      toast.error(error?.message || 'Something went wrong');
    },
  });

  return {
    compliancesList: compliances.filter((el) => el.isActive),
    isLoading,
    updateCompliance: updateComplianceMutation.mutateAsync,
    selectCandidateCompliances: selectCandidateCompliancesMutation.mutateAsync,
  };
};
