import React, { useEffect, useMemo, useState } from 'react';
import { useAuth } from '../../contexts/auth.context';
import {
  Button,
  CardContainer,
  HeaderPageContainer,
  SearchInput,
  SubTitlePage,
  TabFilter,
  Table,
  TitlePage,
  MultipleSelect,
  SharedCandidatesList,
  Pagination,
} from '../../components';
import { DeleteIcon, EditIcon, EyeIcon, PlusIcon, SortIcon } from '../../icons';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  ERoute,
  filterCandidatesLists,
  formatDate,
  getItemsOfPage,
  getNumberOfPages,
  onSelectSort,
  sortCandidatesLists,
} from '../../utils';
import { useNavigate } from 'react-router-dom';
import { CandidatesList } from '../../backend/careo-api';
import { toast } from 'react-toastify';
import { useModal } from '../../contexts/side-modal.context';
import { useConfirm } from '../../contexts/confirm-modal.context';
import { useUsers } from '../../api-query/hooks/useUsers';
import { useCandidates } from '../../api-query/hooks';

const itemsPerPage = 8;

export const SharedCandidatesListPage = () => {
  const navigate = useNavigate();
  const { openModal, closeModal } = useModal();

  const { user } = useAuth();
  const { usersList } = useUsers();
  const { candidatesList } = useCandidates();

  const [candidatesListsDetails, setCandidatesListsDetails] = useState<
    CandidatesList[]
  >([]);

  const [candidatesListsDetailsLists, setCandidatesListsDetailsLists] =
    useState<CandidatesList[]>([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState<number>();
  const [isLoading, setIsLoading] = useState(true);

  const { openConfirm, closeConfirm } = useConfirm();

  const onDeleteConfirm = (item: CandidatesList) => {
    openConfirm({
      title: 'Delete Candidate List',
      component: (
        <>
          Do you want to delete <b>{item?.name}</b> ?
        </>
      ),
      onConfirm: () =>
        deleteCandidateList(item)
          .then(() => closeConfirm())
          .catch(() => {}),
    });
  };

  const onDisjoinConfirm = (item: CandidatesList) => {
    openConfirm({
      title: 'Disjoin Candidate List',
      component: (
        <>
          Do you want to disjoin <b>{item?.name}</b> ?
        </>
      ),
      onConfirm: () =>
        disjoinCandidateList(item)
          .then(() => closeConfirm())
          .catch(() => {}),
    });
  };

  const [filter, setFilter] = useState({
    search: '',
    region: '',
    county: '',
    grade: '',
    level: '',
  });
  const [sort, setSort] = useState<{ key: string; value: '+' | '-' | '' }>({
    key: '',
    value: '',
  });

  const onClickCreate = () => {
    openModal({
      title: 'New List',
      component: (
        <SharedCandidatesList
          candidates={candidatesList}
          users={usersList}
          selectedCandidatesIds={[]}
          data-testid="new-shared-candidates-list"
          getSharedCandidatesLists={getSharedCandidatesLists}
        />
      ),
    });
  };

  const onClickEdit = (selectedCandidateList: CandidatesList) => {
    openModal({
      title: 'Update List',
      component: (
        <SharedCandidatesList
          candidates={candidatesList}
          users={usersList}
          selectedCandidateList={selectedCandidateList}
          data-testid="update-shared-candidates-list"
          getSharedCandidatesLists={getSharedCandidatesLists}
        />
      ),
    });
  };

  const onClickView = (id: number | string) => {
    navigate(`/${ERoute.SHARED_CANDIDATES}/${id}`);
  };

  const getSharedCandidatesLists = async () => {
    setIsLoading(true);
    await AxiosInstance.candidatesLists
      .candidatesListsControllerFindAll()
      .then((response) => {
        setCandidatesListsDetails(response.data.items);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
    setIsLoading(false);
  };

  const deleteCandidateList = async (item: CandidatesList) => {
    await AxiosInstance.candidatesLists
      .candidatesListsControllerRemove(item?._id)
      .then(() => {
        toast.success('Candidate List Removed successfully');
        getSharedCandidatesLists();
        closeConfirm();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
    closeModal();
  };

  const disjoinCandidateList = async (item: CandidatesList) => {
    await AxiosInstance.candidatesLists
      .candidatesListsControllerDisjoinList(item?._id)
      .then(() => {
        toast.success('You have disjoined Candidate List successfully');
        getSharedCandidatesLists();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });

    closeModal();
  };

  const paginatedData = useMemo(() => {
    return getItemsOfPage(
      candidatesListsDetailsLists,
      currentPage,
      itemsPerPage,
    );
  }, [candidatesListsDetailsLists, currentPage]);

  useEffect(() => {
    const filteredData = filterCandidatesLists(candidatesListsDetails, filter);
    const sortedData = sortCandidatesLists(filteredData, sort);

    setCandidatesListsDetailsLists(sortedData);
    setTotalPages(getNumberOfPages(sortedData.length, itemsPerPage));
  }, [candidatesListsDetails, filter, sort]);

  useEffect(() => {
    getSharedCandidatesLists();
  }, []);

  return (
    <>
      <TabFilter
        data-testid="tab-filter"
        filters={[
          {
            title: 'Candidates',
            url: ERoute.CANDIDATES,
          },
          {
            title: 'Lists',
            url: ERoute.SHARED_CANDIDATES,
          },
        ]}
      />
      <CardContainer data-testid="card-container">
        <HeaderPageContainer data-testid="header-page-container">
          <div className="left-container" data-testid="left-container">
            <TitlePage data-testid="title-page">Candidate Lists</TitlePage>
            <SubTitlePage data-testid="sub-title-page">
              Manage your Lists
            </SubTitlePage>
          </div>
          <div className="right-container" data-testid="right-container">
            <Button
              type="primary"
              onClick={() => onClickCreate()}
              data-testid="create-list-button"
            >
              <PlusIcon /> Create New List
            </Button>
          </div>
        </HeaderPageContainer>
        <div className="filter-container" data-testid="filter-container">
          <SearchInput
            placeholder="Search candidate list"
            onChange={(e) =>
              setFilter((prev) => ({ ...prev, search: e.target.value ?? '' }))
            }
            data-testid="search-input"
          />
          <MultipleSelect
            placeholder="All Regions"
            options={[]}
            onChange={(e) => {}}
            disabled
            data-testid="regions-select"
          />
          <MultipleSelect
            placeholder="All Counties"
            options={[]}
            onChange={(e) => {}}
            disabled
            data-testid="counties-select"
          />

          <MultipleSelect
            placeholder="All Levels"
            options={[]}
            onChange={(e) => {}}
            disabled
            data-testid="levels-select"
          />
          <MultipleSelect
            placeholder="All Grades"
            options={[]}
            onChange={(e) => {}}
            disabled
            data-testid="grades-select"
          />
        </div>
        <div
          className="data-table-container"
          data-testid="data-table-container"
        >
          <Table data-testid="candidate-list-table">
            <thead>
              <tr>
                <th className="checkbox-table" data-testid="checkbox-header">
                  <input type="checkbox" />
                </th>
                <th
                  onClick={() => onSelectSort('firstName', setSort)}
                  data-testid="name-header"
                >
                  <div>
                    <label>Name </label>
                    <SortIcon
                      value={sort.key === 'firstName' ? sort.value : ''}
                      data-testid="sort-icon-name"
                    />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('candidates', setSort)}
                  data-testid="list-candidates-header"
                >
                  <div>
                    <label>List candidates</label>
                    <SortIcon
                      value={sort.key === 'candidates' ? sort.value : ''}
                      data-testid="sort-icon-candidates"
                    />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('user', setSort)}
                  data-testid="line-manager-header"
                >
                  <div>
                    <label>Line Manager</label>
                    <SortIcon value={sort.key === 'user' ? sort.value : ''} />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('county', setSort)}
                  data-testid="created-on-header"
                >
                  <div>
                    <label>Created on</label>
                    <SortIcon value={sort.key === 'county' ? sort.value : ''} />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('region', setSort)}
                  data-testid="last-edited-header"
                >
                  <div>
                    <label>Last Edited</label>
                    <SortIcon value={sort.key === 'region' ? sort.value : ''} />
                  </div>
                </th>
                <th data-testid="action-column-header">
                  <div></div>
                </th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr data-testid="loading-row">
                  <td
                    colSpan={100}
                    className="text-center"
                    data-testid="loading-text"
                  >
                    Loading ...
                  </td>
                </tr>
              ) : (
                <>
                  {paginatedData.length > 0 ? (
                    <>
                      {paginatedData.map((itemList) => {
                        const candidatesName = itemList.candidates
                          .map((el) => el.firstName + ' ' + el.lastName)
                          .join(', ');

                        const isUpdateDisabled =
                          user?._id !== itemList.user._id;

                        return (
                          <tr
                            key={itemList._id}
                            data-testid={`candidate-row-${itemList._id}`}
                          >
                            <td
                              className="checkbox-table"
                              data-testid="checkbox-column"
                            >
                              <input type="checkbox" />
                            </td>
                            <td data-testid="candidate-name">
                              {itemList.name}
                            </td>
                            <td
                              title={candidatesName}
                              data-testid="candidate-list-names"
                            >
                              <div className="candidates-names">
                                {candidatesName}
                              </div>
                            </td>
                            <td data-testid="line-manager-name">
                              {itemList?.user.firstName}{' '}
                              {itemList?.user.lastName}
                            </td>
                            <td data-testid="created-date">
                              {formatDate(itemList.createdAt)}{' '}
                            </td>
                            <td data-testid="updated-date">
                              {itemList.updatedAt
                                ? formatDate(itemList.updatedAt)
                                : '-'}{' '}
                            </td>
                            <td data-testid="action-item">
                              <div className="action-item">
                                <div
                                  className="view-icon"
                                  onClick={() => onClickView(itemList._id)}
                                  data-testid="view-icon"
                                >
                                  <EyeIcon />
                                </div>
                                <div
                                  className={`edit-icon ${
                                    isUpdateDisabled && 'disabled'
                                  }`}
                                  onClick={() =>
                                    !isUpdateDisabled && onClickEdit(itemList)
                                  }
                                  data-testid="edit-icon"
                                >
                                  <EditIcon />
                                </div>
                                <div
                                  className={`delete-icon`}
                                  onClick={() =>
                                    !isUpdateDisabled
                                      ? onDeleteConfirm(itemList)
                                      : onDisjoinConfirm(itemList)
                                  }
                                  data-testid="delete-icon"
                                >
                                  <DeleteIcon />
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  ) : (
                    <tr data-testid="no-items-row">
                      <td
                        colSpan={100}
                        className="text-center"
                        data-testid="no-items-text"
                      >
                        No item found
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </Table>
        </div>
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
          itemsPerPage={itemsPerPage}
          totalEntries={candidatesListsDetailsLists.length}
          data-testid="pagination"
        />
      </CardContainer>
    </>
  );
};
