import { useState, useEffect, useMemo } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Compliance,
  ExtendedCandidate,
  EComplianceStatus,
} from '../../backend/careo-api';
import {
  TitlePage,
  CardContainer,
  UserIcon,
  ATag,
  ComplianceEdit,
  TitleCard,
  ComplianceList,
  Button,
  ProgressBar,
  CandidateGeneral,
  TabPageContainer,
  CandidateComplianceDetailsContainer,
  ComplianceContainer,
  ComplianceStatus,
  NewNotificationsList,
  NewNotificationsListContainer,
} from '../../components';
import {
  LeftArrowIcon,
  CallIcon,
  MessageIcon,
  NewSmallNotificationRedIcon,
  EditIcon,
  SettingIcon,
} from '../../icons';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  getTimeAgo,
  TNotificationUI,
  getNotificationData,
} from '../../utils';
import { useModal } from '../../contexts/side-modal.context';
import { ComplianceStart } from '../../components/compliance/compliance-start';
import { AddComplianceForm } from '../../components/compliance/add-compliance-form';
import { ComplianceAudit } from '../../components/compliance/compliance-audit.component';
import { useCompliances } from '../../api-query/hooks';
import { CandidateDocuments } from '../../components/candidate/tabs/candidate-documents.component';
import NoteTab from '../../components/note/note-tab.component';
import { NoteForm } from '../../components/note/note-form.component';
import { useConfirm } from '../../contexts/confirm-modal.context';

export enum ECandidateComplianceTabs {
  GENERAL = 'General',
  AUDIT = 'Audit',
  DOCUMENTS = 'Documents',
  COMPLIANCE = 'Compliance',
  COMPLIANCE_NOTES = 'Notes',
}

const getCandidateComplianceStatus = (records: Compliance[]) => {
  const requiredRecords = records.filter((el) => el.isActive);

  const isExpired = requiredRecords.some(
    (comp) => comp.status === EComplianceStatus.EXPIRED,
  );
  if (isExpired) return EComplianceStatus.EXPIRED;

  const isFailed = requiredRecords.some(
    (comp) => comp.status === EComplianceStatus.FAILED,
  );
  if (isFailed) return EComplianceStatus.FAILED;

  const isPassed = requiredRecords.every(
    (comp) => comp.status === EComplianceStatus.PASSED,
  );
  if (isPassed) return EComplianceStatus.PASSED;

  const isInProgress = requiredRecords.some(
    (comp) => comp.status === EComplianceStatus.IN_PROGRESS,
  );
  if (isInProgress) return EComplianceStatus.IN_PROGRESS;

  return EComplianceStatus.NOT_ACTIVE;
};

export const CandidateComplianceDetailsPage = () => {
  const navigate = useNavigate();

  const { id, type } = useParams();
  const location = useLocation();
  const { compliancesList, isLoading } = useCompliances(id!);
  const { openModal, closeModal } = useModal();
  const { closeConfirm } = useConfirm();

  const tabs = [
    {
      name: ECandidateComplianceTabs.GENERAL,
      url: `/candidates/${id}`,
    },
    // {
    //   name: ECandidateComplianceTabs.OVERVIEW,
    //   url: `/candidates/${id}/overview`,
    // },
    {
      name: ECandidateComplianceTabs.COMPLIANCE,
      url: `/candidates/${id}/compliance`,
    },
    {
      name: ECandidateComplianceTabs.DOCUMENTS,
      url: `/candidates/${id}/documents`,
    },
    {
      name: ECandidateComplianceTabs.AUDIT,
      url: `/candidates/${id}/audit`,
    },
    {
      name: ECandidateComplianceTabs.COMPLIANCE_NOTES,
      url: `/candidates/${id}/notes`,
    },
  ];

  const [selectedTab, setSelectedTab] = useState<{
    url: string;
    name: string;
  } | null>(null);

  const [selectedTabCompliance, setSelectedTabCompliance] =
    useState<Compliance | null>(null);

  const [candidate, setCandidate] = useState<ExtendedCandidate | null>(null);

  const [notifications, setNotifications] = useState<TNotificationUI[]>([]);

  const complianceCompletion = useMemo(() => {
    const total = compliancesList.filter((el) => el.isActive);
    const passed = total.filter((el) => el.isApproved);

    return {
      status: getCandidateComplianceStatus(compliancesList),
      percentage: total.length > 0 ? (passed.length / total.length) * 100 : 0,
      passed: passed.length,
      total: total.length,
    };
  }, [compliancesList]);

  const onClickNotificationItem = async (notificationUI: TNotificationUI) => {
    navigate(notificationUI.url);
    await AxiosInstance.notifications
      .notificationsControllerViewNotification(notificationUI._id)
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const submitComplianceNote = (note: string) => {
    return AxiosInstance.candidates.candidatesControllerCreateComplianceNoteCandidate(
      id!,
      {
        text: note,
      },
    );
  };

  const onOpenNotifications = () => {
    openModal({
      title: 'Updates',
      component: (
        <NewNotificationsList
          notifications={notifications}
          onClickNotificationItem={onClickNotificationItem}
          getNotifications={getNotifications}
          data-testid="new-notifications-list"
        />
      ),
    });
  };

  const onClickGoBack = () => {
    navigate('/candidates');
  };

  const onClickTab = (url: string) => {
    navigate(url);
  };

  const onClickCreateComplianceNote = () => {
    openModal({
      title: 'New Note',
      component: (
        <NoteForm
          onCancel={() => closeModal()}
          onSuccess={() => {
            getCandidateDetails();
            closeModal();
          }}
          submitRequest={submitComplianceNote}
        />
      ),
    });
  };

  const deleteComplianceNote = async (noteId: string) => {
    AxiosInstance.candidates
      .candidatesControllerDeleteComplianceNoteByCandidateIdAndNoteId(
        id!,
        noteId,
      )
      .then(() => {
        getCandidateDetails();
        closeConfirm();
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const getCandidateDetails = () => {
    AxiosInstance.candidates
      .candidatesControllerGetCandidate(id!, { analytics: true })
      .then((response) => {
        setCandidate(response.data);
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const getNotifications = async () => {
    await AxiosInstance.notifications
      .notificationsControllerFindAll()
      .then((response) => {
        const notificationsResponse = response.data.items;
        const result = notificationsResponse
          .map((el) => getNotificationData(el))
          .filter((el) => el?.title) as TNotificationUI[];
        setNotifications(result);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  useEffect(() => {
    if (!type) {
      setSelectedTabCompliance(null);
    }
    const selectedCompliance =
      compliancesList.find((com) => com.taskName === type) ?? null;

    setSelectedTabCompliance(selectedCompliance);
  }, [location, compliancesList]);

  useEffect(() => {
    let tab = tabs.find((el) => el.url === location.pathname) ?? null;
    if (!tab && type) {
      const complianceTab = `/candidates/${id}/compliance/${type}`;
      if (decodeURIComponent(location.pathname) === complianceTab) {
        tab = {
          name: ECandidateComplianceTabs.COMPLIANCE,
          url: `/candidates/${id}/compliance`,
        };
      }
    }

    setSelectedTab(tab);
  }, [location]);

  const onClickSetting = () => {
    openModal({
      title: 'Compliances',
      component: (
        <AddComplianceForm
          selectedTasks={compliancesList.map((el) => el.taskName)}
        />
      ),
    });
  };

  useEffect(() => {
    getCandidateDetails();
    getNotifications();
  }, []);

  if (!candidate) {
    return <></>;
  }

  return (
    <>
      <CandidateComplianceDetailsContainer data-testid="candidate-compliance-details-container">
        <div className="candidate-header" data-testid="candidate-header">
          <div
            className="back-button"
            onClick={onClickGoBack}
            data-testid="back-button"
          >
            <LeftArrowIcon />
          </div>
          <TitlePage data-testid="candidate-title">Detail Candidate</TitlePage>
        </div>

        <CardContainer
          className="page-summary-container"
          data-testid="page-summary-container"
        >
          <div className="module-name" data-testid="module-name">
            <UserIcon
              firstName={candidate.firstName}
              lastName={candidate.lastName}
              entity="candidate"
              data-testid="user-icon"
            />
            <div className="module-info" data-testid="module-info">
              <div className="name" data-testid="candidate-name">
                {candidate.firstName} {candidate.lastName}
              </div>
              <div className="availability" data-testid="candidate-city">
                {candidate.address?.city}
              </div>
            </div>
          </div>
          <div className="module-contact" data-testid="candidate-phone-number">
            <CallIcon />
            <div>{candidate.phoneNumber}</div>
          </div>
          <div className="module-contact" data-testid="candidate-email">
            <MessageIcon />
            <div>{candidate.email}</div>
          </div>
          <div className="actions-container" data-testid="actions-container">
            <Button
              type="primary"
              onClick={() => navigate(`/messages/candidate/${candidate._id}`)}
              data-testid="send-message-button"
            >
              Message
            </Button>
          </div>
          <div
            className="edit-container disabled"
            // onClick={() => onClickEdit()}
            data-testid="edit-candidate-button"
          >
            <EditIcon />
          </div>
        </CardContainer>

        <CardContainer
          className="candidate-information-container"
          data-testid="candidate-info-container"
        >
          <TabPageContainer data-testid="tab-page-container">
            <div className="tab-items-list" data-testid="tab-items-list">
              {tabs.map((el) => (
                <ATag
                  key={el.url}
                  className={`tab-item ${el.url === selectedTab?.url ? 'active' : ''}`}
                  onClick={() => onClickTab(el.url)}
                  data-testid={`tab-item-${el.name}`}
                >
                  {el.name}
                </ATag>
              ))}
            </div>
          </TabPageContainer>
        </CardContainer>

        {selectedTab?.name === ECandidateComplianceTabs.GENERAL && (
          <CardContainer
            className="candidate-information-container"
            data-testid="compliance-edit-container"
          >
            <TabPageContainer data-testid="tab-page-container">
              <div
                className="tab-content-container"
                data-testid="tab-content-container"
              >
                <CandidateGeneral
                  candidate={candidate}
                  data-testid="candidate-details-general"
                />
              </div>
            </TabPageContainer>
          </CardContainer>
        )}

        {selectedTab?.name === ECandidateComplianceTabs.DOCUMENTS && (
          <CardContainer
            className="candidate-information-container"
            data-testid="compliance-edit-container"
          >
            <TabPageContainer data-testid="tab-page-container">
              <div
                className="tab-content-container"
                data-testid="tab-content-container"
              >
                <CandidateDocuments
                  candidate={candidate}
                  data-testid="candidate-details-general"
                />
              </div>
            </TabPageContainer>
          </CardContainer>
        )}

        {selectedTab?.name === ECandidateComplianceTabs.COMPLIANCE && (
          <>
            {selectedTabCompliance ? (
              <CardContainer
                className="candidate-information-container"
                data-testid="compliance-edit-container"
              >
                <TabPageContainer data-testid="compliance-edit-tab">
                  <div
                    className="tab-content-container"
                    data-testid="compliance-edit-content"
                  >
                    <ComplianceContainer data-testid="compliance-container">
                      <ComplianceEdit
                        selectedCompliance={selectedTabCompliance}
                        data-testid="compliance-edit"
                      />
                    </ComplianceContainer>
                  </div>
                </TabPageContainer>
              </CardContainer>
            ) : (
              <div
                className="compliance-list-container"
                data-testid="compliance-list-container"
              >
                <CardContainer
                  className="candidate-information-container"
                  data-testid="compliance-info-container"
                >
                  {!!compliancesList.length && (
                    <TitleCard data-testid="compliance-title-card">
                      Compliance
                    </TitleCard>
                  )}
                  <TabPageContainer data-testid="compliance-tab-container">
                    {compliancesList.length ? (
                      <div
                        className="tab-content-container"
                        data-testid="compliance-tab-content"
                      >
                        <ComplianceContainer data-testid="compliance-list-container">
                          <div
                            className="info-card compliance-info-container"
                            data-testid="compliance-info-card"
                          >
                            <div
                              className="compliance-status"
                              data-testid="compliance-status"
                            >
                              Compliance Status :
                              <ComplianceStatus
                                value={complianceCompletion.status}
                                data-testid="compliance-status-value"
                              />
                            </div>
                            <div className="compliance-progress-container">
                              <ProgressBar
                                value={complianceCompletion.percentage}
                                data-testid="progress-bar"
                              />
                              <div
                                className="setting-button-container"
                                onClick={onClickSetting}
                                data-testid="setting-button"
                              >
                                <SettingIcon />
                              </div>
                            </div>
                          </div>
                          <div
                            className="data-table-container"
                            data-testid="compliance-list-table"
                          >
                            <ComplianceList />
                          </div>
                        </ComplianceContainer>
                      </div>
                    ) : (
                      <ComplianceStart />
                    )}
                  </TabPageContainer>
                </CardContainer>
                <CardContainer
                  className="compliance-updates-container"
                  data-testid="compliance-updates-container"
                >
                  <TitleCard data-testid="updates-title-card">
                    Updates
                  </TitleCard>
                  <TabPageContainer data-testid="updates-tab-container">
                    <div
                      className="tab-content-container"
                      data-testid="updates-tab-content"
                    >
                      <ComplianceContainer data-testid="compliance-updates-container">
                        {!!notifications.length ? (
                          <NewNotificationsListContainer data-testid="notifications-list-container">
                            <div
                              className="notification-content-list"
                              data-testid="notification-list"
                            >
                              {notifications.slice(0, 5).map((el) => (
                                <div
                                  key={el._id}
                                  className="notification-item"
                                  onClick={() => onClickNotificationItem(el)}
                                  data-testid={`notification-item-${el._id}`}
                                >
                                  <div className="notification-item-top">
                                    <div
                                      className="notification-title"
                                      data-testid="notification-title"
                                    >
                                      {el?.title}
                                    </div>
                                    <div
                                      className="notification-time"
                                      data-testid="notification-time"
                                    >
                                      {getTimeAgo(el.createdDate)}
                                    </div>
                                  </div>
                                  <div className="notification-item-bottom">
                                    <div
                                      className="notification-description"
                                      data-testid="notification-description"
                                    >
                                      {el?.description}
                                    </div>
                                    {el.isNew && (
                                      <NewSmallNotificationRedIcon data-testid="new-small-notification" />
                                    )}
                                  </div>
                                </div>
                              ))}
                            </div>
                            <br />
                            <Button
                              onClick={() => onOpenNotifications()}
                              type="primary"
                              data-testid="show-all-notifications-button"
                            >
                              Show All
                            </Button>
                          </NewNotificationsListContainer>
                        ) : (
                          <div
                            className="no-items pt-5"
                            data-testid="no-updates-message"
                          >
                            No updates yet
                          </div>
                        )}
                      </ComplianceContainer>
                    </div>
                  </TabPageContainer>
                </CardContainer>
              </div>
            )}
          </>
        )}

        {selectedTab?.name === ECandidateComplianceTabs.AUDIT && (
          <>
            <div
              className="compliance-list-container"
              data-testid="compliance-list-container"
            >
              <CardContainer
                className="candidate-information-container"
                data-testid="compliance-info-container"
              >
                <TabPageContainer data-testid="compliance-tab-container">
                  {compliancesList.length ? (
                    <div
                      className="tab-content-container"
                      data-testid="compliance-tab-content"
                    >
                      <ComplianceContainer data-testid="compliance-list-container">
                        <div
                          className="data-table-container"
                          data-testid="compliance-list-table"
                        >
                          <ComplianceAudit compliancesList={compliancesList} />
                        </div>
                      </ComplianceContainer>
                    </div>
                  ) : (
                    <ComplianceStart />
                  )}
                </TabPageContainer>
              </CardContainer>
            </div>
          </>
        )}

        {selectedTab?.name === ECandidateComplianceTabs.COMPLIANCE_NOTES && (
          <CardContainer
            className="candidate-information-container"
            data-testid="compliance-edit-container"
          >
            <TabPageContainer data-testid="tab-page-container">
              <div
                className="tab-content-container"
                data-testid="tab-content-container"
              >
                <NoteTab
                  notes={candidate.complianceNotes ?? []}
                  onCreateNote={onClickCreateComplianceNote}
                  onDeleteNote={deleteComplianceNote}
                />
              </div>
            </TabPageContainer>
          </CardContainer>
        )}
      </CandidateComplianceDetailsContainer>
    </>
  );
};
