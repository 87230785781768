import React, { useEffect, useState } from 'react';
import { LeftArrowIcon } from '../../../icons';
import {
  ATag,
  CardContainer,
  TitlePage,
  DragDropUpload,
  TabPageContainer,
  DocumentsListComponent,
} from '../../../components';
import { VacancyDetailsContainer } from './job-details.style';
import { AxiosInstance, formatDate, uploadJobFiles } from '../../../utils';
import { useNavigate, useParams } from 'react-router-dom';
import { HostedFile, Job } from '../../../backend/careo-api';
import { toast } from 'react-toastify';

export enum EVacancyDetailsTabs {
  GENERAL = 'General',
  CONTACT = 'Contact',
  DOCUMENTS = 'Documents',
}

const tabs = [
  EVacancyDetailsTabs.GENERAL,
  EVacancyDetailsTabs.CONTACT,
  EVacancyDetailsTabs.DOCUMENTS,
];

export const VacancyDetailsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState(EVacancyDetailsTabs.GENERAL);
  const [job, setJob] = useState<Job | null>(null);
  const [jobDocuments, setJobDocuments] = useState<HostedFile[]>([]);

  const getVacancyDetails = () => {
    AxiosInstance.jobs
      .jobsControllerGetJob(id!)
      .then((response) => {
        setJob(response.data);
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const onClickGoBack = () => {
    navigate(-1);
  };

  const getJobDocuments = () => {
    AxiosInstance.files
      .filesControllerFindJobDocs(id!)
      .then((response) => {
        setJobDocuments(response.data.items);
      })
      .catch(() => {
        toast.error('Failed to get client documents');
      });
  };

  const handleFileChange = async (files: File[]) => {
    await uploadJobFiles(id!, files)
      .then(() => {
        toast.success('Documents uploaded successfully');
        getJobDocuments();
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const onClickDelete = async (documentId: string) => {
    AxiosInstance.files
      .filesControllerDeleteFile(documentId)
      .then(() => {
        toast.success('File deleted successfully');
        getJobDocuments();
      })
      .catch(() => {
        toast.error('Failed to delete file');
      });
  };

  useEffect(() => {
    getVacancyDetails();
  }, []);

  if (!job) {
    return <></>;
  }

  return (
    <VacancyDetailsContainer data-testid="job-details-container">
      <div className="job-header" data-testid="job-header">
        <div
          className="back-button"
          onClick={onClickGoBack}
          data-testid="back-button"
        >
          <LeftArrowIcon />
        </div>
        <TitlePage data-testid="job-title">Detail Vacancy</TitlePage>
      </div>
      <CardContainer
        className="job-information-container"
        data-testid="job-information-container"
      >
        <TabPageContainer data-testid="tab-page-container">
          <div className="tab-items-list" data-testid="tab-items-list">
            {tabs.map((el) => (
              <ATag
                className={`tab-item ${el === selectedTab && 'active'}`}
                onClick={() => setSelectedTab(el)}
                data-testid={`tab-item-${el}`}
                key={el}
              >
                {el}
              </ATag>
            ))}
          </div>
          <div
            className="tab-content-container"
            data-testid="tab-content-container"
          >
            {selectedTab === EVacancyDetailsTabs.GENERAL && (
              <>
                <div className="info-card" data-testid="general-job-details">
                  <div
                    className="info-card-title"
                    data-testid="info-card-title"
                  >
                    Vacancy Details
                  </div>
                  <hr data-testid="job-details-divider" />
                  <div
                    className="info-card-content row"
                    data-testid="job-details-content"
                  >
                    <div className="col-md-6 row" data-testid="job-client-name">
                      <div className="info-item-title col-4">Client Name</div>
                      <div className="info-item-data col-8">
                        {job?.client?.clientName}
                      </div>
                    </div>
                    <div className="col-md-6 row" data-testid="job-specialty">
                      <div className="info-item-title col-4">Specialty</div>
                      <div className="info-item-data col-8">
                        {job.specialty}
                      </div>
                    </div>
                    <div
                      className="col-md-6 row"
                      data-testid="job-job-description"
                    >
                      <div className="info-item-title col-4">
                        Job Description
                      </div>
                      <div className="info-item-data col-8">
                        {job.jobDescription}
                      </div>
                    </div>
                    <div className="col-md-6 row" data-testid="job-grade">
                      <div className="info-item-title col-4">Grade</div>
                      <div className="info-item-data col-8">{job.grade}</div>
                    </div>
                    <div className="col-md-6 row" data-testid="job-start-date">
                      <div className="info-item-title col-4">Start Date</div>
                      <div className="info-item-data col-8">
                        {formatDate(job.vacantFrom)}
                      </div>
                    </div>
                    <div className="col-md-6 row" data-testid="job-end-date">
                      <div className="info-item-title col-4">End Date</div>
                      <div className="info-item-data col-8">
                        {formatDate(job.vacantTo)}
                      </div>
                    </div>
                    <div className="col-md-6 row" data-testid="job-pay-option">
                      <div className="info-item-title col-4">Pay Option</div>
                      <div className="info-item-data col-8">
                        {job.payOption}
                      </div>
                    </div>
                    <div className="col-md-6 row" data-testid="job-vat">
                      <div className="info-item-title col-4">VAT</div>
                      <div className="info-item-data col-8">{job.vat}</div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {selectedTab === EVacancyDetailsTabs.CONTACT && (
              <div className="info-card" data-testid="contact-details">
                <div
                  className="info-card-title"
                  data-testid="contact-details-title"
                >
                  Contact Details
                </div>
                <hr data-testid="contact-details-divider" />
                <div
                  className="info-card-content row"
                  data-testid="contact-details-content"
                >
                  <div className="col-md-6 row" data-testid="contact-title">
                    <div className="info-item-title col-4">Title</div>
                    <div className="info-item-data col-8">
                      {job?.client?.title}
                    </div>
                  </div>
                  <div
                    className="col-md-6 row"
                    data-testid="contact-first-name"
                  >
                    <div className="info-item-title col-4">First Name</div>
                    <div className="info-item-data col-8">
                      {job?.client?.firstName}
                    </div>
                  </div>
                  <div className="col-md-6 row" data-testid="contact-last-name">
                    <div className="info-item-title col-4">Last Name</div>
                    <div className="info-item-data col-8">
                      {job?.client?.lastName}
                    </div>
                  </div>
                  <div className="col-md-6 row" data-testid="contact-job-title">
                    <div className="info-item-title col-4">Job Title</div>
                    <div className="info-item-data col-8">
                      {job?.client?.jobTitle}
                    </div>
                  </div>
                  <div className="col-md-6 row" data-testid="contact-email">
                    <div className="info-item-title col-4">Email Address</div>
                    <div className="info-item-data col-8">
                      {job?.client?.email}
                    </div>
                  </div>
                  <div
                    className="col-md-6 row"
                    data-testid="contact-phone-number"
                  >
                    <div className="info-item-title col-4">Mobile Number</div>
                    <div className="info-item-data col-8">
                      {job?.client?.phoneNumber}
                    </div>
                  </div>
                  <div
                    className="col-md-6 row"
                    data-testid="contact-work-phone-number"
                  >
                    <div className="info-item-title col-4">
                      Work Phone Number
                    </div>
                    <div className="info-item-data col-8">
                      {job?.client?.workPhoneNumber}
                    </div>
                  </div>
                  <div className="col-md-6 row" data-testid="contact-website">
                    <div className="info-item-title col-4">Website</div>
                    <div className="info-item-data col-8">
                      {job?.client?.website}
                    </div>
                  </div>
                  <div
                    className="col-md-6 row"
                    data-testid="contact-fax-number"
                  >
                    <div className="info-item-title col-4">Fax Number</div>
                    <div className="info-item-data col-8">
                      {job?.client?.faxNumber}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {selectedTab === EVacancyDetailsTabs.DOCUMENTS && (
              <>
                <div className="info-card" data-testid="personal-documents">
                  <div
                    className="info-card-title"
                    data-testid="personal-documents-title"
                  >
                    Personal Documents
                  </div>
                  <hr data-testid="documents-divider" />
                  <DocumentsListComponent
                    documents={jobDocuments}
                    onClickDelete={onClickDelete}
                    getDocuments={getJobDocuments}
                    data-testid="documents-list"
                  />
                </div>
                <div className="info-card" data-testid="drag-drop-upload">
                  <DragDropUpload onUpload={handleFileChange} />
                </div>
              </>
            )}
          </div>
        </TabPageContainer>
      </CardContainer>
    </VacancyDetailsContainer>
  );
};
