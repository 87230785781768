import React from 'react';
import { Candidate } from '../../../backend/careo-api';
import { formatDate, formatCurrency } from '../../../utils';
import { AddressDetails } from '../../address/address-details.component';

type CandidateGeneralProps = {
  candidate: Candidate;
};

export const CandidateGeneral = ({ candidate }: CandidateGeneralProps) => {
  return (
    <>
      <div className="info-card" data-testid="info-card">
        <div className="info-card-title" data-testid="info-card-title">
          Personal Info
        </div>
        <hr />
        <div className="info-card-content row">
          <div className="col-md-6 row" data-testid="full-name-row">
            <div
              className="info-item-title col-4"
              data-testid="full-name-title"
            >
              Full Name
            </div>
            <div className="info-item-data col-8" data-testid="full-name-data">
              {candidate.firstName} {candidate.lastName}
            </div>
          </div>
          <div className="col-md-6 row" data-testid="gender-row">
            <div className="info-item-title col-4" data-testid="gender-title">
              Gender
            </div>
            <div className="info-item-data col-8" data-testid="gender-data">
              {candidate.gender || '-'}
            </div>
          </div>
          <div className="col-md-6 row" data-testid="dob-row">
            <div className="info-item-title col-4" data-testid="dob-title">
              Date of Birth
            </div>
            <div className="info-item-data col-8" data-testid="dob-data">
              {candidate.birthDate ? formatDate(candidate.birthDate) : '-'}
            </div>
          </div>
          <div className="col-md-6 row" data-testid="nationality-row">
            <div
              className="info-item-title col-4"
              data-testid="nationality-title"
            >
              Nationality
            </div>
            <div
              className="info-item-data col-8"
              data-testid="nationality-data"
            >
              {candidate.nationality || '-'}
            </div>
          </div>
          <div className="col-md-6 row" data-testid="email-row">
            <div className="info-item-title col-4" data-testid="email-title">
              Email Address
            </div>
            <div className="info-item-data col-8" data-testid="email-data">
              {candidate.email}
            </div>
          </div>
          <div className="col-md-6 row" data-testid="job-title-row">
            <div
              className="info-item-title col-4"
              data-testid="job-title-title"
            >
              Job title
            </div>
            <div className="info-item-data col-8" data-testid="job-title-data">
              {candidate.designation || '-'}
            </div>
          </div>
          <div className="col-md-6 row" data-testid="grade-row">
            <div className="info-item-title col-4" data-testid="grade-title">
              Grade
            </div>
            <div className="info-item-data col-8" data-testid="grade-data">
              {candidate.grade || '-'}
            </div>
          </div>
          <div className="col-md-6 row" data-testid="department-row">
            <div
              className="info-item-title col-4"
              data-testid="department-title"
            >
              Departments
            </div>
            <div className="info-item-data col-8" data-testid="department-data">
              {candidate.departments?.join(', ') || '-'}
            </div>
          </div>
          <div className="col-md-6 row" data-testid="registration-number-row">
            <div
              className="info-item-title col-4"
              data-testid="registration-number-title"
            >
              Professional Registration Number
            </div>
            <div
              className="info-item-data col-8"
              data-testid="registration-number-data"
            >
              {candidate.professionalRegistrations
                ?.map((p) => p.number)
                .join(', ') ?? '-'}
            </div>
          </div>
          <div
            className="col-md-6 row"
            data-testid="registration-authority-row"
          >
            <div
              className="info-item-title col-4"
              data-testid="registration-authority-title"
            >
              Professional Registration Authority
            </div>
            <div
              className="info-item-data col-8"
              data-testid="registration-authority-data"
            >
              {candidate.professionalRegistrations
                ?.map((p) => p.authority)
                .join(', ') ?? '-'}
            </div>
          </div>
          <div className="col-md-6 row" data-testid="pay-rate-row">
            <div className="info-item-title col-4" data-testid="pay-rate-title">
              Pay Rate
            </div>
            <div className="info-item-data col-8" data-testid="pay-rate-data">
              {formatCurrency(candidate.payAmount)}
            </div>
          </div>
        </div>
      </div>
      <AddressDetails address={candidate.address} />
    </>
  );
};
