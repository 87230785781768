import { useQuery, useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import {
  deleteCampaign,
  getCampaigns,
  createCampaign,
  updateCampaign,
  getCampaignById,
} from '../api/campaigns.api';
import {
  CreateCampaignDto,
  Campaign,
  ESocketRefreshModule,
} from '../../backend/careo-api';

type SaveCampaignVariables = {
  campaignData: CreateCampaignDto;
  selectedCampaign?: Campaign;
};

type UseCampaignProps = {
  campaignsList: Campaign[];
  isLoading: boolean;

  saveCampaign: (variables: SaveCampaignVariables) => Promise<void>;
  deleteCampaign: (campaign: Campaign) => Promise<void>;
  findCampaignById: (campaignId: string) => Promise<Campaign>;
};

export const useCampaigns = (): UseCampaignProps => {
  // Fetch campaigns (React Query caches it)
  const { data: campaigns = [], isLoading } = useQuery({
    queryKey: [ESocketRefreshModule.CAMPAIGNS],
    queryFn: getCampaigns,
  });

  // Delete campaign mutation
  const deleteMutation = useMutation({
    mutationFn: (selectedCampaign: Campaign) => {
      return deleteCampaign(selectedCampaign);
    },
    onSuccess: () => {
      toast.success('Campaign removed successfully');
    },
    onError: (error: any) => {
      toast.error(error.message);
    },
  });

  // Create or Update Campaign Mutation
  const saveCampaignMutation = useMutation({
    mutationFn: ({
      campaignData,
      selectedCampaign,
    }: {
      selectedCampaign?: Campaign;
      campaignData: CreateCampaignDto;
    }) => {
      return (
        selectedCampaign
          ? updateCampaign(selectedCampaign._id, campaignData)
          : createCampaign(campaignData)
      ).then(() => {});
    },
    onSuccess: () => {
      toast.success('Campaign saved successfully');
    },
    onError: (error: any) => {
      toast.error(error?.message || 'Something went wrong');
    },
  });

  return {
    campaignsList: campaigns,
    isLoading,
    saveCampaign: saveCampaignMutation.mutateAsync,
    deleteCampaign: deleteMutation.mutateAsync,
    findCampaignById: getCampaignById,
  };
};
