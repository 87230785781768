import React from 'react';
import { Button } from '../ui';
import { EUserRole, Note } from '../../backend/careo-api';
import { formatDateTime } from '../../utils';
import { useAuth } from '../../contexts/auth.context';
import { DeleteIcon } from '../../icons';
import { useConfirm } from '../../contexts/confirm-modal.context';

type NoteTabProps = {
  notes: Note[];
  onCreateNote: () => void;
  onDeleteNote: (noteId: string) => Promise<void>;
};

const NoteTab = ({ notes, onCreateNote, onDeleteNote }: NoteTabProps) => {
  const { user } = useAuth();
  const isAdmin = user?.role === EUserRole.Admin;

  const { openConfirm, closeConfirm } = useConfirm();

  const onDeleteConfirm = (item: Note) => {
    openConfirm({
      title: 'Delete Note',
      component: <>Do you want to delete this note ? </>,
      onConfirm: () =>
        onDeleteNote(item._id)
          .then(() => closeConfirm())
          .catch(() => {}),
    });
  };

  return (
    <>
      <div className="info-card" data-testid="notes-tab-content">
        <div className="info-card-header" data-testid="notes-header">
          <div className="info-card-title" data-testid="notes-title">
            Notes Info
          </div>
          <Button
            type="primary"
            onClick={() => onCreateNote()}
            data-testid="add-notes-button"
          >
            Add Notes
          </Button>
        </div>
        <hr />
        {notes?.length ? (
          <>
            {notes.map((el) => (
              <div className="info-card">
                <div className="info-card-content w-100 notes-container">
                  <div className="left-container">
                    <div className="info-item-title">
                      <div>
                        {el.user.firstName} {el.user.lastName}
                      </div>
                      <div>{formatDateTime(el.createdAt)}</div>
                    </div>
                    <div className="info-item-data" data-testid="note-text">
                      {el.text}
                    </div>
                  </div>
                  <div className="action-item">
                    <div
                      className={`delete-icon ${!isAdmin && 'disabled'}`}
                      onClick={() => isAdmin && onDeleteConfirm(el)}
                    >
                      <DeleteIcon />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        ) : (
          <div className="w-100 text-center" data-testid="no-notes-found">
            No item found
          </div>
        )}
      </div>
    </>
  );
};

export default NoteTab;
