import {
  Application,
  CreateApplicationDto,
  DurationDto,
  UpdateApplicationStatusDto,
} from '../../backend/careo-api';
import { AxiosInstance } from '../../utils';

export const createApplication = async (requestDto: CreateApplicationDto) => {
  const response =
    await AxiosInstance.applications.applicationsControllerCreate(requestDto);
  return response.data;
};

// Fetch all applications
export const getJobApplications = async (jobId?: string) => {
  const response =
    await AxiosInstance.applications.applicationsControllerFindAll({
      jobId,
    });
  return response.data.items;
};

export const getApplicationById = async (applicationId: string) => {
  const response =
    await AxiosInstance.applications.applicationsControllerGetApplicationById(
      applicationId,
    );
  return response.data;
};

// Update a application
export const updateApplicationDuration = async (
  id: string,
  durationDto: DurationDto,
) => {
  const response =
    await AxiosInstance.applications.applicationsControllerUpdateApplicationDuration(
      id,
      durationDto,
    );
  return response.data;
};

export const updateApplicationStatus = async (
  id: string,
  requestDto: UpdateApplicationStatusDto,
) => {
  const response =
    await AxiosInstance.applications.applicationsControllerUpdateApplicationStatus(
      id,
      requestDto,
    );
  return response.data;
};

export const deleteApplication = async (application: Application) => {
  await AxiosInstance.applications.applicationsControllerDeleteApplication(
    application._id,
  );
};
