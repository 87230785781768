import { useQuery, useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import {
  getAvailabilities,
  createAvailability,
  deleteAvailabilityByRangeDate,
  deleteMultipleAvailabilities,
} from '../api/availabilities.api';
import {
  AddAvailabilityDto,
  Availability,
  DeleteAvailabilityDto,
  ESocketRefreshModule,
} from '../../backend/careo-api';

type SaveAvailabilityVariables = {
  availabilityData: AddAvailabilityDto;
  selectedAvailability?: Availability;
};

type UseAvailabilityProps = {
  availabilitiesList: Availability[];
  isLoading: boolean;
  saveAvailability: (variables: SaveAvailabilityVariables) => Promise<void>;
  deleteMultipleAvailabilities: (
    availabilities: Availability[],
  ) => Promise<void>;
  deleteByRangeDate: (requestDto: DeleteAvailabilityDto) => Promise<void>;
};

export const useAvailabilities = (
  candidateId: string,
): UseAvailabilityProps => {
  // Fetch availabilities (React Query caches it)
  const { data: availabilities = [], isLoading } = useQuery({
    queryKey: [ESocketRefreshModule.AVAILABILITIES, candidateId],
    queryFn: async () => await getAvailabilities(candidateId),
    enabled: !!candidateId, // Ensures query runs only if candidateId exists
  });

  // Delete availability mutation
  const deleteMutation = useMutation({
    mutationFn: (selectedAvailabilities: Availability[]) => {
      return deleteMultipleAvailabilities(selectedAvailabilities);
    },
    onSuccess: () => {
      toast.success('Availability removed successfully');
    },
    onError: (error: any) => {
      toast.error(error.message);
    },
  });

  // Delete availability mutation
  const deleteByRangeMutationMutation = useMutation({
    mutationFn: (requestDto: DeleteAvailabilityDto) => {
      return deleteAvailabilityByRangeDate(requestDto);
    },
    onSuccess: () => {
      toast.success('Availability removed successfully');
    },
    onError: (error: any) => {
      toast.error(error.message);
    },
  });

  // Create or Update Availability Mutation
  const saveAvailabilityMutation = useMutation({
    mutationFn: ({
      availabilityData,
      selectedAvailability,
    }: {
      selectedAvailability?: Availability;
      availabilityData: AddAvailabilityDto;
    }) => {
      return (
        // selectedAvailability
        //   ? updateAvailability(selectedAvailability._id, availabilityData)
        //   :
        createAvailability(availabilityData).then(() => {})
      );
    },
    onSuccess: () => {
      toast.success('Availability saved successfully');
    },
    onError: (error: any) => {
      toast.error(error?.message || 'Something went wrong');
    },
  });

  return {
    availabilitiesList: availabilities,
    isLoading,
    saveAvailability: saveAvailabilityMutation.mutateAsync,
    deleteMultipleAvailabilities: deleteMutation.mutateAsync,
    deleteByRangeDate: deleteByRangeMutationMutation.mutateAsync,
  };
};
