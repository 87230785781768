import React, { useMemo, useState } from 'react';
import { ATag, Button, Table, TabPageContainer } from '../ui';
import { useParams } from 'react-router-dom';
import { formatCurrency } from '../../utils';
import {
  ChargesRates,
  Client,
  EChargeRateType,
  ESpecialty,
} from '../../backend/careo-api';
import { NestedRows } from './charges-rates.style';
import { useModal } from '../../contexts/side-modal.context';
import { RateForm } from './new-rate-form.component';
import { EditIcon } from '../../icons';

type ClientRatesProps = {
  client: Client;
  getClientDetails: () => void;
  specialty?: ESpecialty;
};

const tabs = [
  EChargeRateType.Doctors,
  EChargeRateType.NursesHealthCareAssistants,
];

export const ChargesRatesList = ({
  client,
  getClientDetails,
  specialty,
}: ClientRatesProps) => {
  const { id } = useParams();
  const [selectedTab, setSelectedTab] = useState(EChargeRateType.Doctors);

  const { openModal, closeModal } = useModal();

  const chargesRates = useMemo(() => {
    const result = client.chargesRates.find((item) => {
      return specialty
        ? item.specialty === specialty && item.type === selectedTab
        : item.specialty === undefined && item.type === selectedTab;
    });
    return result;
  }, [specialty, selectedTab, client]);

  const onClickCreate = (rate?: ChargesRates) => {
    openModal({
      title: `Charge/Rate Form`,
      component: (
        <RateForm
          selectedType={selectedTab}
          getClientDetails={getClientDetails}
          onClose={() => {
            closeModal();
          }}
          data-testid="new-rate-form"
          selectedRates={rate}
          specialty={specialty}
        />
      ),
    });
  };

  return (
    <>
      <TabPageContainer data-testid="tab-page-container">
        <div className="tab-items-list" data-testid="tab-items-list">
          {tabs.map((el) => (
            <ATag
              className={`tab-item text-capitalize ${
                el === selectedTab && 'active'
              }`}
              onClick={() => setSelectedTab(el)}
              data-testid={`tab-item-${el}`}
            >
              {el}
            </ATag>
          ))}
        </div>
        <div
          className="tab-content-container"
          data-testid="tab-content-container"
        >
          <div className="row">
            <div
              style={{
                display: 'flex',
                justifyContent: 'right',
              }}
            >
              <Button
                type={!!!chargesRates ? 'primary' : 'success'}
                onClick={() => onClickCreate(chargesRates)}
                data-testid="add-rate-button"
              >
                {!!chargesRates && (
                  <EditIcon color="White" title="Edit Rate " />
                )}
                {!!!chargesRates ? 'Add new rate' : 'Edit Rates'}
              </Button>
            </div>
          </div>
          <Table data-testid="rates-table">
            <thead>
              <tr>
                <th
                  className=""
                  data-testid="job-title-header"
                  style={{ width: '200px' }}
                >
                  <div>
                    <label>Grade</label>
                  </div>
                </th>
                <th className="" data-testid="grade-header">
                  <div>
                    <label>Shift Type</label>
                  </div>
                </th>
                <th className="" data-testid="charge-rate-header">
                  <div>
                    <label>Price Cap</label>
                  </div>
                </th>
                <th className="center-text" data-testid="charge-rate-header">
                  <div>
                    <label>Charge</label>
                  </div>
                </th>
                <th data-testid="actions-header">
                  <div></div>
                </th>
              </tr>
            </thead>
            <tbody>
              {(chargesRates?.pricing ?? []).length > 0 ? (
                <>
                  {(chargesRates?.pricing ?? [])?.map((item) => {
                    return (
                      <tr
                        key={item.grade}
                        data-testid={`rate-row-${item.grade}`}
                        style={{ maxWidth: '100px', borderColor: 'black' }}
                      >
                        <td
                          className=""
                          data-testid={`rate-grade-${item.grade}`}
                          style={{ color: 'black', fontWeight: 700 }}
                        >
                          {item?.grade}
                        </td>

                        <td
                          style={{ padding: '0 0 0 20px' }}
                          data-testid={`rate-grade-${item.grade}`}
                        >
                          <NestedRows>
                            <div className="row">Core</div>
                            <div className="row">Unsocial</div>
                            <div className="row">On call Active</div>
                            <div className="row">On call Passive</div>
                          </NestedRows>
                        </td>

                        <td data-testid={`rate-grade-${item.grade}`}>
                          <NestedRows>
                            <div className="row" style={{ fontWeight: 700 }}>
                              {formatCurrency(item.corePrice)}
                            </div>
                            <div className="row" style={{ fontWeight: 700 }}>
                              {formatCurrency(item.unSocialPrice)}
                            </div>
                            <div className="row" style={{ fontWeight: 700 }}>
                              {formatCurrency(item.onCallActivePrice)}
                            </div>
                            <div className="row" style={{ fontWeight: 700 }}>
                              {formatCurrency(item.onCallPassivePrice)}
                            </div>
                          </NestedRows>
                          {/* {item?.} */}
                        </td>

                        <td data-testid={`rate-grade-${item.grade}`}>
                          <div
                            className=" center-text"
                            style={{ fontWeight: 700 }}
                          >
                            {formatCurrency(item.charge)}
                          </div>
                          {/* {item?.} */}
                        </td>
                      </tr>
                    );
                  })}{' '}
                </>
              ) : (
                <tr>
                  <td colSpan={100} className="text-center">
                    No item found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </TabPageContainer>
    </>
  );
};
