import { useQuery, useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import {
  deleteTrust,
  getTrusts,
  createTrust,
  updateTrust,
  getTrustById,
} from '../api/trusts.api';
import {
  CreateTrustDto,
  ESocketRefreshModule,
  Trust,
} from '../../backend/careo-api';
import {
  AxiosInstanceErrorResponse,
  uploadTrustsCsvRequest,
} from '../../utils';

type SaveTrustVariables = {
  trustData: CreateTrustDto;
  selectedTrust?: Trust;
};

type UseTrustProps = {
  trustsList: Trust[];
  isLoading: boolean;
  saveTrust: (variables: SaveTrustVariables) => Promise<void>;
  deleteTrust: (trust: Trust) => Promise<void>;
  findTrustById: (trustId: string) => Promise<Trust>;
  uploadTrustsCsv: (file: File) => void;
};

export const useTrusts = (): UseTrustProps => {
  // Fetch trusts (React Query caches it)
  const { data: trusts = [], isLoading } = useQuery({
    queryKey: [ESocketRefreshModule.TRUSTS],
    queryFn: getTrusts,
  });

  // Delete trust mutation
  const deleteMutation = useMutation({
    mutationFn: (selectedTrust: Trust) => {
      return deleteTrust(selectedTrust);
    },
    onSuccess: () => {
      toast.success('Trust removed successfully');
    },
    onError: (error: any) => {
      toast.error(error.message);
    },
  });

  // Create or Update Trust Mutation
  const saveTrustMutation = useMutation({
    mutationFn: ({
      trustData,
      selectedTrust,
    }: {
      selectedTrust?: Trust;
      trustData: CreateTrustDto;
    }) => {
      return (
        selectedTrust
          ? updateTrust(selectedTrust._id, trustData)
          : createTrust(trustData)
      ).then(() => {});
    },
    onSuccess: () => {
      toast.success('Trust saved successfully');
    },
    onError: (error: any) => {
      toast.error(error?.message || 'Something went wrong');
    },
  });

  const uploadTrustsCsv = async (file: File) => {
    await uploadTrustsCsvRequest(file)
      .then(() => {
        toast.success('Trusts uploaded successfully');
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  return {
    trustsList: trusts,
    isLoading,
    saveTrust: saveTrustMutation.mutateAsync,
    deleteTrust: deleteMutation.mutateAsync,
    findTrustById: getTrustById,
    uploadTrustsCsv,
  };
};
