import React, { useEffect, useState } from 'react';
import { TitleCard, RadioGroup, FieldText } from '../../ui';
import {
  Compliance,
  ComplianceFile,
  ComplianceMetaData,
  LifeSupportType,
  HostedFile,
} from '../../../backend/careo-api';
import ComplianceUpload from '../compliance-edit/compliance-upload';

type LifeSupportProps = {
  compliance: Compliance;
  documents: HostedFile[];
  onClickSubmit: (
    id: string,
    values: ComplianceMetaData,
    validate?: boolean,
    successMessage?: string,
  ) => void;
  getDocuments: () => void;
  onUpdateValidation: (id: string, value: string) => void;
};

type TFilesKey =
  | 'blsCertificateFiles'
  | 'ilsCertificateFiles'
  | 'alsCertificateFiles';

type TAllFields = TFilesKey | 'lifeSupportType' | 'lifeSupportExpiryDate';

export const LifeSupport = ({
  compliance,
  documents,
  onClickSubmit,
  getDocuments,
  onUpdateValidation,
}: LifeSupportProps) => {
  const [isApproved, setIsApproved] = useState<boolean>();
  const [lifeSupportType, setLifeSupportType] = useState<LifeSupportType>();
  const [lifeSupportExpiryDate, setLifeSupportExpiryDate] = useState<string>();

  const [filesList, setFilesList] = useState<
    { key: TFilesKey; data: ComplianceFile[] }[]
  >([
    { key: 'blsCertificateFiles', data: [] },
    { key: 'ilsCertificateFiles', data: [] },
    { key: 'alsCertificateFiles', data: [] },
  ]);

  const onUpdateCompliance = (
    key: TAllFields,
    value: any,
    successMessage?: string,
  ) => {
    onClickSubmit(
      compliance._id,
      {
        [key]: value,
      },
      false,
      successMessage,
    );
  };

  useEffect(() => {
    if (compliance?._id) {
      setLifeSupportType(compliance.metadata?.lifeSupportType);
      setLifeSupportExpiryDate(compliance.metadata?.lifeSupportExpiryDate);
      setFilesList([
        {
          key: 'blsCertificateFiles',
          data: compliance?.metadata?.blsCertificateFiles ?? [],
        },
        {
          key: 'ilsCertificateFiles',
          data: compliance?.metadata?.ilsCertificateFiles ?? [],
        },
        {
          key: 'alsCertificateFiles',
          data: compliance?.metadata?.alsCertificateFiles ?? [],
        },
      ]);
      setIsApproved(compliance.isApproved);
    }
  }, [compliance]);

  return (
    <>
      <>
        <TitleCard data-testid="title-card-application-form-certificate">
          Type of Life Support Certificate
        </TitleCard>
        <RadioGroup
          options={[
            {
              label: 'BLS Certificate',
              value: LifeSupportType.Bls,
            },
            {
              label: 'ILS Certificate',
              value: LifeSupportType.Ils,
            },
            {
              label: 'ALS Certificate',
              value: LifeSupportType.Als,
            },
          ]}
          name="lifeSupportType"
          value={lifeSupportType}
          onChange={(value: any) => {
            setLifeSupportType(value);
            onUpdateCompliance('lifeSupportType', value);
          }}
        />
      </>

      {lifeSupportType === LifeSupportType.Bls && (
        <>
          <TitleCard data-testid="title-card-application-form-certificate">
            BLS Certificate
          </TitleCard>
          <ComplianceUpload
            fileKey="blsCertificateFiles"
            label="Upload Documents"
            isMulti
            description=""
            getDocuments={getDocuments}
            filesList={filesList}
            setFilesList={setFilesList}
            onUpdateCompliance={onUpdateCompliance}
            documents={documents}
          />
        </>
      )}

      {lifeSupportType === LifeSupportType.Ils && (
        <>
          <TitleCard data-testid="title-card-application-form-certificate">
            ILS Certificate
          </TitleCard>
          <ComplianceUpload
            fileKey="ilsCertificateFiles"
            label="Upload Documents"
            isMulti
            description=""
            getDocuments={getDocuments}
            filesList={filesList}
            setFilesList={setFilesList}
            onUpdateCompliance={onUpdateCompliance}
            documents={documents}
          />
        </>
      )}

      {lifeSupportType === LifeSupportType.Als && (
        <>
          <TitleCard data-testid="title-card-application-form-certificate">
            ALS Certificate
          </TitleCard>
          <ComplianceUpload
            fileKey="alsCertificateFiles"
            isMulti
            label="Upload Documents"
            description=""
            getDocuments={getDocuments}
            filesList={filesList}
            setFilesList={setFilesList}
            onUpdateCompliance={onUpdateCompliance}
            documents={documents}
          />
        </>
      )}

      <>
        <TitleCard data-testid="title-card-application-form-certificate">
          Expiry Date
        </TitleCard>
        <div className="row">
          <div className="col-md-6">
            <FieldText
              placeholder="Enter here ..."
              label="Expiry Date"
              required
              type="date"
              value={lifeSupportExpiryDate?.split('T')?.[0]}
              onChange={(e) => {
                setLifeSupportExpiryDate(e.target.value);
              }}
              onBlur={(e) => {
                onUpdateCompliance('lifeSupportExpiryDate', e.target.value);
              }}
            />
          </div>
        </div>
      </>

      {/* Approved Section */}
      {}
      <>
        <TitleCard data-testid="title-card-approved">Approved</TitleCard>
        <RadioGroup
          options={[
            {
              label: 'Pass',
              value: 'true',
            },
            {
              label: 'Fail',
              value: 'false',
            },
          ]}
          name="approved"
          value={isApproved?.toString()}
          onChange={(value: any) => onUpdateValidation(compliance._id, value)}
          data-testid="approved-radio-group"
        />
      </>
    </>
  );
};
