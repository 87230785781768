import React from 'react';
import { ComplianceList } from '../../compliance';

export const CandidateCompliances = () => {
  return (
    <div className="info-card" data-testid="compliance-tab-content">
      <div className="info-card-title" data-testid="compliance-title">
        Compliances List
      </div>
      <hr />
      <div
        className="data-table-container"
        data-testid="compliance-table-container"
      >
        <ComplianceList />
      </div>
    </div>
  );
};
