import { CreateClientDto, Client } from '../../backend/careo-api';
import { AxiosInstance } from '../../utils';

// Fetch all clients
export const getClients = async () => {
  const response = await AxiosInstance.clients.clientsControllerFindAll();
  return response.data.items;
};

export const getClientById = async (id: string) => {
  const response = await AxiosInstance.clients.clientsControllerGetClient(id);
  return response.data;
};

export const createClient = async (clientData: CreateClientDto) => {
  const response =
    await AxiosInstance.clients.clientsControllerCreateClient(clientData);
  return response.data;
};

// Update a client
export const updateClient = async (id: string, clientData: CreateClientDto) => {
  const response = await AxiosInstance.clients.clientsControllerUpdate(
    id,
    clientData,
  );
  return response.data;
};

// Delete a client
export const deleteClient = async (client: Client) => {
  await AxiosInstance.clients.clientsControllerDeleteClient(client._id);
};
