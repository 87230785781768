import styled from 'styled-components';
import { breakpoint } from '../../../constants';

export const VacancyApplicationsListContainer = styled.div`
  flex: 1;

  .jobs-list-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow-x: auto;
    flex: 1;
  }

  @media (max-width: ${breakpoint.mobile}px) {
    .jobs-list-content {
      gap: 10px;
    }
  }
`;
