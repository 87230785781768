import React, { useEffect, useState } from 'react';
import { VacancyApplicationsListContainer } from './job-application.style';
import {
  AddCandidateVacancy,
  Button,
  CardContainer,
  SearchInput,
  SubTitlePage,
  TitlePage,
  HeaderPageContainer,
  ApplicationCardPipeline,
  DraggableArea,
  PipelineContainer,
  PipelineStatus,
} from '../../../components';
import { PlusIcon } from '../../../icons';
import { Application, Job } from '../../../backend/careo-api';
import { useParams } from 'react-router-dom';
import { AxiosInstance } from '../../../utils';
import { toast } from 'react-toastify';
import { applicationStatusList } from '../../../constants';
import { useModal } from '../../../contexts/side-modal.context';
import { useJobApplications } from '../../../api-query/hooks/useJobApplications';

export const VacancyApplicationsListPage = () => {
  const { id } = useParams();
  const { openModal } = useModal();

  const { jobApplicationsList, updateApplicationStatus } = useJobApplications(
    id!,
  );

  const [job, setJob] = useState<Job | null>(null);

  const [pipelines, setPipelines] = useState<PipelineStatus<Application>[]>(
    applicationStatusList.map((el) => ({ ...el, items: [] })),
  );

  const onClickCreate = () => {
    openModal({
      title: 'Add Candidate to job',
      component: (
        <AddCandidateVacancy job={job!} data-testid="add-candidate-job" />
      ),
    });
  };

  const getVacancyDetails = () => {
    AxiosInstance.jobs
      .jobsControllerGetJob(id!)
      .then((response) => {
        setJob(response.data);
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const handleDrop = async (id: string, newListIndex: number) => {
    const newStatus = applicationStatusList[newListIndex].value;

    updateApplicationStatus({
      applicationId: id,
      requestDto: { status: newStatus },
    });
  };

  useEffect(() => {
    const groupedApplications = jobApplicationsList.reduce((acc: any, curr) => {
      const status = curr.status; // Assuming status is a property of the application model
      if (!acc[status]) {
        acc[status] = [];
      }
      acc[status].push(curr);
      return acc;
    }, {});

    setPipelines((prev) => {
      prev = prev.map((el) => ({
        ...el,
        items: groupedApplications[el.value] ?? [],
      }));
      return prev;
    });
  }, [JSON.stringify(jobApplicationsList)]);

  useEffect(() => {
    getVacancyDetails();
  }, []);

  if (!job) {
    return <></>;
  }

  return (
    <>
      <VacancyApplicationsListContainer data-testid="job-applications-list-container">
        <CardContainer
          className="jobs-list-content"
          data-testid="jobs-list-content"
        >
          <HeaderPageContainer data-testid="header-page-container">
            <div className="left-container" data-testid="left-container">
              <TitlePage data-testid="jobs-title">Vacancies</TitlePage>
              <SubTitlePage data-testid="jobs-subtitle">
                List Job - {job.grade}/{job.specialty}
              </SubTitlePage>
            </div>
            <div className="right-container" data-testid="right-container">
              <SearchInput
                placeholder="Search what you need"
                disabled
                data-testid="search-input"
              />
              <Button
                type="primary"
                onClick={() => onClickCreate()}
                data-testid="add-candidates-button"
              >
                <PlusIcon data-testid="plus-icon" /> Add Candidates
              </Button>
            </div>
          </HeaderPageContainer>
          <PipelineContainer data-testid="pipeline-container">
            {pipelines.map((el, listIndex: number) => (
              <div
                className="item-container"
                key={listIndex}
                data-testid={`item-container-${listIndex}`}
              >
                <div
                  className="item-header"
                  data-testid={`item-header-${listIndex}`}
                >
                  <div
                    className="item-title"
                    data-testid={`item-title-${listIndex}`}
                  >
                    <label>{el.label}</label>
                    <div
                      className="item-total"
                      data-testid={`item-total-${listIndex}`}
                    >
                      {el.items.length}
                    </div>
                  </div>
                  <PlusIcon data-testid={`plus-icon-${listIndex}`} />
                </div>
                <div
                  className="cards-list-container"
                  data-testid={`cards-list-container-${listIndex}`}
                >
                  {el.isDraggableTo ? (
                    <DraggableArea
                      pipeline={pipelines}
                      listIndex={listIndex}
                      itemIndex={0}
                      onDragRequest={handleDrop}
                      data-testid={`draggable-area-${listIndex}`}
                    />
                  ) : (
                    <div
                      className="draggable-area false"
                      data-testid={`draggable-area-false-${listIndex}`}
                    >
                      <hr />
                    </div>
                  )}
                  {el.items
                    .sort(
                      (a, b) =>
                        new Date(a.availableFrom).getTime() -
                        new Date(b.availableFrom).getTime(),
                    )
                    .map((item, itemIndex: number) => (
                      <React.Fragment key={item._id}>
                        <ApplicationCardPipeline
                          item={item}
                          listIndex={listIndex}
                          itemIndex={itemIndex}
                          data-testid={`application-card-pipeline-${item._id}`}
                        />
                        {el.isDraggableTo ? (
                          <DraggableArea
                            pipeline={pipelines}
                            listIndex={listIndex}
                            itemIndex={0}
                            onDragRequest={handleDrop}
                            data-testid={`draggable-area-2-${listIndex}`}
                          />
                        ) : (
                          <div
                            className="draggable-area false"
                            data-testid={`draggable-area-false-2-${listIndex}`}
                          >
                            <hr />
                          </div>
                        )}
                      </React.Fragment>
                    ))}
                </div>
              </div>
            ))}
          </PipelineContainer>
        </CardContainer>
      </VacancyApplicationsListContainer>
    </>
  );
};
