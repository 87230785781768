import { CreateJobDto, Job } from '../../backend/careo-api';
import { AxiosInstance } from '../../utils';

// Fetch all jobs
export const getJobs = async () => {
  const response = await AxiosInstance.jobs.jobsControllerFindAll();
  return response.data.items;
};

export const getJobById = async (id: string) => {
  const response = await AxiosInstance.jobs.jobsControllerGetJob(id);
  return response.data;
};

export const createJob = async (jobData: CreateJobDto) => {
  const response = await AxiosInstance.jobs.jobsControllerCreateJob(jobData);
  return response.data;
};

// Update a job
export const updateJob = async (id: string, jobData: CreateJobDto) => {
  const response = await AxiosInstance.jobs.jobsControllerUpdate(id, jobData);
  return response.data;
};

// Delete a job
export const deleteJob = async (job: Job) => {
  await AxiosInstance.jobs.jobsControllerDeleteJob(job._id);
};
