import React, { useEffect, useState } from 'react';
import { TitleCard, RadioGroup, Button } from '../../ui';
import {
  Compliance,
  ComplianceFile,
  ComplianceMetaData,
  HostedFile,
} from '../../../backend/careo-api';
import ComplianceUpload from '../compliance-edit/compliance-upload';
import { toast } from 'react-toastify';

type ProfessionalRegistrationProps = {
  compliance: Compliance;
  documents: HostedFile[];
  onClickSubmit: (
    id: string,
    values: ComplianceMetaData,
    validate?: boolean,
    successMessage?: string,
  ) => void;
  getDocuments: () => void;

  onUpdateValidation: (id: string, value: string) => void;
};

type TFilesKey = 'registrationCertificates' | 'registrationChecksFiles';
type TAllFields = TFilesKey;

export const ProfessionalRegistration = ({
  compliance,
  documents,
  onClickSubmit,
  getDocuments,
  onUpdateValidation,
}: ProfessionalRegistrationProps) => {
  const [isValid, setIsValid] = useState<boolean>();

  const [filesList, setFilesList] = useState<
    { key: TFilesKey; data: ComplianceFile[] }[]
  >([
    { key: 'registrationCertificates', data: [] },
    { key: 'registrationChecksFiles', data: [] },
  ]);

  const onUpdateCompliance = (
    key: TAllFields,
    value: any,
    successMessage?: string,
  ) => {
    onClickSubmit(
      compliance._id,
      {
        [key]: value,
      },
      false,
      successMessage,
    );
  };

  const validateByAI = () => {
    const getActiveFilesByKey = (key: TFilesKey) => {
      return (
        filesList
          .find((el) => el.key === key)
          ?.data.filter((el) => !el.archiveDate) || []
      );
    };

    const activeDbsFiles = getActiveFilesByKey('registrationCertificates');
    if (!activeDbsFiles.length) {
      toast.error('Please upload the Professional Registration Certificate');
      return;
    }

    onClickSubmit(compliance._id, {}, true);
  };

  useEffect(() => {
    if (compliance?._id) {
      const metadata = compliance?.metadata;
      const registrationCertificates = metadata?.registrationCertificates ?? [];
      const registrationChecksFiles = metadata?.registrationChecksFiles ?? [];
      setFilesList([
        {
          key: 'registrationCertificates',
          data: registrationCertificates,
        },
        {
          key: 'registrationChecksFiles',
          data: registrationChecksFiles,
        },
      ]);
      setIsValid(compliance.isApproved);
    }
  }, [compliance]);

  return (
    <>
      {/* Prof Registration Certificate */}
      <TitleCard data-testid="title-card-prof-registration">
        Prof Registration Certificate
      </TitleCard>
      <ComplianceUpload
        isMulti
        fileKey="registrationCertificates"
        label="Upload file"
        description=""
        getDocuments={getDocuments}
        filesList={filesList}
        setFilesList={setFilesList}
        onUpdateCompliance={onUpdateCompliance}
        documents={documents}
      />
      {/* Prof Registration Checks */}
      <TitleCard data-testid="title-card-prof-registration-checks">
        Prof Registration Checks
      </TitleCard>
      <ComplianceUpload
        isMulti
        fileKey="registrationChecksFiles"
        label="Upload file"
        description=""
        getDocuments={getDocuments}
        filesList={filesList}
        setFilesList={setFilesList}
        onUpdateCompliance={onUpdateCompliance}
        documents={documents}
      />

      {/* Approved Section */}
      <>
        <TitleCard data-testid="title-card-approved">Approved</TitleCard>
        <RadioGroup
          options={[
            {
              label: 'Pass',
              value: 'true',
            },
            {
              label: 'Fail',
              value: 'false',
            },
          ]}
          name="checkRegistration"
          value={isValid?.toString()}
          onChange={(value: any) => onUpdateValidation(compliance._id, value)}
          data-testid="check-registration-radio-group"
        />

        <div className="info-card compliance-status buttons-container validated-by-ai">
          <Button
            type="primary"
            onClick={validateByAI}
            data-testid="validate-by-ai-btn"
          >
            Validate By AI
          </Button>
        </div>
      </>
    </>
  );
};
