import {
  AddAvailabilityDto,
  Availability,
  DeleteAvailabilityDto,
} from '../../backend/careo-api';
import { AxiosInstance } from '../../utils';

// Fetch all availabilities
export const getAvailabilities = async (candidateId: string) => {
  const response =
    await AxiosInstance.availability.availabilityControllerFindAllByCandidateId(
      candidateId,
    );
  return response.data;
};

export const createAvailability = async (
  availabilityData: AddAvailabilityDto,
) => {
  const response =
    await AxiosInstance.availability.availabilityControllerAddAvailability(
      availabilityData,
    );
  return response.data;
};

// Delete a availability
export const deleteAvailability = async (availability: Availability) => {
  await AxiosInstance.availability.availabilityControllerDeleteById(
    availability._id,
  );
};

export const deleteMultipleAvailabilities = async (
  availabilities: Availability[],
) => {
  await Promise.all(
    availabilities.map((availability) =>
      AxiosInstance.availability.availabilityControllerDeleteById(
        availability._id,
      ),
    ),
  );
};

export const deleteAvailabilityByRangeDate = async (
  requestDto: DeleteAvailabilityDto,
) => {
  await AxiosInstance.availability.availabilityControllerDeleteAvailabilityByRangeDate(
    requestDto,
  );
};
