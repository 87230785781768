import React, { useEffect, useState } from 'react';
import { TitleCard, FieldText, Button, Textarea, FormContainer } from '../ui';
import { useForm } from 'react-hook-form';
import {
  CampaignTemplate,
  Candidate,
  CreateCampaignTemplateDto,
} from '../../backend/careo-api';
import { campaignTemplateSchema } from '../../utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCampaignTemplates } from '../../api-query/hooks';
import { useModal } from '../../contexts/side-modal.context';

type CampaignTemplateFormProps = {
  selectedCampaignTemplate?: CampaignTemplate;
};

export const CampaignTemplateForm = ({
  selectedCampaignTemplate,
}: CampaignTemplateFormProps) => {
  const { saveCampaignTemplate } = useCampaignTemplates();
  const { closeModal } = useModal();

  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm<CreateCampaignTemplateDto>({
    resolver: yupResolver(campaignTemplateSchema as any),
  });
  const formValues = watch();

  const [result, setResult] = useState('');

  function generateMessageFromTemplate(
    template: string,
    candidate: Candidate,
  ): string {
    return (
      template?.replace(/\[\[(.+?)\]\]/g, (_, key) => {
        switch (key) {
          case 'title':
            return candidate.title;
          case 'firstName':
            return candidate.firstName;
          case 'lastName':
            return candidate.lastName;
          default:
            return 'UNKNOWN KEY';
        }
      }) ?? ''
    );
  }

  const onClickClose = () => {
    closeModal();
  };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const onClickSubmit = () => {
    setIsSubmitting(true);
    const values = getValues();

    const requestDto = {
      ...values,
    };

    saveCampaignTemplate({
      campaignTemplateData: requestDto,
      selectedCampaignTemplate: selectedCampaignTemplate,
    })
      .then(() => {
        onClickClose();
      })
      .catch(() => {
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    setResult(() => {
      return generateMessageFromTemplate(formValues.message, {
        title: 'Mr.',
        firstName: 'John',
        lastName: 'Doe',
      } as Candidate);
    });
  }, [formValues]);

  useEffect(() => {
    if (selectedCampaignTemplate) {
      reset(selectedCampaignTemplate);
    }
  }, []);

  return (
    <FormContainer data-testid="form-container">
      <FieldText
        placeholder="Enter here ..."
        label="Template name"
        required
        register={register('name', { required: true })}
        error={errors.name}
        data-testid="template-name-field"
      />
      <TitleCard data-testid="message-options-title">Message Options</TitleCard>
      <ul data-testid="message-options-list">
        <li>
          <b>[[title]]</b> : title of candidate (Mrs., Mr., Ms., Dr., Prof.)
        </li>
        <li>
          <b>[[firstName]]</b> : first name of candidate
        </li>
        <li>
          <b>[[lastName]]</b> : last name of candidate
        </li>
      </ul>
      <Textarea
        label="Template Message"
        required
        register={register('message', { required: true })}
        placeholder={`Dear [title] [lastName],\nI hope you are doing well!`}
        rows={3}
        error={errors.message}
        data-testid="template-message-textarea"
      />
      <Textarea
        label="Result (Example)"
        rows={3}
        value={result}
        disabled
        data-testid="result-example-textarea"
      />
      <div className="form-actions" data-testid="form-actions">
        <Button
          onClick={onClickClose}
          data-testid="cancel-button"
          type="primary"
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={handleSubmit(onClickSubmit)}
          disabled={isSubmitting}
          data-testid="update-button"
        >
          Submit
        </Button>
      </div>
    </FormContainer>
  );
};
