import { ClientsListPage, ClientDetailsPage } from '../pages/client';
import { TrustsListPage } from '../pages/client/trusts-list/trusts-list.page';
import { IRoute } from '../types/routes.type';
import { ERoute } from '../utils';
import { EClientTabs } from '../pages/client/client-details/client-details.page';

export const ClientsRoutes: IRoute[] = [
  {
    path: `/${ERoute.TRUSTED_CLIENTS}`,
    element: <TrustsListPage />,
  },
  {
    path: `/${ERoute.CLIENTS}`,
    element: <ClientsListPage />,
    isVisibleOnSidebar: true,
  },
  {
    path: `/${ERoute.CLIENTS}/:id`,
    element: <ClientDetailsPage />,
  },
  {
    path: `/${ERoute.CLIENTS}/:id/${EClientTabs.GENERAL}`,
    element: <ClientDetailsPage />,
  },
  {
    path: `/${ERoute.CLIENTS}/:id/${EClientTabs.CONTACT}`,
    element: <ClientDetailsPage />,
  },
  {
    path: `/${ERoute.CLIENTS}/:id/${EClientTabs.DEPARTMENTS}`,
    element: <ClientDetailsPage />,
  },
  {
    path: `/${ERoute.CLIENTS}/:id/${EClientTabs.PAYROLL}`,
    element: <ClientDetailsPage />,
  },
  {
    path: `/${ERoute.CLIENTS}/:id/${EClientTabs.DOCUMENTS}`,
    element: <ClientDetailsPage />,
  },
  {
    path: `/${ERoute.CLIENTS}/:id/${EClientTabs.RATES}`,
    element: <ClientDetailsPage />,
  },
  {
    path: `/${ERoute.CLIENTS}/:id/${EClientTabs.NOTES}`,
    element: <ClientDetailsPage />,
  },
];
