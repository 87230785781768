import { useQuery, useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { deleteUser, getUsers, createUser, updateUser } from '../api/users.api';
import {
  CreateUserDto,
  ESocketRefreshModule,
  User,
} from '../../backend/careo-api';

type SaveUserVariables = {
  userData: CreateUserDto;
  selectedUser?: User;
};

type UseUserProps = {
  usersList: User[];
  isLoading: boolean;

  saveUser: (variables: SaveUserVariables) => Promise<User>;
  deleteUser: (user: User) => Promise<void>;
};

export const useUsers = (): UseUserProps => {
  // Fetch users (React Query caches it)
  const { data: users = [], isLoading } = useQuery({
    queryKey: [ESocketRefreshModule.USERS],
    queryFn: getUsers,
  });

  // Delete user mutation
  const deleteMutation = useMutation({
    mutationFn: (selectedUser: User) => {
      return deleteUser(selectedUser);
    },
    onSuccess: () => {
      toast.success('User removed successfully');
    },
    onError: (error: any) => {
      toast.error(error.message);
    },
  });

  // Create or Update User Mutation
  const saveUserMutation = useMutation({
    mutationFn: ({
      userData,
      selectedUser,
    }: {
      selectedUser?: User;
      userData: CreateUserDto;
    }) => {
      return selectedUser
        ? updateUser(selectedUser._id, userData)
        : createUser(userData);
    },
    onSuccess: () => {
      toast.success('User saved successfully');
    },
    onError: (error: any) => {
      toast.error(error?.message || 'Something went wrong');
    },
  });

  return {
    usersList: users,
    isLoading,
    saveUser: saveUserMutation.mutateAsync,
    deleteUser: deleteMutation.mutateAsync,
  };
};
