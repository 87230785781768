import { useQuery, useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import {
  getCandidates,
  getCandidateById,
  createCandidate,
  updateCandidate,
  deleteCandidate,
} from '../api/candidates.api';
import {
  CreateCandidateDto,
  Candidate,
  ESocketRefreshModule,
} from '../../backend/careo-api';

type SaveCandidateVariables = {
  candidateData: CreateCandidateDto;
  selectedCandidate?: Candidate;
};

type UseCandidatesProps = {
  candidatesList: Candidate[];
  isLoading: boolean;

  saveCandidate: (variables: SaveCandidateVariables) => Promise<void>;
  deleteCandidate: (candidate: Candidate) => Promise<void>;
  findCandidateById: (candidateId: string) => Promise<Candidate>;
};

export const useCandidates = (): UseCandidatesProps => {
  // Fetch candidates (React Query caches it)
  const { data: candidates = [], isLoading } = useQuery({
    queryKey: [ESocketRefreshModule.CANDIDATES],
    queryFn: getCandidates,
  });

  // Delete candidate mutation
  const deleteMutation = useMutation({
    mutationFn: (selectedCandidate: Candidate) => {
      return deleteCandidate(selectedCandidate);
    },
    onSuccess: () => {
      toast.success('Candidate removed successfully');
      toast.info("All Candidate's placements has been removed successfully");
    },
    onError: (error: any) => {
      toast.error(error.message);
    },
  });

  // Create or Update Candidate Mutation
  const saveCandidateMutation = useMutation({
    mutationFn: ({
      candidateData,
      selectedCandidate,
    }: {
      selectedCandidate?: Candidate;
      candidateData: CreateCandidateDto;
    }) => {
      return (
        selectedCandidate
          ? updateCandidate(selectedCandidate._id, candidateData)
          : createCandidate(candidateData)
      ).then(() => {});
    },
    onSuccess: () => {
      toast.success('Candidate saved successfully');
    },
    onError: (error: any) => {
      toast.error(error?.message || 'Something went wrong');
    },
  });

  return {
    candidatesList: candidates,
    isLoading,
    saveCandidate: saveCandidateMutation.mutateAsync,
    deleteCandidate: deleteMutation.mutateAsync,
    findCandidateById: getCandidateById,
  };
};
