import { useQuery, useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import {
  deleteJob,
  getJobs,
  createJob,
  updateJob,
  getJobById,
} from '../api/jobs.api';
import {
  CreateJobDto,
  ESocketRefreshModule,
  Job,
} from '../../backend/careo-api';

type SaveJobVariables = {
  jobData: CreateJobDto;
  selectedJob?: Job;
};

type UseJobProps = {
  jobsList: Job[];
  isLoading: boolean;

  saveJob: (variables: SaveJobVariables) => Promise<void>;
  deleteJob: (job: Job) => Promise<void>;
  findJobById: (jobId: string) => Promise<Job>;
};

export const useJobs = (): UseJobProps => {
  // Fetch jobs (React Query caches it)
  const { data: jobs = [], isLoading } = useQuery({
    queryKey: [ESocketRefreshModule.JOBS],
    queryFn: getJobs,
  });

  // Delete job mutation
  const deleteMutation = useMutation({
    mutationFn: (selectedJob: Job) => {
      return deleteJob(selectedJob);
    },
    onSuccess: () => {
      toast.success('Vacancy removed successfully');
    },
    onError: (error: any) => {
      toast.error(error.message);
    },
  });

  // Create or Update Job Mutation
  const saveJobMutation = useMutation({
    mutationFn: ({
      jobData,
      selectedJob,
    }: {
      selectedJob?: Job;
      jobData: CreateJobDto;
    }) => {
      return (
        selectedJob ? updateJob(selectedJob._id, jobData) : createJob(jobData)
      ).then(() => {});
    },
    onSuccess: () => {
      toast.success('Vacancy saved successfully');
    },
    onError: (error: any) => {
      toast.error(error?.message || 'Something went wrong');
    },
  });

  return {
    jobsList: jobs,
    isLoading,
    saveJob: saveJobMutation.mutateAsync,
    deleteJob: deleteMutation.mutateAsync,
    findJobById: getJobById,
  };
};
