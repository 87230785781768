import React, { useEffect, useState } from 'react';
import { TitleCard, RadioGroup, Switch, Select, FieldText } from '../../ui';
import {
  Compliance,
  ComplianceFile,
  ComplianceMetaData,
  HostedFile,
} from '../../../backend/careo-api';
import ComplianceUpload from '../compliance-edit/compliance-upload';

type IdBadgeProps = {
  compliance: Compliance;
  documents: HostedFile[];
  onClickSubmit: (
    id: string,
    values: ComplianceMetaData,
    validate?: boolean,
    successMessage?: string,
  ) => void;
  getDocuments: () => void;
  onUpdateValidation: (id: string, value: string) => void;
};

type TFilesKey = 'profilePictureFile' | 'badgeFile';
type TAllFields =
  | TFilesKey
  | 'isBadgeSent'
  | 'isPerformCheckActivated'
  | 'idTimePeriod'
  | 'idCustomDate';

export const IdBadge = ({
  compliance,
  documents,
  onClickSubmit,
  getDocuments,
  onUpdateValidation,
}: IdBadgeProps) => {
  const [isApproved, setIsApproved] = useState<boolean>();
  const [isBadgeSent, setOnIdUpdateService] = useState<boolean>();
  const [idTimePeriod, setIdTimePeriod] = useState<string>();
  const [idCustomDate, setIdCustomDate] = useState<string>();

  const [filesList, setFilesList] = useState<
    { key: TFilesKey; data: ComplianceFile[] }[]
  >([
    { key: 'profilePictureFile', data: [] },
    { key: 'badgeFile', data: [] },
  ]);

  const onUpdateCompliance = (
    key: TAllFields,
    value: any,
    successMessage?: string,
  ) => {
    onClickSubmit(
      compliance._id,
      {
        [key]: value,
      },
      false,
      successMessage,
    );
  };

  useEffect(() => {
    if (compliance?._id) {
      setIdTimePeriod(compliance.metadata?.idTimePeriod);
      setIdCustomDate(compliance.metadata?.idCustomDate);
      setOnIdUpdateService(compliance.metadata?.isBadgeSent ?? false);
      setFilesList([
        {
          key: 'profilePictureFile',
          data: compliance?.metadata?.profilePictureFile ?? [],
        },
        {
          key: 'badgeFile',
          data: compliance?.metadata?.badgeFile ?? [],
        },
      ]);
      setIsApproved(compliance.isApproved);
    }
  }, [compliance]);

  return (
    <>
      <>
        <TitleCard data-testid="title-card-application-form-certificate">
          Upload Photo
        </TitleCard>
        <ComplianceUpload
          fileKey="profilePictureFile"
          label="Upload Profile Picture"
          description=""
          getDocuments={getDocuments}
          filesList={filesList}
          setFilesList={setFilesList}
          onUpdateCompliance={onUpdateCompliance}
          documents={documents}
        />
      </>
      <>
        <TitleCard data-testid="title-card-application-form-certificate">
          Copy of ID badge
        </TitleCard>
        <div className="question-toggle-container">
          <div className="label">
            Has the ID badge been created and sent to candidate
          </div>
          <div>{isBadgeSent ? 'Yes' : 'No'}</div>
          <div className="action-toggle">
            <Switch
              value={isBadgeSent ?? false}
              onClick={() => {
                onUpdateCompliance('isBadgeSent', !isBadgeSent);
                setOnIdUpdateService((prev) => !prev);
              }}
            />
          </div>
        </div>
        <ComplianceUpload
          fileKey="badgeFile"
          label="Upload ID badge"
          description=""
          getDocuments={getDocuments}
          filesList={filesList}
          setFilesList={setFilesList}
          onUpdateCompliance={onUpdateCompliance}
          documents={documents}
        />

        <div className="row">
          <div className="col-md-6">
            <Select
              placeholder="Enter here ..."
              label="Time Period"
              required
              options={[{ label: '1 Year', value: '1 Year' }]}
              value={idTimePeriod}
              onChange={(value: any) => {
                setIdTimePeriod(value);
                onUpdateCompliance('idTimePeriod', value);
              }}
              data-testid="select-time"
            />
          </div>
          <div className="col-md-6">
            <FieldText
              placeholder="Enter here ..."
              label="Expiry Date"
              required
              type="date"
              value={idCustomDate}
              onChange={(e) => {
                setIdCustomDate(e.target.value);
                onUpdateCompliance('idCustomDate', e.target.value);
              }}
              onBlur={(e) => {
                onUpdateCompliance('idCustomDate', e.target.value);
              }}
            />
          </div>
        </div>
      </>

      {/* Approved Section */}
      <>
        <TitleCard data-testid="title-card-approved">Approved</TitleCard>
        <RadioGroup
          options={[
            {
              label: 'Pass',
              value: 'true',
            },
            {
              label: 'Fail',
              value: 'false',
            },
          ]}
          name="approved"
          value={isApproved?.toString()}
          onChange={(value: any) => onUpdateValidation(compliance._id, value)}
          data-testid="approved-radio-group"
        />
        {/* <div
          className="info-card compliance-status buttons-container validated-by-ai"
          data-testid="validate-by-ai-button-container"
        >
          <Button
            type="primary"
            onClick={() => onClickSubmit(compliance._id, {}, true)}
            data-testid="validate-by-ai-button"
          >
            Validate By AI
          </Button>
        </div> */}
      </>
    </>
  );
};
