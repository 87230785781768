import React, { useMemo, useState } from 'react';
import { Select, Button, FormContainer } from '../ui';
import { useForm } from 'react-hook-form';
import { EApplicationStatus } from '../../backend/careo-api';
import {
  AxiosInstanceErrorResponse,
  handleFormsServerErrors,
  PlacementCreateByApplicationIdSchema,
} from '../../utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { rejectedPlacementStatuses } from '../../pages/placement/placements-list/placements-list.page';
import { useModal } from '../../contexts/side-modal.context';
import { usePlacements } from '../../api-query/hooks/usePlacements';
import { useJobApplications } from '../../api-query/hooks/useJobApplications';

export const NewPlacementForm = () => {
  const { jobApplicationsList } = useJobApplications();

  const {
    getValues,
    handleSubmit,
    formState: { errors },
    register,
    setError,
    setFocus,
  } = useForm<{ applicationId: string }>({
    resolver: yupResolver(PlacementCreateByApplicationIdSchema),
  });

  const { closeModal } = useModal();
  const { addPlacementByApplicationId } = usePlacements();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onClickSubmit = () => {
    setIsSubmitting(true);
    const values = getValues();

    addPlacementByApplicationId(values.applicationId)
      .then(() => {
        closeModal();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        handleFormsServerErrors(error, setError, setFocus);
        setIsSubmitting(false);
      });
  };

  const applicationOptions = useMemo(
    () =>
      jobApplicationsList
        .filter(
          (application) =>
            application.status !== EApplicationStatus.Placement &&
            application.status !== EApplicationStatus.Rejected &&
            !rejectedPlacementStatuses.includes(application.approvalStatus!),
        )
        .map((application) => ({
          label: `${application.job?.grade ?? 'N/A'} / ${application.job?.specialty ?? 'N/A'} : ${application.candidate?.firstName ?? ''} ${application.candidate?.lastName ?? ''} (${application.status})`,
          value: application._id,
        })),
    [jobApplicationsList],
  );

  return (
    <FormContainer data-testid="form-container">
      <Select
        placeholder="Select Application"
        label="Application"
        options={applicationOptions}
        register={register('applicationId')}
        required
        error={errors.applicationId}
        data-testid="application-select"
      />
      <div className="form-actions" data-testid="form-actions">
        <Button
          onClick={() => closeModal()}
          data-testid="cancel-button"
          type="primary"
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={handleSubmit(onClickSubmit)}
          disabled={isSubmitting}
          data-testid="create-button"
        >
          Create
        </Button>
      </div>
    </FormContainer>
  );
};
