import React, { useMemo, useState } from 'react';
import {
  Button,
  CardContainer,
  HeaderPageContainer,
  Pagination,
  SearchInput,
  SubTitlePage,
  TabFilter,
  Table,
  TitlePage,
  UploadButton,
  TrustForm,
} from '../../../components';
import { DeleteIcon, EditIcon, PlusIcon, SortIcon } from '../../../icons';
import {
  ERoute,
  TFilterTrust,
  filterTrusts,
  formatDate,
  getItemsOfPage,
  getNumberOfPages,
  onSelectSort,
  sortTrusts,
} from '../../../utils';
import { Trust, EUserRole } from '../../../backend/careo-api';
import { useAuth } from '../../../contexts/auth.context';
import { useModal } from '../../../contexts/side-modal.context';
import { useConfirm } from '../../../contexts/confirm-modal.context';
import { useTrusts } from '../../../api-query/hooks/useTrusts';

const itemsPerPage = 8;

export const TrustsListPage = () => {
  const { user } = useAuth();
  const { openModal } = useModal();
  const { openConfirm, closeConfirm } = useConfirm();

  const { trustsList, isLoading, uploadTrustsCsv, deleteTrust } = useTrusts();

  const [currentPage, setCurrentPage] = useState(1);

  const [filter, setFilter] = useState<TFilterTrust>({
    search: '',
  });
  const [sort, setSort] = useState<{ key: string; value: '+' | '-' | '' }>({
    key: '',
    value: '',
  });

  const onDeleteConfirm = (item: Trust) => {
    openConfirm({
      title: 'Delete Trust',
      component: (
        <>
          Do you want to delete <b>{item?.name}</b> ?
        </>
      ),
      onConfirm: () =>
        deleteTrust(item)
          .then(() => closeConfirm())
          .catch(() => {}),
    });
  };

  const onClickCreate = () => {
    openModal({
      title: 'New Trust',
      component: <TrustForm data-testid="new-trust-form" />,
    });
  };

  const onClickEdit = (trust: Trust) => {
    openModal({
      title: 'Update Trust',
      component: (
        <TrustForm selectedTrust={trust} data-testid="update-trust-form" />
      ),
    });
  };

  // Apply filtering and sorting
  const filteredAndSortedData = useMemo(() => {
    const filteredData = filterTrusts(trustsList, filter);
    return sortTrusts(filteredData, sort);
  }, [trustsList, filter, sort]);

  // Paginate the data
  const paginatedData = useMemo(() => {
    return getItemsOfPage(filteredAndSortedData, currentPage, itemsPerPage);
  }, [filteredAndSortedData, currentPage]);

  const totalPages = useMemo(() => {
    return getNumberOfPages(filteredAndSortedData.length, itemsPerPage);
  }, [filteredAndSortedData]);

  return (
    <>
      <TabFilter
        filters={[
          {
            title: 'Clients',
            url: ERoute.CLIENTS,
          },
          {
            title: 'Trust',
            url: ERoute.TRUSTED_CLIENTS,
          },
        ]}
        data-testid="tab-filter"
      />
      <CardContainer data-testid="card-container">
        <HeaderPageContainer data-testid="header-page-container">
          <div className="left-container" data-testid="left-container">
            <TitlePage data-testid="trusts-title">Trusts</TitlePage>
            <SubTitlePage data-testid="trusts-subtitle">
              Manage your Trust
            </SubTitlePage>
          </div>
          <div className="right-container" data-testid="right-container">
            <UploadButton
              accept=".csv"
              onUpload={uploadTrustsCsv}
              data-testid="upload-button"
            >
              Upload
            </UploadButton>
            <Button
              type="primary"
              onClick={() => onClickCreate()}
              data-testid="add-new-button"
            >
              <PlusIcon /> Add new
            </Button>
          </div>
        </HeaderPageContainer>
        <div className="filter-container" data-testid="filter-container">
          <SearchInput
            placeholder="Search trust"
            onChange={(e) =>
              setFilter((prev) => ({ ...prev, search: e.target.value ?? '' }))
            }
            data-testid="search-input"
          />
        </div>
        <div
          className="data-table-container"
          data-testid="data-table-container"
        >
          <Table data-testid="trusts-table">
            <thead>
              <tr>
                <th className="checkbox-table" data-testid="checkbox-header">
                  <input type="checkbox" />
                </th>
                <th
                  onClick={() => onSelectSort('name', setSort)}
                  data-testid="sort-name"
                >
                  <div>
                    <label>Name</label>
                    <SortIcon
                      value={sort.key === 'trustName' ? sort.value : ''}
                    />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('description', setSort)}
                  data-testid="sort-description"
                >
                  <div>
                    <label>Description</label>
                    <SortIcon
                      value={sort.key === 'description' ? sort.value : ''}
                    />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('createdAt', setSort)}
                  data-testid="sort-created-at"
                >
                  <div>
                    <label>Created At</label>
                    <SortIcon
                      value={sort.key === 'createdAt' ? sort.value : ''}
                    />
                  </div>
                </th>
                <th data-testid="action-header">
                  <div></div>
                </th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td
                    colSpan={100}
                    className="text-center"
                    data-testid="loading"
                  >
                    Loading ...
                  </td>
                </tr>
              ) : (
                <>
                  {paginatedData.length > 0 ? (
                    <>
                      {paginatedData.map((trust) => {
                        const isTrustBelongsToUser =
                          trust?.user?._id === user?._id ||
                          user?.role === EUserRole.Admin;

                        return (
                          <tr
                            key={trust._id}
                            data-testid={`trust-row-${trust._id}`}
                          >
                            <td
                              className="checkbox-table"
                              data-testid={`checkbox-${trust._id}`}
                            >
                              <input type="checkbox" />
                            </td>
                            <td data-testid={`name-${trust._id}`}>
                              {trust.name}
                            </td>
                            <td data-testid={`description-${trust._id}`}>
                              {trust.description}
                            </td>
                            <td data-testid={`created-at-${trust._id}`}>
                              {formatDate(trust.createdAt ?? '')}
                            </td>
                            <td data-testid={`action-icons-${trust._id}`}>
                              <div className="action-item">
                                <div
                                  className="edit-icon"
                                  onClick={() => onClickEdit(trust)}
                                  data-testid={`edit-trust-${trust._id}`}
                                >
                                  <EditIcon />
                                </div>
                                <div
                                  className="delete-icon"
                                  onClick={() => onDeleteConfirm(trust)}
                                  data-testid={`delete-trust-${trust._id}`}
                                >
                                  <DeleteIcon />
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  ) : (
                    <tr>
                      <td
                        colSpan={100}
                        className="text-center"
                        data-testid="no-items-found"
                      >
                        No item found
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </Table>
        </div>
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
          itemsPerPage={itemsPerPage}
          totalEntries={trustsList.length}
          data-testid="pagination"
        />
      </CardContainer>
    </>
  );
};
