import { UpdateComplianceDto } from '../../backend/careo-api';
import { AxiosInstance } from '../../utils';

// Fetch all compliances
export const getCandidateCompliances = async (candidateId: string) => {
  const response = await AxiosInstance.compliances.compliancesControllerFindAll(
    { candidateId },
  );
  return response.data.items;
};

// Update a compliance
export const updateCompliance = async (
  id: string,
  validate: boolean,
  complianceData: UpdateComplianceDto,
) => {
  const response = await AxiosInstance.compliances.compliancesControllerUpdate(
    id,
    { validate },
    complianceData,
  );
  return response.data;
};
