import React, { useEffect, useState } from 'react';
import { TitleCard, RadioGroup, Button } from '../../ui';
import {
  Compliance,
  ComplianceFile,
  ComplianceMetaData,
  HostedFile,
} from '../../../backend/careo-api';
import ComplianceUpload from '../compliance-edit/compliance-upload';
import { toast } from 'react-toastify';

type EmploymentHistoryProps = {
  compliance: Compliance;
  documents: HostedFile[];
  onClickSubmit: (
    id: string,
    values: ComplianceMetaData,
    validate?: boolean,
    successMessage?: string,
  ) => void;
  getDocuments: () => void;
  onUpdateValidation: (id: string, value: string) => void;
};

type TFilesKey = 'cvFile' | 'references';
type TAllFields = TFilesKey | 'isCvValid' | 'isReferencesValid';

export const EmploymentHistoryReferences = ({
  compliance,
  documents,
  onClickSubmit,
  getDocuments,
  onUpdateValidation,
}: EmploymentHistoryProps) => {
  const [isApproved, setIsApproved] = useState<boolean>();
  const [isCvValid, setIsCvValid] = useState<boolean>();
  const [isReferencesValid, setIsReferenceValid] = useState<boolean>();

  const [filesList, setFilesList] = useState<
    { key: TFilesKey; data: ComplianceFile[] }[]
  >([
    { key: 'cvFile', data: [] },
    { key: 'references', data: [] },
  ]);

  const onUpdateCompliance = (
    key: TAllFields,
    value: any,
    successMessage?: string,
  ) => {
    onClickSubmit(compliance._id, { [key]: value }, false, successMessage);
  };

  const validateByAI = () => {
    const getActiveFilesByKey = (key: TFilesKey) => {
      return (
        filesList
          .find((el) => el.key === key)
          ?.data.filter((el) => !el.archiveDate) || []
      );
    };

    const activeDbsFiles = getActiveFilesByKey('cvFile');
    if (!activeDbsFiles.length) {
      toast.error('Please upload the CV');
      return;
    }

    onClickSubmit(compliance._id, {}, true);
  };

  useEffect(() => {
    if (compliance) {
      const cvFile = compliance?.metadata?.cvFile ?? [];
      const references = compliance?.metadata?.references ?? [];
      setFilesList([
        { key: 'cvFile', data: cvFile },
        { key: 'references', data: references },
      ]);
      setIsCvValid(compliance.metadata?.isCvValid);
      setIsReferenceValid(compliance.metadata?.isReferencesValid);
      setIsApproved(compliance.isApproved);
    }
  }, [compliance]);

  return (
    <>
      {/* Type of Employment Section */}
      <TitleCard data-testid="title-card-type-of-employment">CV</TitleCard>
      <ComplianceUpload
        fileKey="cvFile"
        label="Upload CV"
        description="Does it contain current & most recent job. Is the CV going back in chronological order in context to work history and qualifications. Any time period gaps to be qualified and added to document."
        getDocuments={getDocuments}
        filesList={filesList}
        setFilesList={setFilesList}
        onUpdateCompliance={onUpdateCompliance}
        documents={documents}
      />
      <div className="description">
        Does the CV confirm full employment and training history inclusive of
        gaps 
      </div>
      <RadioGroup
        options={[
          {
            label: 'Yes',
            value: 'true',
          },
          {
            label: 'No',
            value: 'false',
          },
        ]}
        name="cvConfirmation"
        value={isCvValid?.toString()}
        onChange={(value) => {
          onUpdateCompliance('isCvValid', value === 'true' ? true : false);
        }}
        data-testid="cv-confirmation-radio-group"
      />

      {/* References Section */}
      <TitleCard data-testid="title-card-references">References</TitleCard>
      <ComplianceUpload
        fileKey="references"
        isMulti
        label="Upload References"
        description="Referee details received? Do they cover the previous 3 years of employment or training."
        getDocuments={getDocuments}
        filesList={filesList}
        setFilesList={setFilesList}
        onUpdateCompliance={onUpdateCompliance}
        documents={documents}
      />
      {/* References Confirmation Section */}
      <div className="description">
        Do the References confirm full employment and training history
      </div>
      <RadioGroup
        options={[
          {
            label: 'Yes',
            value: 'true',
          },
          {
            label: 'No',
            value: 'false',
          },
        ]}
        name="referencesConfirmation"
        value={isReferencesValid?.toString()}
        onChange={(value) => {
          onUpdateCompliance(
            'isReferencesValid',
            value === 'true' ? true : false,
          );
        }}
        data-testid="references-confirmation-radio-group"
      />

      {/* Approved Section */}
      <>
        <TitleCard data-testid="title-card-approved">Approved</TitleCard>
        <RadioGroup
          options={[
            {
              label: 'Pass',
              value: 'true',
            },
            {
              label: 'Fail',
              value: 'false',
            },
          ]}
          name="approved"
          value={isApproved?.toString()}
          onChange={(value: any) => onUpdateValidation(compliance._id, value)}
          data-testid="approved-radio-group"
        />
        <div
          className="info-card compliance-status buttons-container validated-by-ai"
          data-testid="validate-by-ai-button-container"
        >
          <Button
            type="primary"
            onClick={validateByAI}
            data-testid="validate-by-ai-button"
          >
            Validate By AI
          </Button>
        </div>
      </>
    </>
  );
};
