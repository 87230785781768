import React, { useEffect, useState } from 'react';
import {
  Select,
  TitleCard,
  FieldText,
  Button,
  FormContainer,
  Switch,
} from '../ui';
import {
  Days,
  jobLevelWithGrades,
  shiftList,
  specialtiesList,
} from '../../constants';
import { useForm } from 'react-hook-form';
import { CreateJobDto, EJobStatus, Job } from '../../backend/careo-api';
import {
  AxiosInstanceErrorResponse,
  handleFormsServerErrors,
  jobSchema,
} from '../../utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { getChargeRateType } from '../../utils/clients.utils';
import { InfoIcon } from '../../icons';
import { CoreHours } from '../client/charges-rates.style';
import { useUsers } from '../../api-query/hooks/useUsers';
import { useClients } from '../../api-query/hooks/useClients';
import { useJobs } from '../../api-query/hooks/useJobs';
import { useModal } from '../../contexts/side-modal.context';

export const VacancyForm = ({ selectedJob }: { selectedJob?: Job }) => {
  const { usersList } = useUsers();
  const { saveJob } = useJobs();
  const { clientsList } = useClients();
  const { closeModal } = useModal();

  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
    watch,
    setError,
    setFocus,
    setValue,
    reset,
    control,
  } = useForm<CreateJobDto>({
    resolver: yupResolver(jobSchema as any),
    defaultValues: {
      coreHours: {
        monday: { from: '08:00', to: '17:30', isWorkingDay: true },
        tuesday: { from: '08:00', to: '17:30', isWorkingDay: true },
        wednesday: { from: '08:00', to: '17:30', isWorkingDay: true },
        thursday: { from: '08:00', to: '17:30', isWorkingDay: true },
        friday: { from: '08:00', to: '17:30', isWorkingDay: true },
        saturday: { from: '08:00', to: '17:30', isWorkingDay: false },
        sunday: { from: '08:00', to: '17:30', isWorkingDay: false },
      },
    },
  });
  const formValues = watch();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const onClickSubmit = async () => {
    setIsSubmitting(true);
    const values = getValues();

    const charge = Number(values.charge);
    const rate = Number(values.corePrice);

    const selectedClient = clientsList.find(
      (client) => client._id === values.clientId,
    );

    const clientChargeRate = selectedClient?.chargesRates.find((el) => {
      return (
        (el.type === getChargeRateType(values.grade, values.level) &&
          el.specialty === values.specialty) ||
        (el.type === getChargeRateType(values.grade, values.level) &&
          el.specialty === undefined)
      );
    });

    const valuesDto = {
      ...values,
      hoursPerWeek: Number(values.hoursPerWeek ?? 40),
      charge:
        charge > 0
          ? charge
          : (clientChargeRate?.pricing.find((p) => p.grade === values.grade)
              ?.charge ?? 0),
      corePrice:
        rate > 0
          ? rate
          : (clientChargeRate?.pricing.find((p) => p.grade === values.grade)
              ?.corePrice ?? 0),
    };

    await saveJob({ jobData: valuesDto, selectedJob: selectedJob })
      .then(() => {
        closeModal();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        handleFormsServerErrors(error, setError, setFocus);
      });

    setIsSubmitting(false);
  };

  useEffect(() => {
    if (selectedJob) {
      reset({
        ...selectedJob,
        userId: selectedJob.user?._id,
        clientId: selectedJob.client?._id,
        vacantFrom: selectedJob.vacantFrom?.split?.('T')?.[0] ?? '',
        vacantTo: selectedJob.vacantTo?.split?.('T')?.[0] ?? '',
      });
    }
  }, []);

  return (
    <FormContainer data-testid="form-container">
      <div className="row">
        <Select
          placeholder="Select recruiter"
          label="Recruiter"
          required
          options={usersList.map((el) => ({
            label: el.firstName + ' ' + el.lastName,
            value: el._id,
          }))}
          register={register('userId')}
          error={errors.userId}
          data-testid="select-recruiter"
          control={control}
        />
        <TitleCard data-testid="title-job-details">Vacancy Details</TitleCard>
        <Select
          placeholder="Select client"
          label="Client"
          required
          options={clientsList.map((el) => ({
            label: el.clientName,
            value: el._id,
          }))}
          register={register('clientId')}
          error={errors.clientId}
          withoutChip
          data-testid="select-client"
          control={control}
        />
        <div className="col-md-6" data-testid="col-start-date">
          <FieldText
            placeholder="Enter here ..."
            label="Start Date"
            required
            type="date"
            register={register('vacantFrom')}
            error={errors.vacantFrom}
            data-testid="field-start-date"
          />
        </div>
        <div className="col-md-6" data-testid="col-end-date">
          <FieldText
            placeholder="Enter here ..."
            label="End Date"
            required
            type="date"
            register={register('vacantTo')}
            error={errors.vacantTo}
            data-testid="field-end-date"
          />
        </div>
        <CoreHours
          className="row core-hours-container"
          data-testid="core-hours-container"
        >
          <div
            className="row core-hours-header"
            data-testid="core-hours-header"
            style={{}}
          >
            <div className="col-md-4">Working Day</div>
            <div className="col-md-4">Core From</div>
            <div className="col-md-4">Core Till</div>
          </div>

          {Days.map((day) => {
            return (
              <div className="row days" data-testid="day-core-hours">
                <div className="col-md-4 box">
                  <Switch
                    value={formValues.coreHours?.[day]?.isWorkingDay}
                    onClick={() =>
                      setValue(
                        `coreHours.${day}.isWorkingDay`,
                        !formValues.coreHours?.[day]?.isWorkingDay,
                      )
                    }
                  />
                  {day}
                </div>
                <div className="col-md-4 box">
                  {formValues.coreHours?.[day]?.isWorkingDay ? (
                    <FieldText
                      placeholder="Enter here ..."
                      type="time"
                      required
                      register={register(`coreHours.${day}.from`, {
                        required: true,
                      })}
                      error={errors.coreHours?.[day]?.from}
                      data-testid="charge-rate-field"
                    />
                  ) : (
                    <div className="non-working-day">
                      <InfoIcon />
                      Non-working day
                    </div>
                  )}
                </div>
                <div className="col-md-4 box">
                  {formValues.coreHours?.[day]?.isWorkingDay ? (
                    <FieldText
                      placeholder="Enter here ..."
                      type="time"
                      required
                      register={register(`coreHours.${day}.to`, {
                        required: true,
                      })}
                      error={errors.coreHours?.[day]?.to}
                      data-testid="charge-rate-field"
                    />
                  ) : (
                    <div className="non-working-day">
                      <InfoIcon />
                      Non-working day
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </CoreHours>
        <Select
          options={shiftList}
          placeholder="Select Shift"
          label="Shift"
          required
          register={register('shift')}
          error={errors.shift}
          data-testid="select-shift"
          control={control}
        />
        <Select
          options={jobLevelWithGrades.map((el) => ({
            label: el.level,
            value: el.level,
          }))}
          placeholder="Select Job Title"
          label="Job Title"
          required
          register={register('level')}
          error={errors.level}
          data-testid="select-job-title"
          control={control}
        />
        <div className="col-md-6">
          <Select
            options={
              jobLevelWithGrades
                .find((el) => el.level === formValues.level)
                ?.grades?.map((el) => ({ label: el, value: el })) ?? []
            }
            placeholder="Select Grade"
            label="Grade"
            required
            register={register('grade')}
            error={errors.grade}
            disabled={!formValues.level}
            data-testid="select-grade"
            control={control}
          />
        </div>
        <div className="col-md-6">
          <Select
            options={specialtiesList.map((el) => ({ label: el, value: el }))}
            placeholder="Select Specialty"
            label="Specialty"
            required
            register={register('specialty', { required: true })}
            error={errors.specialty}
            data-testid="select-specialty"
            control={control}
          />
        </div>
        <FieldText
          placeholder="Enter here ..."
          label="Job Description"
          required
          register={register('jobDescription', { required: true })}
          error={errors.jobDescription}
          data-testid="field-job-description"
        />
        <Select
          options={[
            { label: 'Hired', value: EJobStatus.Hired },
            { label: 'Pending', value: EJobStatus.Pending },
            { label: 'Active', value: EJobStatus.Active },
            { label: 'Closed', value: EJobStatus.Closed },
          ]}
          placeholder="Select Vacancy Status"
          label="Status"
          required
          register={register('status', { required: true })}
          error={errors.status}
          data-testid="select-status"
          control={control}
        />
        <TitleCard data-testid="placement-charges-title">Charges</TitleCard>
        <div className="col-md-6" data-testid="col-charge">
          <FieldText
            placeholder="Enter here ..."
            label="Charge"
            required
            register={register('charge', { required: true })}
            error={errors.charge}
            data-testid="field-charge"
            type="currency"
          />
        </div>
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="VAT"
            required
            register={register('vat', { required: true })}
            type="currency"
            min={0}
            error={errors.vat}
            data-testid="vat"
          />
        </div>
        <TitleCard data-testid="placement-charges-title">Rates</TitleCard>
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="Core Rate (per hour)"
            required
            register={register('corePrice', { required: true })}
            type="currency"
            min={0}
            error={errors.corePrice}
            data-testid="on-call-rate"
          />
        </div>
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="Unsocial Rate (per hour)"
            required
            register={register('unSocialPrice', { required: true })}
            type="currency"
            min={0}
            error={errors.unSocialPrice}
            data-testid="unsocial-rate"
          />
        </div>
        <div className="col-md-6">
          {' '}
          <FieldText
            placeholder="Enter here ..."
            label="On Call Active Rate (per hour)"
            required
            register={register('onCallActivePrice', { required: true })}
            type="currency"
            min={0}
            error={errors.onCallActivePrice}
            data-testid="on-call-active-rate"
          />
        </div>
        <div className="col-md-6">
          {' '}
          <FieldText
            placeholder="Enter here ..."
            label="On Call Passive Rate (per hour)"
            required
            register={register('onCallPassivePrice', { required: true })}
            type="currency"
            min={0}
            error={errors.onCallPassivePrice}
            data-testid="on-call-passive-rate"
          />
        </div>
      </div>
      <div className="form-actions" data-testid="form-actions">
        <Button
          onClick={() => closeModal()}
          data-testid="button-cancel"
          type="primary"
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={handleSubmit(onClickSubmit)}
          disabled={isSubmitting}
          data-testid="button-create"
        >
          Submit
        </Button>
      </div>
    </FormContainer>
  );
};
