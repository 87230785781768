import ReactDOM from 'react-dom/client';
import App from './pages/App';
import reportWebVitals from './reportWebVitals';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, Bounce } from 'react-toastify';
import { ThemeProvider } from 'styled-components';
import { theme } from './constants';
import { AuthProvider } from './contexts/auth.context';
import { PORTALS } from './backend/careo-api';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from './api-query/queryClient';

if (!process.env.REACT_APP_NAME) {
  throw new Error(
    'REACT_APP_NAME environment variable is not set. Please add this variable with a valid value.',
  );
}

if (
  process.env.REACT_APP_NAME !== PORTALS.Crm &&
  process.env.REACT_APP_NAME !== PORTALS.Compliance
) {
  throw new Error(
    'REACT_APP_NAME environment variable has an invalid value. Please set it to either "crm" or "compliance".',
  );
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <>
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </QueryClientProvider>
    </AuthProvider>
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable={false}
      pauseOnHover
      theme="colored"
      transition={Bounce}
    />
  </>,
);

reportWebVitals();
