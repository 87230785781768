import {
  Compliance,
  ComplianceFile,
  EComplianceName,
  ECriminalRecordCheckType,
  LifeSupportType,
} from '../backend/careo-api';

function filterFiles(files?: ComplianceFile[]): ComplianceFile[] | undefined {
  return files?.filter((el) => !el.archiveDate);
}

function getRegistrationDocuments(compliance: Compliance) {
  return [
    {
      label: 'Application form',
      value: filterFiles(compliance.metadata?.applicationFormFiles),
    },
    {
      label: 'Company Handbook',
      value: filterFiles(compliance.metadata?.handbookFiles),
    },
    {
      label: 'Interview Form',
      value: filterFiles(compliance.metadata?.interviewFormFiles),
    },
    {
      label: 'Appraisals',
      value: filterFiles(compliance.metadata?.appraisalsFiles),
    },
    {
      label: 'Emergency Contact',
      value:
        !!compliance.metadata?.emergencyName &&
        !!compliance.metadata?.emergencyPhoneNumber &&
        !!compliance.metadata?.emergencyRelationship,
    },
  ];
}

function getEmploymentHistoryReferences(compliance: Compliance) {
  return [
    { label: 'CV', value: filterFiles(compliance.metadata?.cvFile) },
    {
      label: 'References',
      value: filterFiles(compliance.metadata?.references),
    },
  ];
}

function getRightToWork(compliance: Compliance) {
  return [
    {
      label: 'Certificate',
      value: filterFiles(compliance.metadata?.certificateFiles),
    },
    { label: 'Visa', value: filterFiles(compliance.metadata?.visaFiles) },
  ];
}

function getCriminalRecordCheck(compliance: Compliance) {
  return [
    {
      label: 'DBS Certificate',
      value:
        (compliance.metadata?.criminalRecordType ===
          ECriminalRecordCheckType.Dbs &&
          filterFiles(compliance.metadata?.dbsCertificateFile)) ||
        (compliance.metadata?.criminalRecordType ===
          ECriminalRecordCheckType.Ni &&
          filterFiles(compliance.metadata?.niCertificateFile)) ||
        (compliance.metadata?.criminalRecordType ===
          ECriminalRecordCheckType.Pvg &&
          filterFiles(compliance.metadata?.pvgCertificateFile)) ||
        (compliance.metadata?.criminalRecordType ===
          ECriminalRecordCheckType.International &&
          filterFiles(compliance.metadata?.internationalCertificateFile)),
    },
    {
      label: 'Expiry Date',
      value:
        !!compliance.metadata?.dbsTimePeriod &&
        !!compliance.metadata?.dbsCustomDate,
    },
  ];
}

function getIDBadge(compliance: Compliance) {
  return [
    {
      label: 'Profile Picture',
      value: filterFiles(compliance.metadata?.profilePictureFile),
    },
    { label: 'ID badge', value: filterFiles(compliance.metadata?.badgeFile) },
  ];
}

function getProfessionalRegistration(compliance: Compliance) {
  return [
    {
      label: 'Registration Certificates',
      value: filterFiles(compliance.metadata?.registrationCertificates),
    },
    {
      label: 'Registration Checks Files',
      value: filterFiles(compliance.metadata?.registrationChecksFiles),
    },
  ];
}

function getOccupationalHealthClearance(compliance: Compliance) {
  return [
    {
      label: 'Fitness to work certificate',
      value: filterFiles(compliance.metadata?.fitnessToWorkCertificateFile),
    },
    { label: 'Bloods', value: filterFiles(compliance.metadata?.bloodsFile) },
    {
      label: 'Medical Questionnaire',
      value: filterFiles(compliance.metadata?.medicalQuestionnaireFile),
    },
  ];
}

function getMandatoryTraining(compliance: Compliance) {
  return [
    {
      label: 'Training Certificate',
      value: filterFiles(compliance.metadata?.trainingCertificateFile),
    },
    {
      label: 'Coverage Certificate',
      value: filterFiles(compliance.metadata?.coverageCertificateFile),
    },
  ];
}

function getIELTSOET(compliance: Compliance) {
  return [
    {
      label: 'IELTS|OET Certificate',
      value: filterFiles(compliance.metadata?.ieltsCertificateFiles),
    },
    {
      label: 'Expiry Date',
      value: !!compliance.metadata?.ieltsCertificateExpiryDate,
    },
  ];
}

function getIndemnityInsurance(compliance: Compliance) {
  return [
    {
      label: 'Indemnity Insurance',
      value: filterFiles(compliance.metadata?.indemnityInsuranceFiles),
    },
    {
      label: 'Expiry Date',
      value: !!compliance.metadata?.indemnityInsuranceExpiryDate,
    },
  ];
}

function getLifeSupport(compliance: Compliance) {
  return [
    {
      label: 'Life Support Certificate',
      value:
        (compliance.metadata?.lifeSupportType === LifeSupportType.Bls &&
          filterFiles(compliance.metadata?.blsCertificateFiles)) ||
        (compliance.metadata?.lifeSupportType === LifeSupportType.Ils &&
          filterFiles(compliance.metadata?.ilsCertificateFiles)) ||
        (compliance.metadata?.lifeSupportType === LifeSupportType.Als &&
          filterFiles(compliance.metadata?.alsCertificateFiles)),
    },
    {
      label: 'Expiry Date',
      value: !!compliance.metadata?.lifeSupportExpiryDate,
    },
  ];
}

function getPrimaryMedicalQualification(compliance: Compliance) {
  return [
    {
      label: 'Diplomas',
      value: filterFiles(compliance.metadata?.universityDiplomasFiles),
    },
    {
      label: 'Expiry Date',
      value: !!compliance.metadata?.universityDiplomasExpiryDate,
    },
  ];
}

function getIdentityInformation(compliance: Compliance) {
  return [
    {
      label: 'Passport',
      value: filterFiles(compliance.metadata?.passportFiles),
    },
    {
      label: 'Driving License',
      value: filterFiles(compliance.metadata?.drivingLicenseFiles),
    },
    {
      label: 'Proof of Address',
      value: filterFiles(compliance.metadata?.proofOfAddressFiles),
    },
  ];
}

export function getComplianceSubItems(compliance: Compliance): {
  label: string;
  value: ComplianceFile[] | boolean | undefined;
}[] {
  switch (compliance.taskName) {
    case EComplianceName.RegistrationDocuments:
      return getRegistrationDocuments(compliance);
    case EComplianceName.EmploymentHistoryReferences:
      return getEmploymentHistoryReferences(compliance);
    case EComplianceName.RightToWork:
      return getRightToWork(compliance);
    case EComplianceName.CriminalRecordCheck:
      return getCriminalRecordCheck(compliance);
    case EComplianceName.IDBadge:
      return getIDBadge(compliance);
    case EComplianceName.ProfessionalRegistration:
      return getProfessionalRegistration(compliance);
    case EComplianceName.OccupationalHealthClearance:
      return getOccupationalHealthClearance(compliance);
    case EComplianceName.MandatoryTraining:
      return getMandatoryTraining(compliance);
    case EComplianceName.IELTSOET:
      return getIELTSOET(compliance);
    case EComplianceName.IndemnityInsurance:
      return getIndemnityInsurance(compliance);
    case EComplianceName.LifeSupport:
      return getLifeSupport(compliance);
    case EComplianceName.PrimaryMedicalQualification:
      return getPrimaryMedicalQualification(compliance);
    case EComplianceName.IdentityInformation:
      return getIdentityInformation(compliance);
    default:
      return [];
  }
}
