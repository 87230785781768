import React, { useEffect, useMemo, useState } from 'react';
import { ComplianceEditContainer } from './compliance-edit.style';
import { ATag, ProgressBar, TabPageContainer } from '../../ui';
import { EmploymentHistoryReferences } from '../compliances-tab/employment-history.component';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  ERoute,
} from '../../../utils';
import {
  Compliance,
  ComplianceMetaData,
  EComplianceName,
  HostedFile,
} from '../../../backend/careo-api';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { RegistrationDocuments } from '../compliances-tab/registration-documents.component';
import { RightToWork } from '../compliances-tab/right-to-work.component';
import { CriminalRecordCheck } from '../compliances-tab/criminal-record.component';
import { ProfessionalRegistration } from '../compliances-tab/professional-registration.component';
import { IdBadge } from '../compliances-tab/id-badge.component';
import { OccupationHealthClearance } from '../compliances-tab/occupation-health-clearance';
import { MandatoryTraining } from '../compliances-tab/mandatory-training';
import { IdentityInformation } from '../compliances-tab/identity-information.component';
import { EnglishLanguageCertification } from '../compliances-tab/english-certification.component';
import { PrimaryMedicalQualification } from '../compliances-tab/primary-medical-qualification.component';
import { LifeSupport } from '../compliances-tab/life-support.component';
import { IndemnityInsurance } from '../compliances-tab/indemnity-insurance.component';
import { useCompliances } from '../../../api-query/hooks';

export const complianceTasksList = Object.values(EComplianceName);

const COMPONENT_MAP = {
  [EComplianceName.RegistrationDocuments]: RegistrationDocuments,
  [EComplianceName.IELTSOET]: EnglishLanguageCertification,
  [EComplianceName.IdentityInformation]: IdentityInformation,
  [EComplianceName.PrimaryMedicalQualification]: PrimaryMedicalQualification,
  [EComplianceName.EmploymentHistoryReferences]: EmploymentHistoryReferences,
  [EComplianceName.RightToWork]: RightToWork,
  [EComplianceName.CriminalRecordCheck]: CriminalRecordCheck,
  [EComplianceName.IDBadge]: IdBadge,
  [EComplianceName.ProfessionalRegistration]: ProfessionalRegistration,
  [EComplianceName.OccupationalHealthClearance]: OccupationHealthClearance,
  [EComplianceName.MandatoryTraining]: MandatoryTraining,
  [EComplianceName.LifeSupport]: LifeSupport,
  [EComplianceName.IndemnityInsurance]: IndemnityInsurance,
};

type ComplianceEditProps = {
  selectedCompliance: Compliance;
};

export const ComplianceEdit = ({ selectedCompliance }: ComplianceEditProps) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { compliancesList, isLoading, updateCompliance } = useCompliances(id!);

  const [documents, setDocuments] = useState<HostedFile[]>([]);

  const complianceCompletion = useMemo(() => {
    const total = compliancesList.filter((el) => el.isActive);
    const passed = total.filter((el) => el.isApproved);

    return {
      percentage: total.length > 0 ? (passed.length / total.length) * 100 : 0,
      passed: passed.length,
      total: total.length,
    };
  }, [compliancesList]);

  const getCandidateDocuments = () => {
    AxiosInstance.files
      .filesControllerFindCandidateDocs(id!)
      .then((response) => {
        setDocuments(response.data.items);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const onClickUpdateMetadata = async (
    id: string,
    values: ComplianceMetaData,
    aiCheck = false,
  ) => {
    updateCompliance({
      complianceId: id,
      complianceData: { metadata: { ...values } },
      validate: !!aiCheck,
    })
      .then(() => {
        getCandidateDocuments();
      })
      .catch(() => {});
  };

  const onUpdateValidation = (idCompliance: string, value: string) => {
    const isApproved = value === 'true' ? true : false;

    updateCompliance({
      complianceId: idCompliance,
      complianceData: { isApproved },
      validate: false,
    });
  };

  useEffect(() => {
    getCandidateDocuments();
  }, []);

  const renderTabContent = () => {
    if (!selectedCompliance) return <></>;

    const Component = COMPONENT_MAP[selectedCompliance.taskName];
    if (!Component) return <></>;

    return (
      <Component
        compliance={selectedCompliance}
        documents={documents}
        onClickSubmit={onClickUpdateMetadata}
        getDocuments={getCandidateDocuments}
        onUpdateValidation={onUpdateValidation}
        data-testid={`tab-content-${selectedCompliance.taskName}`}
      />
    );
  };

  return (
    <ComplianceEditContainer data-testid="compliance-edit-container">
      <TabPageContainer data-testid="tab-page-container">
        <div className="compliance-completion">
          <ProgressBar value={complianceCompletion.percentage} />{' '}
          <div>
            &nbsp;&nbsp;&nbsp;&nbsp;
            {complianceCompletion.passed}/{complianceCompletion.total} Passed
          </div>
        </div>
        <div className="tab-items-list" data-testid="tab-items-list">
          {compliancesList.map((el) => (
            <ATag
              className={`tab-item ${el.taskName === selectedCompliance?.taskName && 'active'}`}
              onClick={() =>
                navigate(
                  `/${ERoute.CANDIDATES}/${id}/compliance/${el.taskName}`,
                )
              }
              key={el.taskName}
              data-testid={`tab-item-${el.taskName}`} // Added a unique test ID for each tab
            >
              {el.taskName}
            </ATag>
          ))}
        </div>
        <div
          className="tab-content-container"
          data-testid="tab-content-container"
        >
          {renderTabContent()}
        </div>
      </TabPageContainer>
    </ComplianceEditContainer>
  );
};
