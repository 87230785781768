import { CreateCampaignDto, Campaign } from '../../backend/careo-api';
import { AxiosInstance } from '../../utils';

// Fetch all campaigns
export const getCampaigns = async () => {
  const response = await AxiosInstance.campaigns.campaignsControllerFindAll();
  return response.data.items;
};

export const getCampaignById = async (id: string) => {
  const response = await AxiosInstance.campaigns.campaignsControllerFindOne(id);
  return response.data;
};

export const createCampaign = async (campaignData: CreateCampaignDto) => {
  const response =
    await AxiosInstance.campaigns.campaignsControllerCreate(campaignData);
  return response.data;
};

// Update a campaign
export const updateCampaign = async (
  id: string,
  campaignData: CreateCampaignDto,
) => {
  const response = await AxiosInstance.campaigns.campaignsControllerUpdate(
    id,
    campaignData,
  );
  return response.data;
};

// Delete a campaign
export const deleteCampaign = async (campaign: Campaign) => {
  await AxiosInstance.campaigns.campaignsControllerDelete(campaign._id);
};
