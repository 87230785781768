import { ValidatePlacementDto } from '../../backend/careo-api';
import { AxiosInstance } from '../../utils';

// Fetch all placements
export const getPlacements = async () => {
  const response =
    await AxiosInstance.applications.applicationsControllerGetAllPlacements();
  return response.data.items;
};

export const validatePlacement = async (
  placementId: string,
  values: ValidatePlacementDto,
) => {
  const response =
    await AxiosInstance.applications.applicationsControllerValidatePlacement(
      placementId,
      {
        fee: Number(values.fee),
        vat: Number(values.vat),
        onCallFee: Number(values.onCallFee),
        onCallActiveRate: Number(values.onCallActiveRate),
        onCallPassiveRate: Number(values.onCallPassiveRate),
        coreRate: Number(values.coreRate),
        unSocialRate: Number(values.unSocialRate),
      },
    );

  return response.data;
};
