import {
  CreateCampaignTemplateDto,
  CampaignTemplate,
  EComplianceName,
} from '../../backend/careo-api';
import { AxiosInstance } from '../../utils';

// Fetch all campaignTemplates
export const getCampaignTemplates = async () => {
  const response =
    await AxiosInstance.campaignTemplates.campaignTemplatesControllerFindAll();
  return response.data.items;
};

export const createCampaignTemplate = async (
  campaignTemplateData: CreateCampaignTemplateDto,
) => {
  const response =
    await AxiosInstance.campaignTemplates.campaignTemplatesControllerCreate(
      campaignTemplateData,
    );
  return response.data;
};

// Update a campaignTemplate
export const updateCampaignTemplate = async (
  id: string,
  campaignTemplateData: CreateCampaignTemplateDto,
) => {
  const response =
    await AxiosInstance.campaignTemplates.campaignTemplatesControllerUpdate(
      id,
      campaignTemplateData,
    );
  return response.data;
};

// Delete a campaignTemplate
export const deleteCampaignTemplate = async (
  campaignTemplate: CampaignTemplate,
) => {
  await AxiosInstance.campaignTemplates.campaignTemplatesControllerDelete(
    campaignTemplate._id,
  );
};

export const selectCandidateCompliances = async (
  candidateId: string,
  selectedTasksList: EComplianceName[],
  reminderDate?: string,
) => {
  await AxiosInstance.compliances.compliancesControllerSetCompliancesByCandidateId(
    candidateId,
    {
      compliances: selectedTasksList.map((el) => ({
        taskName: el,
        reminderDate: reminderDate,
      })),
    },
  );
};
