import React, { useState } from 'react';
import { Table } from '../ui';
import {
  CheckIcon,
  DownloadIcon,
  EditIcon,
  EyeIcon,
  MinusIcon,
  PlusIcon,
} from '../../icons';
import { ERoute, formatDate } from '../../utils';
import { Compliance } from '../../backend/careo-api';
import { isCRMApp } from '../../environment/app.type';
import { useNavigate, useParams } from 'react-router-dom';
import { ComplianceStatus } from './compliance-status.component';
import { downloadFile } from '../../utils';
import { ComplianceDateForm } from './compliance-date-form.component';
import { useModal } from '../../contexts/side-modal.context';
import { useCompliances } from '../../api-query/hooks';
import { getComplianceSubItems } from '../../utils/compliances.utils';

const ComplianceRow = ({
  compliance,
  candidateId,
}: {
  compliance: Compliance;
  candidateId: string;
}) => {
  const navigate = useNavigate();
  const { openModal } = useModal();

  const [isOpen, setIsOpen] = useState(false);
  const subItems = getComplianceSubItems(compliance);

  const onClickEdit = (compliance: Compliance) => {
    openModal({
      title: 'Update Date',
      component: (
        <ComplianceDateForm
          compliance={compliance}
          data-testid="compliance-date-form"
        />
      ),
    });
  };

  return (
    <>
      <tr key={compliance._id} data-testid={`compliance-row-${compliance._id}`}>
        <td onClick={() => setIsOpen((prev) => !prev)} className="toggle-td">
          {isOpen ? <MinusIcon /> : <PlusIcon />}
        </td>
        <td>
          <div
            className={`task-container`}
            data-testid={`task-container-${compliance._id}`}
          >
            <label
              htmlFor={`task-checkbox-${compliance._id}`}
              data-testid={`task-label-${compliance._id}`}
            >
              {compliance.taskName}
            </label>
          </div>
        </td>
        <td data-testid={`reminder-date-${compliance._id}`}>
          {compliance.reminderDate ? formatDate(compliance.reminderDate) : '-'}
        </td>
        <td data-testid={`expiry-date-${compliance._id}`}>
          {compliance.expiryDate ? formatDate(compliance.expiryDate) : '-'}
        </td>
        <td>
          <ComplianceStatus
            value={compliance.status}
            data-testid={`compliance-status-${compliance._id}`}
          />
        </td>
        <td>
          <div className="action-item">
            {!isCRMApp && (
              <>
                <div
                  className="edit-icon"
                  onClick={() => onClickEdit(compliance)}
                  data-testid={`edit-icon-${compliance._id}`}
                >
                  <EditIcon />
                </div>
                <div
                  className="view-icon"
                  onClick={() =>
                    navigate(
                      `/${ERoute.CANDIDATES}/${candidateId}/compliance/${compliance.taskName}`,
                    )
                  }
                  data-testid={`view-icon-${compliance._id}`}
                >
                  <EyeIcon />
                </div>
              </>
            )}
            {/* <div
              className="delete-icon disabled"
              data-testid={`delete-icon-${compliance._id}`}
            >
              <DeleteIcon />
            </div> */}
          </div>
        </td>
      </tr>
      {isOpen && (
        <>
          {subItems.map((el, index) => {
            const isBooleanValue = typeof el.value === 'boolean';
            const activeFiles = Array.isArray(el.value) ? el.value : [];

            const downloadFiles = () => {
              activeFiles.map((el) => downloadFile(el.file));
            };

            return (
              <tr
                data-testid={`compliance-row-sub-item-${compliance._id}-${index}`}
              >
                <td></td>
                <td>
                  <div className={`task-container`}>
                    <label>{el.label}</label>
                  </div>
                </td>
                <td></td>
                <td></td>
                <td className="check-icon">
                  {isBooleanValue || !!activeFiles.length ? <CheckIcon /> : '-'}
                </td>
                <td>
                  <div className="action-item">
                    {!isCRMApp && !!activeFiles.length && (
                      <>
                        <div
                          className="download-icon"
                          onClick={() => downloadFiles()}
                        >
                          <DownloadIcon />
                        </div>
                      </>
                    )}
                  </div>
                </td>
              </tr>
            );
          })}
        </>
      )}
    </>
  );
};

export const ComplianceList = () => {
  const { id } = useParams();
  const { compliancesList, isLoading } = useCompliances(id!);

  return (
    <>
      <Table data-testid="compliance-list">
        <thead>
          <tr>
            <th></th>
            <th>Task</th>
            <th>Reminder Date</th>
            <th>Expiry Date</th>
            <th>Status</th>
            <th className="action-th">Action</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td
                colSpan={100}
                className="text-center"
                data-testid="loading-row"
              >
                Loading ...
              </td>
            </tr>
          ) : compliancesList.length > 0 ? (
            <>
              {compliancesList.map((el) => {
                return <ComplianceRow compliance={el} candidateId={id!} />;
              })}
            </>
          ) : (
            <tr>
              <td
                colSpan={100}
                className="text-center"
                data-testid="no-item-found"
              >
                No item found
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
};
