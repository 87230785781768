import React, { useState } from 'react';
import { TitleCard, Button, Textarea, FormContainer } from '../ui';
import { CreateNoteDto } from '../../backend/careo-api';
import {
  AxiosInstanceErrorResponse,
  handleFormsServerErrors,
} from '../../utils';
import { toast } from 'react-toastify';
import { noteSchema } from '../../utils/validators/notes.validator';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { AxiosResponse } from 'axios';

type NoteFormProps = {
  onCancel: () => void;
  onSuccess: () => void;
  submitRequest: (noteText: string) => Promise<AxiosResponse<void, any>>;
};

export const NoteForm = ({
  onCancel,
  onSuccess,
  submitRequest,
}: NoteFormProps) => {
  const {
    register,
    getValues,
    handleSubmit,
    setError,
    setFocus,
    formState: { errors },
  } = useForm<CreateNoteDto>({
    resolver: yupResolver(noteSchema),
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onClickSubmit = () => {
    setIsSubmitting(true);
    const values = getValues();

    submitRequest(values.text)
      .then(() => {
        toast.success('Note submitted successfully');
        onSuccess();
        setIsSubmitting(false);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        handleFormsServerErrors(error, setError, setFocus);
        toast.error(error.message);
      });
  };

  return (
    <FormContainer data-testid="form-container">
      <TitleCard data-testid="title-card">Notes Info</TitleCard>
      <Textarea
        placeholder="Enter here ..."
        label=""
        required
        data-testid="note-textarea"
        register={register('text')}
        error={errors.text}
      />
      <div className="form-actions" data-testid="form-actions">
        <Button
          onClick={() => onCancel()}
          data-testid="cancel-button"
          type="primary"
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={handleSubmit(onClickSubmit)}
          data-testid="submit-button"
          disabled={isSubmitting}
        >
          Add Note
        </Button>
      </div>
    </FormContainer>
  );
};
