import React, { useState } from 'react';
import { DropdownSelectText } from '../ui';
import { DonutChart } from '../charts/donut-chart';
import { RevenueChartContainer } from './revenue-chart';

type PerformanceChartProps = {
  isLoading: boolean;
  chartData: {
    backgroundColor: string;
    borderColor: string;
    label: string;
    value: number;
  }[];
};

export const PerformanceChart = ({
  isLoading,
  chartData,
}: PerformanceChartProps) => {
  const [duration, setDuration] = useState('');

  return (
    <RevenueChartContainer data-testid="revenue-chart-container">
      <div className="header" data-testid="revenue-chart-header">
        <span className="title" data-testid="revenue-chart-title">
          Compliance
        </span>
        <div className="selection" data-testid="revenue-chart-dropdown">
          <DropdownSelectText
            items={[
              {
                label: 'All Time',
                value: '',
              },
            ]}
            selectedItem={duration}
            setSelectedItem={setDuration}
          />
        </div>
      </div>
      <DonutChart
        config={{
          labels: chartData.map((el) => el.label),
          backgroundColors: chartData.map((el) => el.backgroundColor),
          borderColors: chartData.map((el) => el.borderColor),
          subtitle: 'Tasks',
        }}
        data={chartData.map((el) => el.value)}
        isLoading={isLoading}
        data-testid="donut-chart"
      />
      <div className="labels-container" data-testid="chart-data-labels">
        {chartData.map((el) => (
          <div
            className="label"
            key={el.label}
            data-testid={`chart-label-${el.label}`}
          >
            <div>
              <div
                style={{
                  backgroundColor: el.backgroundColor,
                }}
                data-testid={`chart-color-box-${el.label}`}
              ></div>
              <div data-testid={`chart-label-text-${el.label}`}>{el.label}</div>
            </div>
            <div data-testid={`chart-value-${el.label}`}>{el.value}</div>
          </div>
        ))}
      </div>
    </RevenueChartContainer>
  );
};
