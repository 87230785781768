export const PagesUrls = {
  TRUSTED_CLIENT: { Index: '/trusts' },
  PAYROLLS: { Index: '/payrolls', Details: '/payrolls/:id' },
  PLACEMENTS: { Index: '/placements', Details: '/placements/:id' },
  CALENDAR: { Index: '/calendar', Details: '/calendar/:id' },
  MESSAGES: {
    Index: '/messages',
    Candidate: '/messages/candidate',
    Client: '/messages/client',
    WildCard: '/messages/*',
  },
  EMAILS: {
    Index: '/emails/:emailType',
    EmailDetails: '/emails/:emailType/:emailId',
    WildCard: '/emails/*',
  },
  SETTING: { Index: '/settings', Type: '/settings/:type' },
  TIMESHEET: {
    Client: '/timesheet/:token/client',
    Download: '/timesheet/:token/client/download',
    Candidate: '/timesheet/:token/candidate',
  },
  OFFER: {
    Client: '/offer/:token/client',
    Candidate: '/offer/:token/candidate',
    Recruiter: '/offer/:placementId/recruiter',
  },
};
