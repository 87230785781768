import React, { useState } from 'react';
import { Table } from '../../ui';
import { daysTitleList } from '../../../constants';
import { isDateIncluded } from '../../../utils';
import {
  Availability,
  EJobShift,
  EAvailabilityStatus,
  EAiJobShift,
} from '../../../backend/careo-api';

import { AddAvailabilityConfirmModal } from './add-availability-confirm.component';
import { RemoveAvailabilityConfirmModal } from './remove-availability-confirm.component';

type AvailabilityCalendarProps = {
  weeksDay: {
    day: number;
    label: string;
    week: number;
    month: number;
    year: number;
  }[][];
  availabilities: Availability[];
};
const AvailabilityCalendar = ({
  weeksDay,
  availabilities,
}: AvailabilityCalendarProps) => {
  const [slotToAdd, setSlotToAdd] = useState<{
    day: Date;
    shift: EAiJobShift;
  }>();
  const [slotsToRemove, setSlotsToRemove] = useState<Availability[]>([]);

  return (
    <>
      <Table className="calendar calendar-row">
        <thead>
          <tr>
            {daysTitleList.map((el) => (
              <th key={el.value}>{el.label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {weeksDay.map((days, index) => (
            <tr key={index}>
              {days.map((el) => {
                const cellDate = new Date(
                  Date.UTC(el.year, el.month - 1, el.day),
                );

                const dayAvailabilities = availabilities.filter(
                  (availableTime) =>
                    isDateIncluded(
                      cellDate,
                      new Date(availableTime?.date.split('T')[0]),
                    ),
                );

                const dayAvailable = !!dayAvailabilities.length;

                if (!dayAvailable) {
                  return (
                    <td
                      key={el.day}
                      className={el.label !== 'currentMonth' ? 'disabled' : ''}
                    >
                      <div
                        className="day-container free"
                        onClick={() =>
                          setSlotToAdd({
                            day: cellDate,
                            shift: EAiJobShift.FullDay,
                          })
                        }
                      >
                        <div className="day-number">{el.day}</div>
                      </div>
                    </td>
                  );
                }

                const fullDayAvailable =
                  dayAvailabilities.filter(
                    (el) => el.status === EAvailabilityStatus.AVAILABLE,
                  ).length === 2;

                const fullDayBooked =
                  dayAvailabilities.filter(
                    (el) => el.status === EAvailabilityStatus.PLACED,
                  ).length === 2;

                if (fullDayAvailable) {
                  return (
                    <td
                      key={el.day}
                      className={el.label !== 'currentMonth' ? 'disabled' : ''}
                    >
                      <div
                        className="day-container slot available"
                        onClick={() => setSlotsToRemove(dayAvailabilities)}
                      >
                        <div className="day-number">{el.day}</div>
                        <div className="fullDay"></div>
                      </div>
                    </td>
                  );
                }

                if (fullDayBooked) {
                  return (
                    <td
                      key={el.day}
                      className={el.label !== 'currentMonth' ? 'disabled' : ''}
                    >
                      <div
                        className="day-container slot booked"
                        onClick={() => setSlotsToRemove([])}
                      >
                        <div className="day-number">{el.day}</div>
                        <div className="fullDay"></div>
                      </div>
                    </td>
                  );
                }

                const dayShift = dayAvailabilities.find(
                  (el) => el.availabilityTime === EJobShift.Day,
                );

                const nightShift = dayAvailabilities.find(
                  (el) => el.availabilityTime === EJobShift.Night,
                );

                return (
                  <td
                    key={el.day}
                    className={el.label !== 'currentMonth' ? 'disabled' : ''}
                  >
                    <div className="day-container">
                      <div className="day-number">{el.day}</div>
                      <div
                        className={`slot ${
                          dayShift
                            ? dayShift.status === EAvailabilityStatus.AVAILABLE
                              ? 'available'
                              : 'booked'
                            : 'free'
                        }  day `}
                        onClick={() =>
                          dayShift
                            ? dayShift.status === EAvailabilityStatus.AVAILABLE
                              ? setSlotsToRemove([dayShift])
                              : () => {}
                            : setSlotToAdd({
                                day: cellDate,
                                shift: EAiJobShift.Day,
                              })
                        }
                      />
                      <div
                        className={`slot ${
                          nightShift
                            ? nightShift.status ===
                              EAvailabilityStatus.AVAILABLE
                              ? 'available'
                              : 'booked'
                            : 'free'
                        }  night`}
                        onClick={() =>
                          nightShift
                            ? nightShift.status ===
                              EAvailabilityStatus.AVAILABLE
                              ? setSlotsToRemove([nightShift])
                              : () => {}
                            : setSlotToAdd({
                                day: cellDate,
                                shift: EAiJobShift.Night,
                              })
                        }
                      />
                    </div>
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </Table>

      <AddAvailabilityConfirmModal
        slotToAdd={slotToAdd}
        onSuccess={() => {
          setSlotToAdd(undefined);
        }}
        onCancel={() => setSlotToAdd(undefined)}
      />

      <RemoveAvailabilityConfirmModal
        slotsToRemove={slotsToRemove}
        onSuccess={() => {
          setSlotsToRemove([]);
        }}
        onCancel={() => setSlotsToRemove([])}
      />
    </>
  );
};

export default AvailabilityCalendar;
