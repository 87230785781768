import React, { useState } from 'react';
import { DownloadIcon, MinusIcon, PlusIcon } from '../../icons';
import {
  Compliance,
  ComplianceFile,
  ComplianceMetaData,
  EComplianceName,
  ECriminalRecordCheckType,
  LifeSupportType,
} from '../../backend/careo-api';
import { useParams } from 'react-router-dom';
import { Badge, Table } from '../ui';
import { downloadFile, formatDate } from '../../utils';

type ComplianceAuditProps = {
  compliancesList: Compliance[];
};

type ComplianceItem = {
  label: string;
  value: keyof ComplianceMetaData;
};

type ComplianceItemsMap = {
  [key in EComplianceName]: ComplianceItem[];
};

const complianceItemsMap: ComplianceItemsMap = {
  [EComplianceName.IndemnityInsurance]: [
    { label: 'Indemnity Insurance', value: 'indemnityInsuranceFiles' },
  ],
  [EComplianceName.LifeSupport]: [
    { label: 'BLS Certificate', value: 'blsCertificateFiles' },
    { label: 'ILS Certificate', value: 'ilsCertificateFiles' },
    { label: 'ALS Certificate', value: 'alsCertificateFiles' },
  ],
  [EComplianceName.IdentityInformation]: [
    { label: 'Passport', value: 'passportFiles' },
    { label: 'Driving License', value: 'drivingLicenseFiles' },
    { label: 'Proof of Address', value: 'proofOfAddressFiles' },
  ],
  [EComplianceName.IELTSOET]: [
    { label: 'IELTS Certificate', value: 'ieltsCertificateFiles' },
  ],
  [EComplianceName.PrimaryMedicalQualification]: [
    { label: 'University Diplomas', value: 'universityDiplomasFiles' },
  ],
  [EComplianceName.RegistrationDocuments]: [
    { label: 'Application Form', value: 'applicationFormFiles' },
    { label: 'Company Handbook', value: 'handbookFiles' },
    { label: 'Interview Form', value: 'interviewFormFiles' },
    { label: 'Appraisals', value: 'appraisalsFiles' },
    { label: 'ECS', value: 'ecsFiles' },
  ],
  [EComplianceName.EmploymentHistoryReferences]: [
    { label: 'CV', value: 'cvFile' },
    { label: 'References', value: 'references' },
  ],
  [EComplianceName.RightToWork]: [
    {
      label: 'Birth Certificate or Certificate of Naturalisation',
      value: 'certificateFiles',
    },
    { label: 'Visa', value: 'visaFiles' },
  ],
  [EComplianceName.CriminalRecordCheck]: [
    { label: 'DBS Certificate', value: 'dbsCertificateFile' },
    // { label: 'DBS Check', value: 'dbsCheckFile' },
    { label: 'PVG Certificate', value: 'pvgCertificateFile' },
    // { label: 'PVG Check', value: 'pvgCheckFile' },
    { label: 'NI Certificate', value: 'niCertificateFile' },
    // { label: 'NI Check', value: 'niCheckFile' },
    {
      label: 'International Certificate',
      value: 'internationalCertificateFile',
    },
    // { label: 'International Check', value: 'internationalCheckFile' },
  ],
  [EComplianceName.IDBadge]: [
    { label: 'Profile Picture', value: 'profilePictureFile' },
    { label: 'ID Badge', value: 'badgeFile' },
  ],
  [EComplianceName.ProfessionalRegistration]: [
    { label: 'Registration Certificates', value: 'registrationCertificates' },
    { label: 'Registration Checks', value: 'registrationChecksFiles' },
  ],
  [EComplianceName.OccupationalHealthClearance]: [
    {
      label: 'Fitness to Work Certificate',
      value: 'fitnessToWorkCertificateFile',
    },
    { label: 'Bloods', value: 'bloodsFile' },
    { label: 'Medical Questionnaire', value: 'medicalQuestionnaireFile' },
  ],
  [EComplianceName.MandatoryTraining]: [
    { label: 'Training Certificate', value: 'trainingCertificateFile' },
    { label: 'Coverage Certificate', value: 'coverageCertificateFile' },
  ],
};

function getComplianceItems(
  compliance: Compliance,
): { label: string; value: string }[] {
  if (compliance.taskName === EComplianceName.CriminalRecordCheck) {
    switch (compliance?.metadata?.criminalRecordType) {
      case ECriminalRecordCheckType.Dbs:
        return [{ label: 'DBS Certificate', value: 'dbsCertificateFile' }];
      case ECriminalRecordCheckType.Pvg:
        return [{ label: 'PVG Certificate', value: 'pvgCertificateFile' }];
      case ECriminalRecordCheckType.Ni:
        return [{ label: 'NI Certificate', value: 'niCertificateFile' }];
      case ECriminalRecordCheckType.International:
        return [
          {
            label: 'International Certificate',
            value: 'internationalCertificateFile',
          },
        ];
      default:
        return [];
    }
  }

  if (compliance.taskName === EComplianceName.LifeSupport) {
    switch (compliance?.metadata?.lifeSupportType) {
      case LifeSupportType.Bls:
        return [{ label: 'BLS Certificate', value: 'blsCertificateFiles' }];
      case LifeSupportType.Ils:
        return [{ label: 'ILS Certificate', value: 'ilsCertificateFiles' }];
      case LifeSupportType.Als:
        return [{ label: 'ALS Certificate', value: 'alsCertificateFiles' }];
      default:
        return [];
    }
  }

  return complianceItemsMap[compliance.taskName];
}

const getSubItems = (compliance: Compliance) => {
  const items = getComplianceItems(compliance as any);

  if (items) {
    return items.map(({ label, value }) => ({
      label,
      value: (compliance?.metadata as any)?.[value as any] as any,
    }));
  }
  return [];
};

const ItemAuditRow = ({
  compliance,
}: {
  compliance: Compliance;
  id: string;
}) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <>
      <tr
        key={compliance._id}
        data-testid={`compliance-row-${compliance._id}`}
        onClick={() => setIsOpen((prev) => !prev)}
        className="audit-tr"
      >
        <td className="toggle-td">
          {isOpen ? <MinusIcon /> : <PlusIcon />}{' '}
          <label
            htmlFor={`task-checkbox-${compliance._id}`}
            data-testid={`task-label-${compliance._id}`}
          >
            {compliance.taskName}
          </label>
        </td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      {isOpen && (
        <>
          {getSubItems(compliance).map((el) => {
            return <SubItemAuditRow metadataItem={el} />;
          })}
        </>
      )}
    </>
  );
};

const SubItemAuditRow = ({
  metadataItem,
}: {
  metadataItem: {
    label: string;
    value: ComplianceFile[] | undefined;
  };
}) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <>
      <tr className="audit-tr" onClick={() => setIsOpen((prev) => !prev)}>
        <td className="toggle-td">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {isOpen ? <MinusIcon /> : <PlusIcon />}{' '}
          <div className={`task-container`} data-testid={`task-container`}>
            <label htmlFor={`task-checkbox`} data-testid={`task-label`}>
              {metadataItem.label}
            </label>
          </div>
        </td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      {isOpen && (
        <>
          {(metadataItem.value ?? [])
            .sort((a, b) => {
              if (a.archiveDate && b.archiveDate) {
                return (
                  new Date(b.archiveDate).getTime() -
                  new Date(a.archiveDate).getTime()
                );
              } else if (a.archiveDate) {
                return 1;
              } else if (b.archiveDate) {
                return -1;
              } else {
                return (
                  new Date(b.file.timestamp).getTime() -
                  new Date(a.file.timestamp).getTime()
                );
              }
            })
            ?.map((el, index) => {
              return (
                <tr
                  data-testid={`compliance-row-sub-item-${el.file._id}`}
                  key={index}
                >
                  <td>{el.file.fileName}</td>
                  <td>{formatDate(el.addedDate)}</td>
                  <td>
                    <Badge type={el.archiveDate ? 'info' : 'success'}>
                      {el.archiveDate ? 'Archive' : 'Active'}
                    </Badge>
                  </td>
                  <td>{el.archiveDate ? formatDate(el.archiveDate) : '-'}</td>
                  <td>{formatDate(el.file.timestamp)}</td>
                  <td>
                    <div className="action-item">
                      <div
                        className="download-icon"
                        onClick={() => downloadFile(el.file)}
                      >
                        <DownloadIcon />
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
        </>
      )}
    </>
  );
};

export const ComplianceAudit = ({ compliancesList }: ComplianceAuditProps) => {
  const { id } = useParams();

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>File Name</th>
            <th>Added Date</th>
            <th>Status</th>
            <th>Archived Date</th>
            <th>Uploaded Date</th>
            <th className="action-th"></th>
          </tr>
        </thead>
        <tbody>
          {compliancesList.length > 0 ? (
            <>
              {compliancesList.map((el) => {
                return <ItemAuditRow compliance={el} id={id!} />;
              })}
            </>
          ) : (
            <tr>
              <td
                colSpan={100}
                className="text-center"
                data-testid="no-item-found"
              >
                No item found
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
};
