import React, { useEffect, useState } from 'react';
import { FieldText, Button, FormContainer } from '../ui';
import { useForm } from 'react-hook-form';
import { Application, DurationDto } from '../../backend/careo-api';
import {
  AxiosInstanceErrorResponse,
  UpdateVacancySchema,
  handleFormsServerErrors,
} from '../../utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { useModal } from '../../contexts/side-modal.context';
import { useJobApplications } from '../../api-query/hooks/useJobApplications';
import { useParams } from 'react-router-dom';

export const UpdateApplicationForm = ({
  selectedApplication,
}: {
  selectedApplication: Application;
}) => {
  const { id } = useParams();
  const { closeModal } = useModal();
  const { updateApplicationDuration } = useJobApplications(id!);

  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    setFocus,
  } = useForm<DurationDto>({
    resolver: yupResolver(UpdateVacancySchema),
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onClickSubmit = () => {
    setIsSubmitting(true);
    const values = getValues();
    updateApplicationDuration({
      applicationId: selectedApplication._id,
      durationDto: values,
    })
      .then(() => {
        closeModal();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        handleFormsServerErrors(error, setError, setFocus);
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    reset({
      ...selectedApplication,
      availableFrom: selectedApplication.availableFrom?.split('T')?.[0] ?? '',
      availableTo: selectedApplication.availableTo?.split('T')?.[0] ?? '',
    });
  }, [selectedApplication]);

  return (
    <FormContainer>
      <FieldText
        label="Candidate Name"
        required
        value={
          selectedApplication?.candidate?.firstName +
          ' ' +
          selectedApplication?.candidate?.lastName
        }
        disabled
        data-testid="candidate-name"
      />
      <FieldText
        placeholder="Enter here ..."
        label="Start Date"
        required
        type="date"
        register={register('availableFrom')}
        error={errors.availableFrom}
        min={selectedApplication.job.vacantFrom.split('T')[0]}
        max={selectedApplication.job.vacantTo.split('T')[0]}
        data-testid="start-date"
      />
      <FieldText
        placeholder="Enter here ..."
        label="End Date"
        required
        type="date"
        register={register('availableTo')}
        error={errors.availableTo}
        min={selectedApplication.job.vacantFrom.split('T')[0]}
        max={selectedApplication.job.vacantTo.split('T')[0]}
        data-testid="end-date"
      />
      <div className="form-actions">
        <Button
          onClick={() => closeModal()}
          data-testid="cancel-button"
          type="primary"
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={handleSubmit(onClickSubmit)}
          disabled={isSubmitting}
          data-testid="update-button"
        >
          Update
        </Button>
      </div>
    </FormContainer>
  );
};
