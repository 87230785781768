import { useMutation, useQuery } from '@tanstack/react-query';
import {} from '../api/compliances.api';
import {
  Application,
  CreateApplicationDto,
  DurationDto,
  ESocketRefreshModule,
  UpdateApplicationStatusDto,
} from '../../backend/careo-api';
import {
  createApplication,
  deleteApplication,
  getJobApplications,
  updateApplicationDuration,
  updateApplicationStatus,
} from '../api/job-applications.api';
import { rejectedPlacementStatuses } from '../../pages/placement/placements-list/placements-list.page';
import { toast } from 'react-toastify';

type UpdateApplicationDurationProps = {
  applicationId: string;
  durationDto: DurationDto;
};

type UpdateApplicationStatusProps = {
  applicationId: string;
  requestDto: UpdateApplicationStatusDto;
};

type UseJobApplicationProps = {
  jobApplicationsList: Application[];
  isLoading: boolean;
  updateApplicationDuration: (
    variables: UpdateApplicationDurationProps,
  ) => Promise<void>;
  updateApplicationStatus: (
    variables: UpdateApplicationStatusProps,
  ) => Promise<void>;
  deleteApplication: (application: Application) => Promise<void>;
  createApplication: (requestDto: CreateApplicationDto) => Promise<void>;
};

export const useJobApplications = (jobId?: string): UseJobApplicationProps => {
  // Fetch compliances (React Query caches it)
  const { data: applications = [], isLoading } = useQuery({
    queryKey: [ESocketRefreshModule.APPLICATIONS, jobId],
    queryFn: () => getJobApplications(jobId),
  });

  const updateApplicationDurationMutation = useMutation({
    mutationFn: ({
      applicationId,
      durationDto,
    }: UpdateApplicationDurationProps) => {
      return updateApplicationDuration(applicationId, durationDto).then(
        () => {},
      );
    },
    onSuccess: () => {
      toast.success('Application saved successfully');
    },
    onError: (error: any) => {
      toast.error(error?.message || 'Something went wrong');
    },
  });

  const updateApplicationStatusMutation = useMutation({
    mutationFn: ({
      applicationId,
      requestDto,
    }: UpdateApplicationStatusProps) => {
      return updateApplicationStatus(applicationId, requestDto).then(() => {});
    },
    onSuccess: () => {
      toast.success('Application saved successfully');
    },
    onError: (error: any) => {
      toast.error(error?.message || 'Something went wrong');
    },
  });

  // Delete payroll mutation
  const deleteMutation = useMutation({
    mutationFn: (selectedApplication: Application) => {
      return deleteApplication(selectedApplication);
    },
    onSuccess: () => {
      toast.success('Payroll removed successfully');
    },
    onError: (error: any) => {
      toast.error(error.message);
    },
  });

  const createMutation = useMutation({
    mutationFn: (requestDto: CreateApplicationDto) => {
      return createApplication(requestDto);
    },
    onSuccess: () => {
      toast.success('Candidate added to job successfully');
    },
    onError: (error: any) => {
      toast.error(error.message);
    },
  });

  return {
    jobApplicationsList: applications.filter(
      (el) => !rejectedPlacementStatuses.includes(el.approvalStatus!),
    ),
    isLoading,
    updateApplicationDuration: updateApplicationDurationMutation.mutateAsync,
    updateApplicationStatus: updateApplicationStatusMutation.mutateAsync,
    deleteApplication: deleteMutation.mutateAsync,
    createApplication: createMutation.mutateAsync,
  };
};
