import { useQuery, useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { getPlacements, validatePlacement } from '../api/placements.api';
import {
  Application,
  EApplicationStatus,
  ESocketRefreshModule,
  ValidatePlacementDto,
} from '../../backend/careo-api';
import {
  deleteApplication,
  getApplicationById,
  updateApplicationStatus,
} from '../api/job-applications.api';

type UsePlacementProps = {
  placementsList: Application[];
  isLoading: boolean;
  deletePlacement: (placement: Application) => Promise<void>;
  findPlacementById: (placementId: string) => Promise<Application>;
  addPlacementByApplicationId: (placementId: string) => Promise<void>;
  validatePlacement: (data: ValidatePlacementProps) => Promise<void>;
};

type ValidatePlacementProps = {
  placementId: string;
  values: ValidatePlacementDto;
};

export const usePlacements = (): UsePlacementProps => {
  // Fetch placements (React Query caches it)
  const { data: placements = [], isLoading } = useQuery({
    queryKey: [ESocketRefreshModule.PLACEMENTS],
    queryFn: getPlacements,
  });

  // Delete placement mutation
  const deleteMutation = useMutation({
    mutationFn: (selectedPlacement: Application) => {
      return deleteApplication(selectedPlacement);
    },
    onSuccess: () => {
      toast.success('Placement removed successfully');
    },
    onError: (error: any) => {
      toast.error(error.message);
    },
  });

  // Delete placement mutation
  const addPlacementByApplicationIdMutation = useMutation({
    mutationFn: (placementId: string) => {
      return updateApplicationStatus(placementId, {
        status: EApplicationStatus.Placement,
      });
    },
    onSuccess: () => {
      toast.success('Placement added successfully');
    },
    onError: (error: any) => {
      toast.error(error.message);
    },
  });

  // validate placement mutation
  const validatePlacementMutation = useMutation({
    mutationFn: ({ placementId, values }: ValidatePlacementProps) => {
      return validatePlacement(placementId, values);
    },
    onSuccess: () => {
      toast.success('Placement validated successfully');
    },
    onError: (error: any) => {
      toast.error(error.message);
    },
  });

  return {
    placementsList: placements,
    isLoading,
    deletePlacement: deleteMutation.mutateAsync,
    findPlacementById: getApplicationById,
    addPlacementByApplicationId:
      addPlacementByApplicationIdMutation.mutateAsync,
    validatePlacement: validatePlacementMutation.mutateAsync,
  };
};
