import { Navigate, RouteObject } from 'react-router-dom';
import { IRoute } from '../types/routes.type';
import { AppLayout, AuthLayout, NotFound } from '../components';
import {
  CandidatesRoutesCRM,
  CandidatesRoutesCompliance,
  SharedCandidatesRoutes,
} from './candidates.routes';
import { ClientsRoutes } from './clients.routes';
import { JobsRoutes } from './jobs.routes';
import { PayrollsRoutes } from './payrolls.routes';
import { PlacementsRoutes } from './placements.routes';
import { CalendarsRoutes } from './calendars.routes';
import { EmailsRoutes, MessagesRoutes } from './messages.routes';
import { SettingsRoutes } from './settings.routes';
import { TimesheetsRoutes } from './timesheets.routes';
import { isCRMApp } from '../environment/app.type';
import { AuthRoutes } from './auth.routes';
import { OffersRoutes } from './offer.routes';
import { DashboardRoutesCRM } from './dashboard.routes';
import { ERoute } from '../utils';

const homePageRoute = isCRMApp
  ? `/${ERoute.DASHBOARD}`
  : `/${ERoute.CANDIDATES}`;

const routesCRM: IRoute[] = [
  ...DashboardRoutesCRM,
  ...CandidatesRoutesCRM,
  ...SharedCandidatesRoutes,
  ...ClientsRoutes,
  ...JobsRoutes,
  ...PayrollsRoutes,
  ...PlacementsRoutes,
  ...CalendarsRoutes,
  ...MessagesRoutes,
  ...EmailsRoutes,
  ...SettingsRoutes,
  ...TimesheetsRoutes,
  ...OffersRoutes,
  // ...ReportingRoutes,
  { path: `/${ERoute.LOGIN}`, element: <Navigate to={homePageRoute} /> },
  { path: `/${ERoute.VERIFY_OTP}`, element: <Navigate to={homePageRoute} /> },
  { path: '*', element: <NotFound /> },
];

const routesCompliance: IRoute[] = [
  ...CandidatesRoutesCompliance,
  ...SharedCandidatesRoutes,
  ...MessagesRoutes,
  ...EmailsRoutes,
  ...SettingsRoutes,
  { path: `/${ERoute.LOGIN}`, element: <Navigate to={homePageRoute} /> },
  { path: `/${ERoute.VERIFY_OTP}`, element: <Navigate to={homePageRoute} /> },
  { path: '*', element: <NotFound /> },
];

export const authRoutes: RouteObject[] = [
  ...TimesheetsRoutes,
  ...OffersRoutes,
  {
    element: <AuthLayout />,
    children: AuthRoutes,
  },
  { path: '*', element: <Navigate to={`/${ERoute.LOGIN}`} /> },
];

const routes = isCRMApp ? routesCRM : routesCompliance;

export const appRoutes: RouteObject[] = [
  {
    path: '/',
    element: <Navigate to={homePageRoute} />,
  },
  {
    element: <AppLayout />,
    children: routes.filter((route) => !route.withoutLayout),
  },
  ...routes.filter((route) => route.withoutLayout),
  { path: '*', element: <Navigate to={homePageRoute} /> },
];
