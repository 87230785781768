import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { CreateTrustDto, Trust } from '../../backend/careo-api';
import {
  AxiosInstanceErrorResponse,
  trustSchema,
  handleFormsServerErrors,
} from '../../utils';
import { FieldText, FormContainer, Button } from '../ui';
import { useModal } from '../../contexts/side-modal.context';
import { useTrusts } from '../../api-query/hooks';

type TrustFormProps = {
  selectedTrust?: Trust;
};

export const TrustForm = ({ selectedTrust }: TrustFormProps) => {
  const { closeModal } = useModal();
  const { saveTrust } = useTrusts();

  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    setFocus,
  } = useForm<CreateTrustDto>({
    resolver: yupResolver(trustSchema),
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onClickSubmit = () => {
    setIsSubmitting(true);
    const values = getValues();

    saveTrust({ selectedTrust, trustData: values })
      .then(() => {
        closeModal();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        handleFormsServerErrors(error, setError, setFocus);
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    if (selectedTrust) {
      reset({
        ...selectedTrust,
      });
    }
  }, []);

  return (
    <FormContainer data-testid="form-container">
      <FieldText
        placeholder="Enter here ..."
        label="Name"
        required
        register={register('name')}
        error={errors.name}
        data-testid="name-field"
      />
      <FieldText
        placeholder="Enter here ..."
        label="Description"
        register={register('description')}
        error={errors.description}
        data-testid="description-field"
      />
      <div className="form-actions" data-testid="form-actions">
        <Button
          onClick={() => closeModal()}
          data-testid="cancel-button"
          type="primary"
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={handleSubmit(onClickSubmit)}
          disabled={isSubmitting}
          data-testid="update-button"
        >
          Submit
        </Button>
      </div>
    </FormContainer>
  );
};
