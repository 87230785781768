import React, { useMemo, useState } from 'react';
import {
  AxiosInstanceErrorResponse,
  getItemsOfPage,
  getNumberOfPages,
  uploadUsersCsvRequest,
} from '../../utils';
import { EUserRole, User } from '../../backend/careo-api';
import { toast } from 'react-toastify';
import {
  Badge,
  Button,
  CardContainer,
  Pagination,
  Table,
  TitlePage,
} from '../ui';
import { DeleteIcon, EditIcon, PlusIcon, SortIcon } from '../../icons';
import { useAuth } from '../../contexts/auth.context';
import { UploadButton } from '../ui/buttons';
import { useModal } from '../../contexts/side-modal.context';
import { useConfirm } from '../../contexts/confirm-modal.context';
import { useUsers } from '../../api-query/hooks/useUsers';
import { UserForm } from '../user';

const itemsPerPage = 8;

export const UsersList = () => {
  const { user } = useAuth();
  const isAdmin = user?.role === EUserRole.Admin;

  const { openConfirm, closeConfirm } = useConfirm();

  const { usersList, isLoading, deleteUser } = useUsers();
  const [currentPage, setCurrentPage] = useState(1);

  const onDeleteConfirm = (item: User) => {
    openConfirm({
      title: 'Delete User',
      component: (
        <>
          Do you want to delete{' '}
          <b>
            {item.firstName} {item.lastName}
          </b>
        </>
      ),
      onConfirm: () =>
        deleteUser(item)
          .then(() => closeConfirm())
          .catch(() => {}),
    });
  };

  const { openModal } = useModal();

  const onClickCreate = () => {
    openModal({
      title: 'New User',
      component: <UserForm />,
    });
  };

  const onClickEdit = (user: User) => {
    openModal({
      title: 'Update User',
      component: <UserForm selectedUser={user} />,
    });
  };

  const uploadUsersCsv = async (file: File) => {
    await uploadUsersCsvRequest(file)
      .then(() => {
        toast.success('Users uploaded successfully');
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  // Apply filtering and sorting

  // Paginate the data
  const paginatedData = useMemo(() => {
    return getItemsOfPage(usersList, currentPage, itemsPerPage);
  }, [usersList, currentPage]);

  const totalPages = useMemo(() => {
    return getNumberOfPages(usersList.length, itemsPerPage);
  }, [usersList]);

  return (
    <>
      <CardContainer className="right-container" data-testid="card-container">
        <div
          className="settings-title-content"
          data-testid="settings-title-content"
        >
          <TitlePage>Team Access</TitlePage>
          {isAdmin && (
            <div className="actions-container" data-testid="actions-container">
              <UploadButton
                accept=".csv"
                onUpload={uploadUsersCsv}
                data-testid="upload-button"
              >
                Upload
              </UploadButton>
              <Button
                type="primary"
                onClick={() => onClickCreate()}
                data-testid="add-new-button"
              >
                <PlusIcon />
                Add New
              </Button>
            </div>
          )}
        </div>
        <hr />
        <div className="settings-content" data-testid="settings-content">
          <div
            className="data-table-container"
            data-testid="data-table-container"
          >
            <Table data-testid="team-table">
              <thead>
                <tr>
                  <th>
                    <div>
                      <label data-testid="team-member-header">
                        Team Member
                      </label>
                      <SortIcon data-testid="sort-team-member" />
                    </div>
                  </th>
                  <th>
                    <div>
                      <label data-testid="email-header">Email Address</label>
                      <SortIcon data-testid="sort-email" />
                    </div>
                  </th>
                  <th>
                    <div>
                      <label data-testid="role-header">Role</label>
                      <SortIcon data-testid="sort-role" />
                    </div>
                  </th>
                  <th>
                    <div>
                      <label data-testid="active-header">Active</label>
                      <SortIcon data-testid="sort-active" />
                    </div>
                  </th>
                  <th className="action-th">
                    <div data-testid="action-header">Action</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td
                      colSpan={100}
                      className="text-center"
                      data-testid="loading-row"
                    >
                      Loading ...
                    </td>
                  </tr>
                ) : (
                  <>
                    {paginatedData.length > 0 ? (
                      <>
                        {paginatedData.map((user) => (
                          <tr
                            key={user._id}
                            data-testid={`user-row-${user._id}`}
                          >
                            <td data-testid={`user-name-${user._id}`}>
                              <div className="name-item">
                                {user?.firstName} {user?.lastName}
                              </div>
                            </td>
                            <td data-testid={`user-email-${user._id}`}>
                              {user?.email}
                            </td>
                            <td
                              style={{ textTransform: 'capitalize' }}
                              data-testid={`user-role-${user._id}`}
                            >
                              {user?.role}
                            </td>
                            <td>
                              <Badge
                                type={'success'}
                                data-testid={`user-status-${user._id}`}
                              >
                                Active
                              </Badge>
                            </td>
                            <td>
                              <div
                                className="action-item"
                                data-testid={`action-item-${user._id}`}
                              >
                                <div
                                  className={`edit-icon ${!isAdmin && 'disabled'}`}
                                  onClick={() => isAdmin && onClickEdit(user)}
                                  data-testid={`edit-icon-${user._id}`}
                                >
                                  <EditIcon />
                                </div>
                                <div
                                  className={`delete-icon ${!isAdmin && 'disabled'}`}
                                  onClick={() =>
                                    isAdmin && onDeleteConfirm(user)
                                  }
                                  data-testid={`delete-icon-${user._id}`}
                                >
                                  <DeleteIcon />
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <tr>
                        <td
                          colSpan={100}
                          className="text-center"
                          data-testid="no-item-row"
                        >
                          No item found
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </Table>
          </div>
          <Pagination
            data-testid="pagination"
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={totalPages}
            itemsPerPage={itemsPerPage}
            totalEntries={usersList.length}
          />
        </div>
      </CardContainer>
    </>
  );
};

export default UsersList;
