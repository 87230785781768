import { useQuery, useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import {
  deletePayroll,
  getPayrolls,
  createPayroll,
  updatePayroll,
  getPayrollById,
} from '../api/payrolls.api';
import {
  CreatePayrollDto,
  ESocketRefreshModule,
  Payroll,
} from '../../backend/careo-api';

type SavePayrollVariables = {
  payrollData: CreatePayrollDto;
  selectedPayroll?: Payroll;
};

type UsePayrollProps = {
  payrollsList: Payroll[];
  isLoading: boolean;

  savePayroll: (variables: SavePayrollVariables) => Promise<void>;
  deletePayroll: (payroll: Payroll) => Promise<void>;
  findPayrollById: (payrollId: string) => Promise<Payroll>;
};

export const usePayrolls = (): UsePayrollProps => {
  // Fetch payrolls (React Query caches it)
  const { data: payrolls = [], isLoading } = useQuery({
    queryKey: [ESocketRefreshModule.PAYROLLS],
    queryFn: getPayrolls,
  });

  // Delete payroll mutation
  const deleteMutation = useMutation({
    mutationFn: (selectedPayroll: Payroll) => {
      return deletePayroll(selectedPayroll);
    },
    onSuccess: () => {
      toast.success('Payroll removed successfully');
    },
    onError: (error: any) => {
      toast.error(error.message);
    },
  });

  // Create or Update Payroll Mutation
  const savePayrollMutation = useMutation({
    mutationFn: ({
      payrollData,
      selectedPayroll,
    }: {
      selectedPayroll?: Payroll;
      payrollData: CreatePayrollDto;
    }) => {
      return (
        selectedPayroll
          ? updatePayroll(selectedPayroll._id, payrollData)
          : createPayroll(payrollData)
      ).then(() => {});
    },
    onSuccess: () => {
      toast.success('Payroll saved successfully');
    },
    onError: (error: any) => {
      toast.error(error?.message || 'Something went wrong');
    },
  });

  return {
    payrollsList: payrolls,
    isLoading,
    savePayroll: savePayrollMutation.mutateAsync,
    deletePayroll: deleteMutation.mutateAsync,
    findPayrollById: getPayrollById,
  };
};
