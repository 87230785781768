import { useEffect, useState } from 'react';
import { Company, CompanySubscriptionFeatures } from '../../backend/careo-api';
import { AxiosInstance } from '../../utils';

type UseCompanyProps = {
  subscriptionFeatures: CompanySubscriptionFeatures[] | null;
};

//TODO: add React Query to this hook
export const useCompany = (): UseCompanyProps => {
  const [subscriptionFeatures, setSubscriptionFeatures] = useState<
    CompanySubscriptionFeatures[]
  >([]);

  useEffect(() => {
    fetchCompany();
  }, []);
  const fetchCompany = async () => {
    const company = await AxiosInstance.company
      .companyControllerFindOne()
      .then((response) => {
        return response.data as Company;
      });

    setSubscriptionFeatures(company?.subscriptionFeatures ?? []);

    return company;
  };

  return {
    subscriptionFeatures,
  };
};
