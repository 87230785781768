import React, { useEffect, useState } from 'react';
import { TitleCard, FieldText, Button, Select, FormContainer } from '../ui';
import { useForm } from 'react-hook-form';
import { CreateUserDto, EUserRole, User } from '../../backend/careo-api';
import {
  AxiosInstanceErrorResponse,
  handleFormsServerErrors,
  UpdateUserSchema,
} from '../../utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { isCRMApp } from '../../environment/app.type';
import { useUsers } from '../../api-query/hooks/useUsers';
import { useModal } from '../../contexts/side-modal.context';

type UserFormProps = {
  selectedUser?: User;
};

export const UserForm = ({ selectedUser }: UserFormProps) => {
  const { saveUser } = useUsers();
  const { closeModal } = useModal();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setError,
    setFocus,
  } = useForm<CreateUserDto>({
    resolver: yupResolver(UpdateUserSchema as any),
  });

  const onClickSubmit = async () => {
    setIsSubmitting(true);
    const values = getValues();

    await saveUser({
      selectedUser,
      userData: values,
    })
      .then(() => {
        closeModal();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        handleFormsServerErrors(error, setError, setFocus);
      });
    setIsSubmitting(false);
  };

  useEffect(() => {
    if (selectedUser) {
      reset(selectedUser);
    }
  }, []);

  return (
    <FormContainer data-testid="form-container">
      <Select
        control={control}
        label="Role"
        required
        register={register('role', { required: true })}
        options={
          getValues('role') === EUserRole.Admin
            ? [
                {
                  value: EUserRole.Admin,
                  label: 'Admin',
                },
              ]
            : [
                {
                  value: isCRMApp ? EUserRole.Recruiter : EUserRole.Officer,
                  label: isCRMApp ? 'Recruiter' : 'Officer',
                },
                {
                  value: EUserRole.Manager,
                  label: 'Manager',
                },
              ]
        }
        placeholder="Select role"
        error={errors?.role}
        disabled={getValues('role') === EUserRole.Admin}
        data-testid="select-role"
      />
      <TitleCard data-testid="title-card-user-details">User Details</TitleCard>
      <div className="row" data-testid="row-name">
        <div className="col-md-6" data-testid="col-first-name">
          <FieldText
            placeholder="Enter here ..."
            label="First Name"
            required
            register={register('firstName', { required: true })}
            error={errors.firstName}
            data-testid="field-first-name"
          />
        </div>
        <div className="col-md-6" data-testid="col-last-name">
          <FieldText
            placeholder="Enter here ..."
            label="Last Name"
            required
            register={register('lastName', { required: true })}
            error={errors.lastName}
            data-testid="field-last-name"
          />
        </div>
      </div>
      <FieldText
        placeholder="Enter here ..."
        label="Email"
        required
        register={register('email', { required: true })}
        error={errors.email}
        data-testid="field-email"
      />
      <FieldText
        type="phoneNumber"
        placeholder="+442071234567"
        label="Phone Number"
        required
        register={register('phoneNumber', { required: true })}
        error={errors.phoneNumber}
        data-testid="field-phone-number"
      />
      {/* Password fields are commented out */}
      {!selectedUser && (
        <div className="row" data-testid="row-password">
          <div className="col-md-6" data-testid="col-password">
            <FieldText
              placeholder="Enter here ..."
              label="Password"
              required
              register={register('password', { required: true })}
              error={errors.password}
              data-testid="field-password"
            />
          </div>
          <div className="col-md-6" data-testid="col-confirm-password">
            <FieldText
              placeholder="Enter here ..."
              label="Confirm Password"
              required
              register={register('confirmPassword', { required: true })}
              error={errors.confirmPassword}
              data-testid="field-confirm-password"
            />
          </div>
        </div>
      )}

      <div className="form-actions" data-testid="form-actions">
        <Button
          onClick={() => closeModal()}
          data-testid="button-cancel"
          type="primary"
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={handleSubmit(onClickSubmit)}
          disabled={isSubmitting}
          data-testid="button-update"
        >
          Submit
        </Button>
      </div>
    </FormContainer>
  );
};
