import { CreatePayrollDto, Payroll } from '../../backend/careo-api';
import { AxiosInstance } from '../../utils';

// Fetch all payrolls
export const getPayrolls = async () => {
  const response = await AxiosInstance.payrolls.payrollsControllerFindAll();
  return response.data.items;
};

export const getPayrollById = async (id: string) => {
  const response = await AxiosInstance.payrolls.payrollsControllerFindOne(id);
  return response.data;
};

export const createPayroll = async (payrollData: CreatePayrollDto) => {
  const response =
    await AxiosInstance.payrolls.payrollsControllerCreate(payrollData);
  return response.data;
};

// Update a payroll
export const updatePayroll = async (
  id: string,
  payrollData: CreatePayrollDto,
) => {
  const response = await AxiosInstance.payrolls.payrollsControllerUpdate(
    id,
    payrollData,
  );
  return response.data;
};

// Delete a payroll
export const deletePayroll = async (payroll: Payroll) => {
  await AxiosInstance.payrolls.payrollsControllerDelete(payroll._id);
};
