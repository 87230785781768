import * as yup from 'yup';

export const PlacementCreateByApplicationIdSchema = yup.object().shape({
  applicationId: yup.string().required('Application is required'),
});

export const PlacementValidateSchema = yup.object().shape({
  fee: yup
    .number()
    .transform((value, originalValue) =>
      !originalValue ? null : Number(value),
    )
    .typeError('Fee must be a valid number.')
    .required('Fee is required.')
    .positive('Fee must be a positive number')
    .test(
      'charge-must-be-greater-than-rate',
      'Charge amount must be greater than any rate amount',
      function (value) {
        const { coreRate, unSocialRate } = this.parent;
        return Math.max(coreRate, unSocialRate) < value;
      },
    ),

  onCallFee: yup
    .number()
    .transform((value, originalValue) =>
      !originalValue ? null : Number(value),
    )
    .typeError('On Call Charge must be a valid number.')
    .required('On Call Charge is required.')
    .positive('On Call Charge must be a positive number')
    .test(
      'charge-must-be-greater-than-rate',
      'On Call Charge amount must be greater than any On Call rate amount',
      function (value) {
        const { onCallActiveRate, onCallPassiveRate } = this.parent;
        return Math.max(onCallActiveRate, onCallPassiveRate) < value;
      },
    ),

  vat: yup
    .number()
    .transform((value, originalValue) =>
      !originalValue ? null : Number(value),
    )
    .typeError('VAT must be a valid number.')
    .required('VAT is required.')
    .min(0, 'VAT must be 0 or a positive number.'),

  onCallActiveRate: yup
    .number()
    .transform((value, originalValue) =>
      !originalValue ? null : Number(value),
    )
    .typeError('On Call Active Rate must be a valid number.')
    .required('On Call Active Rate is required.')
    .positive('On Call Active Rate must be a positive number'),

  onCallPassiveRate: yup
    .number()
    .transform((value, originalValue) =>
      !originalValue ? null : Number(value),
    )
    .typeError('On Call Passive Rate must be a valid number.')
    .required('On Call Passive Rate is required.')
    .positive('On Call Passive Rate must be a positive number'),

  coreRate: yup
    .number()
    .transform((value, originalValue) =>
      !originalValue ? null : Number(value),
    )
    .typeError('Core Rate must be a valid number.')
    .required('Core Rate is required.')
    .positive('Core Rate must be a positive number'),

  unSocialRate: yup
    .number()
    .transform((value, originalValue) =>
      !originalValue ? null : Number(value),
    )
    .typeError('Unsocial Rate must be a valid number.')
    .required('Unsocial Rate is required.')
    .positive('Unsocial Rate must be a positive number'),
});
