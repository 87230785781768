import React, { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import {
  Candidate,
  HostedFile,
  ESocketRefreshModule,
} from '../../../backend/careo-api';
import { DocumentsListComponent } from '../../document';
import { DragDropUpload } from '../../ui';
import { AxiosInstance, uploadCandidateFiles } from '../../../utils';
import { toast } from 'react-toastify';

type CandidateDocumentsProps = {
  candidate: Candidate;
};

export const CandidateDocuments = ({ candidate }: CandidateDocumentsProps) => {
  const queryClient = useQueryClient();
  const [candidateDocuments, setCandidateDocuments] = useState<HostedFile[]>(
    [],
  );

  const getCandidateDocuments = () => {
    AxiosInstance.files
      .filesControllerFindCandidateDocs(candidate._id)
      .then((response) => {
        setCandidateDocuments(response.data.items);
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const onClickDelete = async (documentId: string) => {
    AxiosInstance.files
      .filesControllerDeleteFile(documentId)
      .then(() => {
        toast.success('File deleted successfully');
        queryClient.invalidateQueries({
          queryKey: [ESocketRefreshModule.CANDIDATE_COMPLIANCES],
        });
        getCandidateDocuments();
      })
      .catch(() => {
        toast.error('Failed to delete file');
      });
  };

  const handleFileChange = async (files: File[]) => {
    await uploadCandidateFiles(candidate._id, files)
      .then(() => {
        toast.success('Document uploaded successfully');
        getCandidateDocuments();
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  useEffect(() => {
    getCandidateDocuments();
  }, []);

  return (
    <>
      <div className="info-card" data-testid="documents-tab-content">
        <div className="info-card-title" data-testid="personal-documents-title">
          Personal Documents
        </div>
        <hr />
        <div
          className="data-table-container"
          data-testid="documents-table-container"
        >
          <DocumentsListComponent
            documents={candidateDocuments}
            onClickDelete={onClickDelete}
            getDocuments={getCandidateDocuments}
            data-testid="documents-list"
          />
        </div>
      </div>
      <div className="info-card" data-testid="drag-drop-upload-container">
        <DragDropUpload
          onUpload={handleFileChange}
          data-testid="drag-drop-upload"
        />
      </div>
    </>
  );
};
