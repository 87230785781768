import React, { useEffect, useState } from 'react';
import {
  TitleCard,
  FieldText,
  Button,
  CardContainer,
  FormContainer,
} from '../ui';
import { useForm } from 'react-hook-form';
import {
  Candidate,
  CandidatesList,
  CreateCandidatesListDto,
  User,
} from '../../backend/careo-api';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  candidateListSchema,
  handleFormsServerErrors,
} from '../../utils';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import Autocomplete from '../ui/inputs/autocomplete/autocomplete.component';
import { FilterCandidates } from './filter-candidates.component';
import { useModal } from '../../contexts/side-modal.context';

type SharedCandidatesListProps = {
  candidates: Candidate[];
  users: User[];
  getSharedCandidatesLists: () => void;
  selectedCandidateList?: CandidatesList;
  selectedCandidatesIds?: string[];
};

export const SharedCandidatesList = ({
  candidates,
  users,
  selectedCandidateList,
  selectedCandidatesIds = [],
  getSharedCandidatesLists,
}: SharedCandidatesListProps) => {
  const { closeModal } = useModal();

  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    setFocus,
  } = useForm<CreateCandidatesListDto>({
    resolver: yupResolver(candidateListSchema as any),
  });

  const [selectedNewCandidates, setSelectedNewCandidates] = useState<string[]>(
    selectedCandidatesIds,
  );
  const [selectedViewers, setSelectedViewers] = useState<
    { title: string; value: string }[]
  >([]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const onClickSubmit = () => {
    setIsSubmitting(true);
    const values = getValues();

    const requestDto = {
      name: values.name,
      candidates: selectedNewCandidates,
      sharedUsers: selectedViewers.map((el) => el.value),
    };

    const request = selectedCandidateList
      ? AxiosInstance.candidatesLists.candidatesListsControllerUpdate(
          selectedCandidateList._id,
          requestDto,
        )
      : AxiosInstance.candidatesLists.candidatesListsControllerCreate(
          requestDto,
        );

    request
      .then(() => {
        toast.success('Candidate List updated successfully');
        getSharedCandidatesLists();
        closeModal();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        handleFormsServerErrors(error, setError, setFocus);
        toast.error(error.message);
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    if (selectedCandidateList) {
      reset({
        name: selectedCandidateList.name,
      });
      setSelectedNewCandidates(
        selectedCandidateList.candidates.map((el) => el._id),
      );
      setSelectedViewers(
        selectedCandidateList.sharedUsers.map((el) => ({
          title: el.firstName + ' ' + el.lastName,
          value: el._id,
        })),
      );
    }
  }, []);

  return (
    <FormContainer>
      <FieldText
        placeholder="Enter here ..."
        label="Name"
        required
        register={register('name', { required: true })}
        error={errors.name}
        data-testid="field-text-name"
      />
      <CardContainer className="p-0 rounded-0" data-testid="card-container">
        <TitleCard data-testid="title-card-candidates">
          Candidates ({selectedNewCandidates.length})
        </TitleCard>
        <FilterCandidates
          candidates={candidates}
          selectedCandidatesIds={selectedNewCandidates}
          setSelectedCandidatesIds={setSelectedNewCandidates}
          data-testid="filter-candidates"
        />
        <TitleCard data-testid="title-card-viewers">
          Viewers ({selectedViewers.length})
        </TitleCard>
        <Autocomplete
          suggestions={users.map((el) => ({
            title: el.firstName + ' ' + el.lastName,
            value: el._id,
          }))}
          values={selectedViewers}
          onChange={(e) => setSelectedViewers(e)}
          data-testid="autocomplete-viewers"
        />
      </CardContainer>

      <div className="form-actions" data-testid="form-actions">
        <Button
          onClick={() => closeModal()}
          data-testid="button-cancel"
          type="primary"
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={handleSubmit(onClickSubmit)}
          disabled={isSubmitting}
          data-testid="button-update"
        >
          Submit
        </Button>
      </div>
    </FormContainer>
  );
};
