import * as yup from 'yup';

const dayWorkingHoursSchema = yup.object().shape({
  isWorkingDay: yup.boolean(),
  from: yup.string().when('isWorkingDay', (isWorkingDay, schema) => {
    return isWorkingDay[0]
      ? schema.required('From time is required')
      : schema.notRequired();
  }),
  to: yup
    .string()
    .when('isWorkingDay', (isWorkingDay, schema) =>
      isWorkingDay[0]
        ? schema.required('To time is required')
        : schema.notRequired(),
    ),
});

export const weekWorkingHoursDtoSchema = yup.object().shape({
  monday: dayWorkingHoursSchema,
  tuesday: dayWorkingHoursSchema,
  wednesday: dayWorkingHoursSchema,
  thursday: dayWorkingHoursSchema,
  friday: dayWorkingHoursSchema,
  saturday: dayWorkingHoursSchema,
  sunday: dayWorkingHoursSchema,
});
