import { VacancyApplicationsListPage } from '../pages/job';
import {
  EVacancyDetailsTabs,
  VacancyDetailsPage,
} from '../pages/job/job-details/job-details.page';
import { VacanciesListPage } from '../pages/job/jobs-list.page';
import { IRoute } from '../types/routes.type';
import { ERoute } from '../utils';

export const JobsRoutes: IRoute[] = [
  {
    path: ERoute.JOBS,
    element: <VacanciesListPage />,
    isVisibleOnSidebar: true,
  },
  {
    path: `/${ERoute.JOBS}/:id`,
    element: <VacancyDetailsPage />,
  },
  {
    path: `/${ERoute.JOBS}/:id/${EVacancyDetailsTabs.GENERAL}`,
    element: <VacancyDetailsPage />,
  },
  {
    path: `/${ERoute.JOBS}/:id/${EVacancyDetailsTabs.DOCUMENTS}`,
    element: <VacancyDetailsPage />,
  },
  {
    path: `/${ERoute.JOBS}/:id/${EVacancyDetailsTabs.CONTACT}`,
    element: <VacancyDetailsPage />,
  },
  {
    path: `/${ERoute.JOBS}/:id/applications`,
    element: <VacancyApplicationsListPage />,
  },
];
