import React, { useEffect, useState } from 'react';
import { TitleCard, RadioGroup, FieldText } from '../../ui';
import {
  Compliance,
  ComplianceFile,
  ComplianceMetaData,
  HostedFile,
} from '../../../backend/careo-api';
import ComplianceUpload from '../compliance-edit/compliance-upload';

type PrimaryMedicalQualificationProps = {
  compliance: Compliance;
  documents: HostedFile[];
  onClickSubmit: (
    id: string,
    values: ComplianceMetaData,
    validate?: boolean,
    successMessage?: string,
  ) => void;
  getDocuments: () => void;
  onUpdateValidation: (id: string, value: string) => void;
};

type TFilesKey = 'universityDiplomasFiles';

type TAllFields = TFilesKey | 'universityDiplomasExpiryDate';

export const PrimaryMedicalQualification = ({
  compliance,
  documents,
  onClickSubmit,
  getDocuments,
  onUpdateValidation,
}: PrimaryMedicalQualificationProps) => {
  const [isApproved, setIsApproved] = useState<boolean>();
  const [universityDiplomasExpiryDate, setUniversityDiplomasExpiryDate] =
    useState<string>();

  const [filesList, setFilesList] = useState<
    { key: TFilesKey; data: ComplianceFile[] }[]
  >([{ key: 'universityDiplomasFiles', data: [] }]);

  const onUpdateCompliance = (
    key: TAllFields,
    value: any,
    successMessage?: string,
  ) => {
    onClickSubmit(compliance._id, { [key]: value }, false, successMessage);
  };

  useEffect(() => {
    if (compliance) {
      const universityDiplomasFiles =
        compliance?.metadata?.universityDiplomasFiles ?? [];

      setFilesList([
        { key: 'universityDiplomasFiles', data: universityDiplomasFiles },
      ]);

      setUniversityDiplomasExpiryDate(
        compliance.metadata?.universityDiplomasExpiryDate?.split('T')[0],
      );

      setIsApproved(compliance.isApproved);
    }
  }, [compliance]);

  return (
    <>
      <>
        <TitleCard data-testid="title-card-universityDiplomasFiles">
          University Diplomas
        </TitleCard>

        <ComplianceUpload
          fileKey="universityDiplomasFiles"
          isMulti
          label="Upload documents"
          description="Does it contain all required fields."
          getDocuments={getDocuments}
          filesList={filesList}
          setFilesList={setFilesList}
          onUpdateCompliance={onUpdateCompliance}
          documents={documents}
        />

        <div className="row">
          <div className="col-md-6">
            <FieldText
              placeholder="Enter here ..."
              label="Expiry Date"
              required
              type="date"
              value={universityDiplomasExpiryDate}
              onChange={(e) => {
                setUniversityDiplomasExpiryDate(e.target.value);
              }}
              onBlur={(e) => {
                onUpdateCompliance(
                  'universityDiplomasExpiryDate',
                  e.target.value,
                );
              }}
            />
          </div>
        </div>
      </>

      {/* Approved Section */}
      <>
        <TitleCard data-testid="title-card-approved">Approved</TitleCard>
        <RadioGroup
          options={[
            {
              label: 'Pass',
              value: 'true',
            },
            {
              label: 'Fail',
              value: 'false',
            },
          ]}
          name="approved"
          value={isApproved?.toString()}
          onChange={(value: any) => onUpdateValidation(compliance._id, value)}
          data-testid="approved-radio-group"
        />
        {/* <div
          className="info-card compliance-status buttons-container validated-by-ai"
          data-testid="validate-by-ai-button-container"
        >
          <Button
            type="primary"
            onClick={() => onClickSubmit(compliance._id, {}, true)}
            data-testid="validate-by-ai-button"
          >
            Validate By AI
          </Button>
        </div> */}
      </>
    </>
  );
};
