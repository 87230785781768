import './App.css';
import { useAuth } from '../contexts/auth.context';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { appRoutes, authRoutes } from '../routes';
import { useEffect } from 'react';
import { isCRMApp } from '../environment/app.type';
import { socket } from '../utils';
import { ESocketRefreshModule, EWebsocketType } from '../backend/careo-api';
import { useQueryClient } from '@tanstack/react-query';

function App() {
  const queryClient = useQueryClient();
  const { verifyToken, isUserAuthenticated, isLoading, user } = useAuth();

  useEffect(() => {
    const connectHandler = () => {
      if (user) {
        socket.emit('register', { userId: user._id });
      }
    };

    if (user) {
      connectHandler();
    }

    const handleUserChange = (data: {
      module: ESocketRefreshModule;
      moduleId?: string;
    }) => {
      console.log('SOCKET IS RECEIVED @@@@@@@@@@@@@@@@');
      console.log('data');
      console.log(data);
      queryClient.invalidateQueries({ queryKey: [data.module] });
    };

    socket.on(EWebsocketType.Connect, connectHandler);
    socket.on(EWebsocketType.Refresh, handleUserChange);

    return () => {
      socket.off(EWebsocketType.Connect, connectHandler);
      socket.off(EWebsocketType.Refresh, handleUserChange);
    };
  }, [user, socket]);

  useEffect(() => {
    document.title = isCRMApp ? 'CRM | Careo Ai' : 'Compliance | Careo Ai';
    verifyToken();

    return () => {
      socket?.disconnect();
    };
  }, []);

  if (isLoading) {
    return <></>;
  }

  return (
    <RouterProvider
      key={isUserAuthenticated ? 'app' : 'auth'}
      router={createBrowserRouter(isUserAuthenticated ? appRoutes : authRoutes)}
    />
  );
}

export default App;
