import { CreateCandidateDto, Candidate } from '../../backend/careo-api';
import { isCRMApp } from '../../environment/app.type';
import { AxiosInstance } from '../../utils';

// Fetch all candidates
export const getCandidates = async () => {
  const analytics = !isCRMApp;
  const response = await AxiosInstance.candidates.candidatesControllerFindAll({
    analytics,
  });
  return response.data.items;
};

export const getCandidateById = async (id: string) => {
  const response =
    await AxiosInstance.candidates.candidatesControllerGetCandidate(id);
  return response.data;
};

export const createCandidate = async (candidateData: CreateCandidateDto) => {
  const response =
    await AxiosInstance.candidates.candidatesControllerCreateCandidate(
      candidateData,
    );
  return response.data;
};

// Update a candidate
export const updateCandidate = async (
  id: string,
  candidateData: CreateCandidateDto,
) => {
  const response = await AxiosInstance.candidates.candidatesControllerUpdate(
    id,
    candidateData,
  );
  return response.data;
};

// Delete a candidate
export const deleteCandidate = async (candidate: Candidate) => {
  await AxiosInstance.candidates.candidatesControllerDeleteCandidate(
    candidate._id,
  );
};
