import { CreateTrustDto, Trust } from '../../backend/careo-api';
import { AxiosInstance } from '../../utils';

// Fetch all trusts
export const getTrusts = async () => {
  const response = await AxiosInstance.trust.trustsControllerFindAll();
  return response.data.items;
};

export const getTrustById = async (id: string) => {
  const response = await AxiosInstance.trust.trustsControllerFindById(id);
  return response.data;
};

export const createTrust = async (trustData: CreateTrustDto) => {
  const response =
    await AxiosInstance.trust.trustsControllerCreateTrust(trustData);
  return response.data;
};

// Update a trust
export const updateTrust = async (id: string, trustData: CreateTrustDto) => {
  const response = await AxiosInstance.trust.trustsControllerUpdateById(
    id,
    trustData,
  );
  return response.data;
};

// Delete a trust
export const deleteTrust = async (trust: Trust) => {
  await AxiosInstance.trust.trustsControllerDeleteById(trust._id);
};
