import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Compliance,
  CreateComplianceDto,
  UpdateComplianceDto,
} from '../../backend/careo-api';
import {
  AxiosInstanceErrorResponse,
  handleFormsServerErrors,
  UpdateComplianceDatesSchema,
} from '../../utils';
import { Button, FormContainer, FieldText } from '../ui';
import { useCompliances } from '../../api-query/hooks';
import { useParams } from 'react-router-dom';
import { useModal } from '../../contexts/side-modal.context';

type ComplianceDateFormProps = {
  compliance: Compliance;
};

export const ComplianceDateForm = ({ compliance }: ComplianceDateFormProps) => {
  const { id } = useParams();
  const { closeModal } = useModal();

  const {
    register,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
    setError,
    setFocus,
  } = useForm<UpdateComplianceDto>({
    resolver: yupResolver(UpdateComplianceDatesSchema as any),
  });

  const { updateCompliance } = useCompliances(id!);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onClickSubmit = () => {
    setIsSubmitting(true);
    const values = getValues();

    updateCompliance({
      complianceId: compliance._id,
      validate: false,
      complianceData: {
        ...(values as CreateComplianceDto),
      },
    })
      .then(() => {
        closeModal();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        handleFormsServerErrors(error, setError, setFocus);
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setValue('reminderDate', compliance.reminderDate?.split('T')?.[0] || '');
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setValue('expiryDate', compliance.expiryDate?.split('T')[0] || '');
  }, [compliance, setValue]);

  return (
    <FormContainer data-testid="form-container">
      <FieldText
        placeholder="Enter here ..."
        label={'Reminder Date'}
        required
        type="date"
        register={register('reminderDate', { required: true })}
        error={errors.reminderDate}
        data-testid="reminder-date-field"
      />
      <FieldText
        placeholder="Enter here ..."
        label="Expiry Date"
        required
        type="date"
        register={register('expiryDate', { required: true })}
        error={errors.expiryDate}
        data-testid="expiry-date-field"
      />
      <div className="form-actions" data-testid="form-actions">
        <Button
          onClick={() => closeModal()}
          data-testid="cancel-button"
          type="primary"
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={handleSubmit(onClickSubmit)}
          disabled={isSubmitting}
          data-testid="update-button"
        >
          Update
        </Button>
      </div>
    </FormContainer>
  );
};
